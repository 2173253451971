import React from "react";
import {   Form, Grid, List, Table } from "semantic-ui-react";

const Marked = (props) => {
  let listMarked = props.thelistmarked.length > 0 ? props.thelistmarked : null;
  let view = props.patientsOrcView.length > 0 ? props.patientsOrcView : null;
  let total = props.patientsOrcTotal.length > 0 ? props.patientsOrcTotal : null;

  let confirmados = 0;
  let deletados = 0;
  let visualizados = 0;
  let nvisualizados = 0;

  console.log("list :", listMarked);
  console.log("view : ", view);
  console.log("total :", total);

  if (listMarked !== null) {
    let c =0;
    let d =0 ;
    listMarked.map(function(element ) { 
      if (element.confirmed_at !== null) {
        c++;
      } else if (element.deleted_at !== null) {
        d++ ;
      }   
      return  (confirmados =c,deletados=d);
    } );
  } else {
    confirmados = "-";
    deletados = "-";
  }


  if (view !== null) {
    let v = 0;
    view.map((element ) => {
      if (element.viewed_at !== null) {
        v++;
      }
      return (visualizados=v);
    });
  } else {
    visualizados = "-"; 
  }

  if (total !== null) { 
    let n= 0;     
    total.map((element, index, array) => {
      if (element.viewed_at === null) {
        n++;
      }
      return (nvisualizados=n);
    });
  } else {
    nvisualizados = "-"; 
  }

  return (
    <>
      <Grid>
        <Grid.Column width="16">
          <Form>
            <Form.Group>
              <Form.Field width="16">
                <List verticalAlign="middle">{""}</List>
              </Form.Field>
            </Form.Group>
          </Form>
        </Grid.Column>

        <Grid.Column width="8">
          <Table celled compact>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell colSpan="4">
                  {" "}
                  Orçamentos nos últimos 4 dias{" "}
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row textAlign="center">
                <Table.HeaderCell collapsing>Marcados</Table.HeaderCell>
                <Table.HeaderCell collapsing>Descartados</Table.HeaderCell>
                <Table.HeaderCell collapsing>Visualizados</Table.HeaderCell>
                <Table.HeaderCell collapsing>Não Visualizados</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row textAlign="center">
                <Table.Cell> {confirmados}</Table.Cell>
                <Table.Cell>{deletados}</Table.Cell>
                <Table.Cell>{visualizados}</Table.Cell>
                <Table.Cell>{nvisualizados}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column width="16">
          <List selection verticalAlign="middle">
            {""}
          </List>
        </Grid.Column>
      </Grid>
    </>
  );
};
export default Marked;
