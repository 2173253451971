import React, { Component } from 'react';
import { Grid, Table, Popup, Divider, Form } from "semantic-ui-react";

import * as Zapi from '../../../../resources/Zapi';

export default class Status extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaign: [],
            buscaItem: "",
            todayValueDate: new Date().toLocaleDateString(),
            de: "",
            ate: "",
        }
    }
    componentWillMount() { //quando componente for montar

        this.setState({
            de: new Date().toLocaleDateString().split("/").reverse("/").join("-"),
            ate: new Date().toLocaleDateString().split("/").reverse("/").join("-"),
        });
        this.acessar();
    } 

    refresh = null;

    componentDidMount() { // depois da montagem do componete
        this.refresh = setInterval(() => {
            this.acessar();
        }, 2000)

    }

    componentWillUnmount() { // 
        clearInterval(this.refresh);
    }

    acessar() {

        let de = new Date(this.state.de+" 00:00:00 GMT-0300").getTime();
        let ate = new Date(this.state.ate+" 23:59:59 GMT-0300").getTime();
       
        Zapi.getStatus(1, de, ate).then(result => {
            if (result.success === true) {
                this.setState({ campaign: result.status })
            }
        });
    }
    buscar(value) {
        this.setState({ buscaItem: value });
        console.log(value)
    }

    handleDate(value, id) {

        switch (id) {
            case 'de': this.setState({ de: value.substring(0, 10).split("/").reverse("/").join("-") })
                break;
            case 'ate': this.setState({ ate: value.substring(0, 10).split("/").reverse("/").join("-") })
                break;
            default:
                break;
        }


    }

    render() {
        let resultado = this.state.campaign.filter((elemento) => {
            return elemento.name.toLowerCase().includes(this.state.buscaItem.toLowerCase());
        });

        let itens = [];

        resultado.forEach((element, index) => {
            let item = [];
            let cor = ['red', 'default', 'green', 'orange']
            item = <Table.Row key={index} >
                <Table.Cell >{element.id}</Table.Cell>
                <Table.Cell >{element.name}</Table.Cell>
                <Table.Cell >{element.message}</Table.Cell>
                <Table.Cell textAlign='center'>{new Date(element.startDate).toLocaleDateString() + " - " + new Date(element.endDate).toLocaleDateString()}</Table.Cell>
                <Table.Cell textAlign='center' style={{ color: cor[1] }}>

                    <Popup
                        on='click'
                        pinned
                        trigger={<b style={{ color: element.fail >= 1 ? cor[0] : cor[1] }} >{element.fail}</b>}
                        position='left center'
                    >Falha no envio:1 <br />
                    Falha no envio:1 <br /> </Popup>

                </Table.Cell>
                <Table.Cell textAlign='center' style={{ color: element.success === element.total ? cor[2] : cor[3] }} > <b>{element.success} / {element.total}</b></Table.Cell>
            </Table.Row>
            itens.push(item);
        });



        return (
            <Grid stackable columns={1}>
                <Grid.Column  >

                    <Form columns={1}  >

                        <Form.Group widths='equal'>

                            <Form.Input fluid type='search' icon='search' label='Buscar:' value={this.state.buscaItem} onChange={(event, { value }) => this.buscar(value)} ></Form.Input>

                            <Form.Input id='de' width='5' type='date' label='Periodo de:' value={this.state.de}
                                onChange={(event, { value, id }) => (new Date(value).getTime() <= new Date(this.state.ate).getTime()) ? this.setState({ de: value }) : alert("A data inicial não pode ser maior que a data final ")}

                            ></Form.Input>

                            <Form.Input id='ate' width='5' type='date' label='Periodo até:' value={this.state.ate}
                                onChange={(event, { value, id }) => (new Date(value).getTime() >= new Date(this.state.de).getTime()) ? this.setState({ ate: value }) : alert("A data final não pode ser menos que a data inicial")}

                            ></Form.Input>
                            <br />
                        </Form.Group>

                    </Form>
                    <Divider />
                    <Table celled selectable compact>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell width={3}>Campanha</Table.HeaderCell>
                                <Table.HeaderCell>Mensagem</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center'>Período Programado</Table.HeaderCell>
                                <Table.HeaderCell textAlign='center' >Falhas</Table.HeaderCell>
                                <Table.HeaderCell width={2} textAlign='center'>Envios</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {itens}
                        </Table.Body>
                    </Table>
                </Grid.Column>
            </Grid >

        );
    }
}