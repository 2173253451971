import React, { Component } from 'react';
import { Menu, Icon, Label} from "semantic-ui-react";
import * as Auth from '../../../resources/Auth';

//Menu Itens
import AgendamentosSite from './AgendamentosSite';
import Regi from './Regi';
import NovoRegi from './NovoRegi';
import ExamesTerceirizados from './ExamesTerceirizados';
import Orcamento from './Orcamento';


class Site extends Component {

    
    constructor(props) {
        Notification.requestPermission();
        Auth.getUser()
            .then(result => {this.setState({ user: result});})
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            handleItemClick:props.handleItemClick,
            visibleBar:true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "agendamentosSite": content = <AgendamentosSite />; break;
            case "regi": content = <Regi />; break;
            case "novoregi": content = <NovoRegi />; break;
            case "ExamesTerceirizados": content = <ExamesTerceirizados />; break;
            case "orcamento": content = <Orcamento />; break;

            default:
                break;
        }

        this.state.handleItemClick(e,name,content);

    }
   componentDidUpdate(){
    const { activeItem } = this.props;
    if(activeItem === "commerce")
    this.handleItemClick(null,{name:"agendamentosSite"});
   }

    componentWillUnmount(){
    }

    render() {

        const { activeItem, notifications } = this.props;
        const active = (activeItem === "commerce" || activeItem === "agendamentosSite" ||activeItem === "orcamento" || activeItem === 'regi' || activeItem === 'novoregi' || activeItem === 'ExamesTerceirizados');
        let haveNotifications = (notifications.commerce !== undefined);
        if(haveNotifications)haveNotifications=parseInt(notifications.commerce.agendamentosSite)>0;
        return (
            <Menu.Item 
                name='commerce'
                onClick={active?null:this.handleItemClick}
            >
                 {haveNotifications && !active?<Label color='red'>{notifications.commerce.agendamentosSite}</Label>:null}
            <Icon name='tty' />
            Commerce
            {active?
            <Menu.Menu>
                <Menu.Item
                    name='agendamentosSite'
                    active={activeItem === 'agendamentosSite'}
                    onClick={this.handleItemClick}
                >
                    {haveNotifications?<Label color='red'>{notifications.commerce.agendamentosSite}</Label>:null}
                    Agendamentos Site
                </Menu.Item>
                <Menu.Item
                name='orcamento'
                active={activeItem === 'orcamento'}
                onClick={this.handleItemClick}
            > 
                Orçamento
            </Menu.Item>
                <Menu.Item
                    name='regi'
                    active={activeItem === 'regi'}
                    onClick={this.handleItemClick}
                >
                    Regi de Controle
                </Menu.Item> 
                <Menu.Item
                    name='novoregi'
                    active={activeItem === 'novoregi'}
                    onClick={this.handleItemClick}
                >
                    Novo Regi de Controle
                </Menu.Item>
                {/*<Menu.Item
                    name='ExamesTerceirizados'
                    active={activeItem === 'ExamesTerceirizados'}
                    onClick={this.handleItemClick}
                >
                    Exames Terceirizados
                </Menu.Item>*/}
            </Menu.Menu>:null}
        </Menu.Item>);
    }
}

export default Site;