import React, { Component } from 'react';
import { Form, Segment, Grid, Header, Icon, Label, Breadcrumb } from "semantic-ui-react";
import * as Manger from '../../../../resources/Manger'



class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            senhas: { novaSenha: '', repitirSenha: '' },
            user: props.user,
        }
    }


    HandleValue = (e) => {

        let senhas = this.state.senhas;

        switch (e.target.name) {
            case 'novaSenha': senhas.novaSenha = e.target.value; break;
            case 'repitirSenha': senhas.repitirSenha = e.target.value; break;

            default:
                break;
        }
        this.setState({ senhas });
    }

    UF = [{ key: "B", text: "BA", value: 12 }]
    City = [{ key: "I", text: "Itabuna", value: 12 }]

    EnviarHandle = (e) => {
        let senhas = this.state.senhas;
        if (senhas.novaSenha === senhas.repitirSenha) {
            Manger.changeUserPassword(sessionStorage.getItem('token'), this.state.user.user_id, this.state.senhas.novaSenha)
                .then(result => {
                    if (result.success) {
                        senhas.novaSenha = "";
                        senhas.repitirSenha = "";
                        this.setState({ senhas: senhas });
                    }
                    alert(result.message)
                });
        } else {
            alert("Porfavor! verifique os campos e tente novamente!")
        }
    }
    render() {

        function WrongMessage(text) {
            return (<Label basic color='red' pointing>
                {text}
            </Label>)
        }

        return (
            <Grid >
                <Grid.Column>
                    <Breadcrumb>
                        <Breadcrumb.Section link onClick={() => this.props.setActive(1)}>Gerenciar Usuários</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right angle' />
                        <Breadcrumb.Section active>Alterar Senha</Breadcrumb.Section>
                    </Breadcrumb>
                    <Header as='h2'>
                        <Icon name='lock' />
                        <Header.Content>Alterar Senha</Header.Content>
                    </Header>
                    <Form as={Segment} size={'tiny'}>
                        <Segment>
                            <Header.Content><b>Nome:</b> {this.state.user.name}</Header.Content>
                            <Header.Content><b>Usuário:</b> {this.state.user.nick}</Header.Content>
                            <Header.Content><b>E-mail: </b>{this.state.user.email}</Header.Content>
                        </Segment>
                        <Form.Field width={8}>
                            <label>Nova Senha</label>
                            <input type={'password'} placeholder='Nova senha' name={'novaSenha'} value={this.state.senhas.novaSenha} onChange={this.HandleValue} />
                            {this.state.senhas.novaSenha === this.state.senhas.senhaAntiga ? WrongMessage("Você precisa definir uma senha diferente da antiga!") : null}
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Repetir a Senha</label>
                            <input type={'password'} placeholder='Repita a senha' name={'repitirSenha'} value={this.state.senhas.repitirSenha} onChange={this.HandleValue} />
                            {this.state.senhas.novaSenha === this.state.senhas.repitirSenha || this.state.senhas.repitirSenha.length < 1 ? null : WrongMessage("As senhas não conferem!")}
                        </Form.Field>
                        <Form.Button content={'Alterar'} color={'blue'} size={'small'} onClick={this.EnviarHandle} />
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default ChangePassword;