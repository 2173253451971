import React from 'react';
import { Button,Grid,Header,Form,Segment,Message, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min';


function PasswordRecovery(props) {
    const [user,setUser]  = React.useState('');
    
    return(
        <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 400 }}>
          <Header as='h2' color='blue' textAlign='center'>
            <Icon name={"bullhorn"} /> Recuperar senha
          </Header>
          <Form size='large'>
            <Segment stacked>
            <span>Enviaremos um e-mail com o link de redefinição de senha!</span>
            <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail ou usuário' value={user} onInput={e=>setUser(e.target.value)}/>
              
    
              <Button color='blue' fluid size='large'>
                Enviar
              </Button>
            </Segment>
          </Form>
          <Message>
            Não lembra o e-mail? <Link to={"/contact"}>Contato</Link> | Fazer <Link to={"/"}>Login</Link>
          </Message>
        </Grid.Column>
      </Grid>
    );
}


export default PasswordRecovery;