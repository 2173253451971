import * as API from './API';

export const authenticate = (nick, password) =>new Promise(resolve=>{

        let user = {nick:nick,password:password}
        let json = JSON.stringify(user);

        API.receive("/login",json).then(result=>resolve(result));
        
});

export const getToken =()=>{
    const token = localStorage.getItem('token');
    return token;
}

export const deleteToken =()=>{
    localStorage.removeItem("token");
    return;
}

export const setToken = (token)=>{
    localStorage.setItem("token", token);
}

export const getUser =()=> new Promise(resolve=>{
    let tokenStr = getToken();
  
    API.receiveGet("/user?token="+tokenStr).then(result=>resolve(result)).catch(err => {
        console.error(err);
        deleteToken();
        //window.location.href = "/";
    });;
});

export const logout =()=>{
    let auth = {match:false,user:{},message:'Sessão encerrada!'}
    localStorage.removeItem('auth');
    localStorage.removeItem('token');
    window.location.href ="/";
    return auth;
}

