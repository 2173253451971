import React, { Component } from 'react';
import { Menu, Icon, Image, Segment, Sidebar, Header } from "semantic-ui-react";
import Cores from '../resources/Cores';
import Logo from '../resources/logo_branca.png';
import LogoCinza from '../resources/logocinzasombra.png';
import * as Auth from '../resources/Auth';
import * as Manger from '../resources/Manger';

//Menu Itens
import Marketing from './menu_itens/marketing/Marketing';
import Commerce from './menu_itens/commerce/Commerce';
import Perfil from './menu_itens/perfil/Perfil';
import UsersMananger from './menu_itens/usersMananger/UsersMananger';
import Polyzhat from './menu_itens/polyzhat/Polyzhat';
import Vacinas from './menu_itens/vacinas/Vacinas';
import Coordenacao from './menu_itens/coordenacao/Coordenacao';
 import Bithday from './menu_itens/birthday/birthday';

class Dashboard extends Component {


    constructor(props) {

        Auth.getUser()
            .then(result => { this.setState({ user: result }); })
            .catch(err => {
                // window.location.reload();
            });
        Manger.dashboard.getNotifications().then(result => {
            this.setState({ notifications: result });
        });

        super(props);
        this.state = {
            notifications: {},
            activeItem: '',
            heightScreen: window.innerHeight,
            widthtScreen: window.innerWidth,
            user: { name: "carregando..." },
            content: null,
            visibleBar: true,
        }
    }
    history = null;



    handleItemClick = (e, name, content) => {
        this.setState({ activeItem: name, content: content, });
    }
    logout = () => {
        this.props.setIsLogged(false, {});
    }
    HideBar = () => {
        this.setState({ visibleBar: !this.state.visibleBar });
    }
    Layout = null;
    Notifications = null;
    componentDidMount() {
        this.Layout = setInterval(() => {
            let height = window.innerHeight;
            let width = window.innerWidth;
            if (this.state.heightScreen !== height || (width !== this.state.widthtScreen)) {
                this.setState({ heightScreen: height, visibleBar: width < 775 ? false : true, widthtScreen: width });
            }

        }, 2000);
        this.Notifications = setInterval(() => {
            Manger.dashboard.getNotifications().then(result => {
                if (this.state.notifications.commerce !== undefined)
                    if (this.state.notifications.commerce.agendamentosSite !== undefined)
                        if (parseInt(result.commerce.agendamentosSite) > parseInt(this.state.notifications.commerce.agendamentosSite)) {
                            const message = "Você tem novas marcações no site!";
                            Manger.notifications.spawnNotification(message, "Dashboard 28");
                        }

                this.setState({ notifications: result });
            });

        }, 10000);

    }
    componentWillUnmount() {
        clearInterval(this.Layout);
        clearInterval(this.Notifications);
    }


    render() {

        const { activeItem } = this.state

        let bemvindo = [
            <Header inverted key={0}>Bem vindo</Header>,
            this.state.user.name + "!"
        ];


        let blank = <div><Image centered size='medium' src={LogoCinza} style={{ marginTop: (this.state.heightScreen / 8) }}></Image></div>;
        let commerce = <Commerce activeItem={activeItem} handleItemClick={this.handleItemClick} notifications={this.state.notifications} />
        let vacinas = <Vacinas activeItem={activeItem} handleItemClick={this.handleItemClick} />
        let marketing = <Marketing activeItem={activeItem} handleItemClick={this.handleItemClick} />;
        let perfil = <Perfil activeItem={activeItem} handleItemClick={this.handleItemClick} />;
        let users = <UsersMananger activeItem={activeItem} handleItemClick={this.handleItemClick} />
        let coordenacao = <Coordenacao activeItem={activeItem} handleItemClick={this.handleItemClick} />

        let polyzhat = <Polyzhat user={this.state.user} activeItem={activeItem} handleItemClick={this.handleItemClick} />

        return (
            <Sidebar.Pushable as={Segment} >
                <Sidebar
                    as={Menu}
                    animation={window.innerWidth < 775 ? 'overlay' : 'slide along'}
                    inverted
                    vertical
                    visible={this.state.visibleBar}

                    style={{ backgroundColor: Cores.principalEscuro }}
                >

                    {this.state.widthtScreen < 400 ? <Icon name={'close'} inverted size={'large'} style={{ cursor: 'pointer' }} onClick={this.HideBar} /> : null}
                    <Menu.Item>
                        {<Image src={Logo} size='tiny' centered />}

                        {this.state.user.user_id > 0 ? bemvindo : <Header inverted textAlign={'center'}>Carregando...</Header>}
                    </Menu.Item>
                    {this.state.user.agendamentos > 0 ? commerce : null}
                    {this.state.user.vacinas > 0 ? vacinas : null}
                    {this.state.user.marketing > 0 ? marketing : null}
                    {this.state.user.coordenador > 0 ? coordenacao : null}
                    {this.state.user.user_id > 0 ? perfil : null}
                    {this.state.user.manage_users > 0 ? users : null}


                    {typeof (this.state.user.zapi_chat) !== 'undefined' ? polyzhat : null}

                    {//this.state.user.manage_account > 0 ? accounts : null}
                    }
                    {//user.manage_admins ? activitys : null}
                    }
                    {//<Menu.Item
                        // name='contact'
                        //active={activeItem === 'contact'}
                        //onClick={this.handleItemClick}
                        //>

                        //     <Icon name='talk'/>
                        //      Contato
                        //</Sidebar>/</Menu.Item>
                    }

                </Sidebar>
                <Sidebar.Pusher dimmed={(this.state.visibleBar ? this.state.widthtScreen < 775 ? true : false : false)}
                    onClick={this.state.widthtScreen < 775 && this.state.visibleBar ? () => this.HideBar() : () => { }}
                    style={{ marginRight: this.state.visibleBar ? this.state.widthtScreen < 755 ? '0px' : '260px' : '0px' }}>

                    <Menu attached={'top'} style={{ backgroundColor: Cores.neutroClaro, }}>
                        <Menu.Item style={{ cursor: 'pointer' }} onClick={this.HideBar}>
                            <Icon name='bars' />
                        </Menu.Item>

                        <Menu.Menu position='right'>
                            <Menu.Item position='right'
                                name='birthday'
                                active={activeItem === 'birthday'}
                                onClick={()=> this.setState({content:<Bithday></Bithday>})}
                            >
                                <Icon name='birthday' />
                            </Menu.Item>  
                            {this.state.content !== null ? <Menu.Item
                                name='x'
                                active={activeItem === 'x'}
                                onClick={() => { this.setState({ content: null }); }}
                            >
                                <Icon name='close' />
                            </Menu.Item> : null}
                            <Menu.Item
                                name='logout'
                                active={activeItem === 'logout'}
                                onClick={() => { Auth.logout() }}
                            >
                                <Icon name='log out' />
                            </Menu.Item>
                        </Menu.Menu>
                    </Menu>
                    <Segment basic style={{ height: (window.innerHeight - 60), overflow: "auto" }} >
                        {this.state.content === null ? blank : this.state.content}
                    </Segment>

                </Sidebar.Pusher>
            </Sidebar.Pushable>

        );
    }
}

export default Dashboard;