import React, { Component } from 'react';
import {  Grid, Header, Image, Icon,  Menu } from "semantic-ui-react";

import * as Zapi from '../../../../resources/Zapi' ;
//import WhatsappMarketing from '../WhatsappMarketing';


class Setting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content:"conectar",
            qrcode:"",
            loading:true,
            isConnected:0,
            isActive:0,
            turn:0,
           
        }
    }

  
    
    Resfresh = null;
    startService(){
        this.UpdateStatus();
        this.Resfresh = setInterval(() => { this.UpdateStatus(); }, 3000);
    }
    componentDidMount() {
        this.startService();
    }

    componentWillUnmount() {
        clearInterval(this.Resfresh);
    } 
    
    UpdateStatus() {
        Zapi.getZapiToken().then(result=>{
            Zapi.getQrCode(result.token,2828).then(result=>{
                
                if(result.success){
                    this.setState({
                        loading:false,
                        isConnected:new Date().getTime() - result.runing < 180000?result.isConnected:0,
                        qrcode:result.qrcode,
                        turn:result.turn,
                        isActive:(new Date().getTime() - result.runing < 180000?1:0)
                    });
                }else{
                    this.setState({
                        loading:false,
                        isConnected:0,
                        isActive:0,
                        turn:0,
                    });
                }
            });
    });
        
    }
    setFilterSituação(active) {
        this.setState({ filter: active });
    }
    setFilter(active) {
        this.setState({ filterTipo: active });
    }
    handleAction() {
        if(this.Resfresh !== null)
        clearInterval(this.Resfresh);

        let stateN = this.state.isConnected>0?1:this.state.isActive>0?2:3

        switch (stateN) {
            case 1:
                Zapi.setTurnState(0).then(()=>this.startService());
                break;
            case 2:
                Zapi.setTurnState(0).then(()=>this.startService());
                break;
            case 3:
                Zapi.setTurnState(1).then(()=>this.startService());
                break;
        
            default:
                break;
        }
        
        this.setState({loading:true});
    }


    conectar = ()=>{
        return(<Grid textAlign={'center'} verticalAlign={'middle'} style={{height:'60vh'}}>
            <Grid.Column>
            {this.state.isConnected>0 || this.state.qrcode.length < 1?<Icon name='whatsapp' size={'massive'}></Icon>:<Image src={this.state.qrcode} style={{width:'auto',marginLeft:'auto',marginRight:'auto',height:'20vh'}} ></Image>}
                <Header>{this.state.loading?'Carregando...':this.state.isConnected>0&&this.state.turn>0?'Conectado':this.state.isActive>0&&this.state.turn>0?this.state.qrcode===""?"Conexão iniciada...":'Capture o código acima com o whatsapp no seu celular!':this.state.turn>0?"Aguarde...":'Instância de Whatsapp desligada!'}</Header>
            </Grid.Column>
        </Grid>);
    }

    render() {

        let content = null;
        switch(this.state.content)
        {
            case 'conectar':content = this.conectar();break;
            default:break;
        }

        return (
            <Grid >
                <Grid.Column>
                    <Menu compact size={'tiny'}>
                        <Menu.Item as='a' active={this.state.encerrados === 0} onClick={() => this.handleAction()}>
                            <Icon name='qrcode' disabled={this.state.loading} /> {this.state.turn>0?this.isConnected>0?"Desconectar":"Desligar":"Conectar"}
                 
                        </Menu.Item>
                        
                    </Menu> 
                        {content} 
                </Grid.Column>
            </Grid>
        );
    }
}

export default Setting;