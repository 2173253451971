import React, { Component } from 'react';
import { Menu, Icon} from "semantic-ui-react";
import * as Auth from '../../../resources/Auth';

//Menu Itens
import EditarPerfil from './EditarPerfil';
import AlterarSenha from './AlterarSenha';


export default class Perfil extends Component {

    
    constructor(props) {

        Auth.getUser()
            .then(result => {this.setState({ user: result});})
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            user:{name:'carregando...'},
            handleItemClick:props.handleItemClick,
            visibleBar:true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "edit-profile": content = <EditarPerfil user={this.state.user} close={this.close}/>; break;
            case "change-password": content = <AlterarSenha />; break;

            default: content=null;
                break;
        }

        this.state.handleItemClick(e,name,content);

    }
    close = ()=>{
        this.handleItemClick(null,{name:""});
    }
   componentDidUpdate(){
    const { activeItem } = this.props
    if(activeItem === "userProfile")
    this.handleItemClick(null,{name:'edit-profile'});
   }

    componentWillUnmount(){
    }

    render() {

        const { activeItem } = this.props
        const active = (activeItem === "edit-profile" || activeItem === "change-password" || activeItem === 'userProfile');
        return (
            <Menu.Item
            name='userProfile'
            onClick={active?null:this.handleItemClick}
            >
                        <Icon name='user' />
                        Perfil
                       {active?<Menu.Menu>
                            <Menu.Item
                                name='edit-profile'
                                active={activeItem === 'edit-profile'}
                                onClick={this.handleItemClick}
                            >
                                Editar Perfil
                            </Menu.Item>
                            <Menu.Item
                                name='change-password'
                                active={activeItem === 'change-password'}
                                onClick={this.handleItemClick}
                            >
                                Alterar Senha
                            </Menu.Item>
                        </Menu.Menu>:null}
                    </Menu.Item>);
    }
}