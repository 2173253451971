import React, { Component } from "react";
import { Segment, Grid, Header, Icon, Menu, Loader } from "semantic-ui-react";
import { Orcamentos } from "../../../resources/Manger";
import ListOrc from "./Orcamento/ListOrc";
import Marked from "./Orcamento/Marked";

export default class Orcamento extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex:1,
      indexItem: undefined,
      itemOrc: [],
      height: window.innerHeight,
      textObs: "",
      patientsOrcView: [],
      patientsOrcTotal: [],
      liststatus: false, 
      listMarked: [],
      viewed: true,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.listOrcamento();  
    this.listMarked();
  } 

  listOrcamento = () => {
    Orcamentos.listPatients()
      .then((result) => {
        if (result.success) {
          let resultado = result.listOrc;
          let orcamentoView = [];
          let orcamentoTotal = [];
          resultado.map((element) => {
            if (element.viewed_at !== null) {
              let orcamentoLocal = [];
              orcamentoLocal = element;
              orcamentoLocal.obs = "";
              return orcamentoView.push(orcamentoLocal);
            } else {
              let orcamentoLocal = [];
              orcamentoLocal = element;
              orcamentoLocal.obs = "";
              return orcamentoTotal.push(orcamentoLocal);
            }
          });
          if (resultado.length > 0) { 
            return this.setState({
              patientsOrcView: orcamentoView,
              patientsOrcTotal: orcamentoTotal, 
              liststatus: true,
            });
          } else { 
            return this.setState({ liststatus: false });
          }
        } else {
          return this.setState({ liststatus: false });
        }
      })
      .catch((erro) => { 
      });
  };

  handleClick = (index) => {
    if (this.state.activeIndex !== index) {
      this.setState({ activeIndex: index });
      this.listMarked();
    }
  };
  // marked
  listMarked = () => {
    try {
       Orcamentos.listPatientsMarked()
      .then((result) => { 
        let Markedlist = result.markedlist;
        this.setState({ listMarked: Markedlist });
      })
      .catch((erro) => { 
      });
    } catch (error) {
      
    }
   
  };

  /// list orc

  handleClickItemList = (index, orc) => {
    this.setState({ indexItem: index, itemOrc: orc });
    this.listOrcamento(); 
    this.handleObs(orc);
  };

  handleObs = (orc) => {
    if (orc !== null && this.state.viwed === true) {
      let orcamento = this.state.patientsOrcView[this.state.indexItem];
 
      let data = [{ orcamento: orcamento }];
      
      Orcamentos.markObs(data)
        .then((result) => { 
          if (result.success === true) {
            let valor = result.obs;
            this.setState({ textObs: valor });
          } else {
            this.setState({ textObs: "" });
          }
        })
        .catch((error) => {});
    } else if (orc !== null && this.state.viwed === false) {
      let orcamento = this.state.patientsOrcTotal[this.state.indexItem];
 
      let data = [{ orcamento: orcamento }];
      Orcamentos.markObs(data)
        .then((result) => {
          if (result.success === true) {
            let valor = result.obs;
            this.setState({ textObs: valor });
          } else {
            this.setState({ textObs: "" });
          }
        })
        .catch((error) => {});
    } else {
      let data = [{ orcamento: orc }];
      Orcamentos.markObs(data)
        .then((result) => {
          if (result.success === true) {
            let valor = result.obs;
            this.setState({ textObs: valor });
          } else {
            this.setState({ textObs: "" });
          }
        })
        .catch((error) => {});
    }
  };

  setTextObs = (value) => {
    this.setState({ textObs: value });
  };

  setTextObsBlur = () => {
    // pegar o valor de estiver dentro do obs e setar no banco de dados
    let patient = this.state.viewed
      ? this.state.patientsOrcView[this.state.indexItem]
      : this.state.patientsOrcTotal[this.state.indexItem];
    let data = [
      {
        pacienteId: parseInt(this.state.indexItem),
        orcamento: parseInt(patient.orçamento),
        observacao: this.state.textObs,
        created_at: patient.created_at !== null? patient.created_at:new Date()
        .toISOString()
        .substr(0, 19)
        .split("T")
        .join(" "),
        confirmed_at: null,
        deleted_at: null,
      },
    ];

    Orcamentos.markPatients(data)
      .then((result) => {
        if (result.success) {
          this.listOrcamento();
          this.setState({ textObs: result.obs,indexItem:undefined });   
        } else { 
        }
      })
      .catch((error) => {});
  };

  handleClickViewed = (value) => {
    this.setState({ viewed: value, indexItem: undefined });

    this.render();
  };

  handleMark = (id) => {
    let patient = this.state.viewed
      ? this.state.patientsOrcView[id]
      : this.state.patientsOrcTotal[id]; 
    let data = [
      {
        pacienteId: parseInt(id),
        orcamento: parseInt(patient.orçamento),
        observacao: this.state.textObs,
        created_at: patient.created_at !== null? patient.created_at:new Date()
        .toISOString()
        .substr(0, 19)
        .split("T")
        .join(" "),
        confirmed_at: new Date()
          .toISOString()
          .substr(0, 19)
          .split("T")
          .join(" "),
        deleted_at: null,
      },
    ];
    Orcamentos.markPatients(data)
      .then((result) => { 
        if (result.success) {
          this.listOrcamento();
          this.setState({ textObs: "",indexItem:undefined });
        }
      })
      .catch((error) => {});
  };

  handleDiscard = (id) => {
   
      let patient = this.state.viewed
        ? this.state.patientsOrcView[id]
        : this.state.patientsOrcTotal[id];
      let data = [
        {
          pacienteId: parseInt(id),
          orcamento: parseInt(patient.orçamento),
          observacao: patient.obs,
          created_at:patient.created_at !== null? patient.created_at:new Date()
          .toISOString()
          .substr(0, 19)
          .split("T")
          .join(" "),
          confirmed_at: null,
          deleted_at: new Date()
            .toISOString()
            .substr(0, 19)
            .split("T")
            .join(" "),
        },
      ]; 
      Orcamentos.markPatients(data)
        .then((result) => {
          if (result.success) {
            this.listOrcamento();
            this.setState({ textObs: "" , indexItem:undefined});
          }
        })
        .catch((error) => {});
    
  };

  render() { 

    let option = this.state.activeIndex;

    let menu = (
      <div>
        <Header as="h2">
          <Icon name="calendar alternate outline" />
          <Header.Content>Orçamento</Header.Content>
        </Header>

        <Menu compact size={"tiny"}>
          <Menu.Item
            as="a"
            name="listOrc"
            index={1}
            active={this.state.activeIndex === 1 ? true : false}
            onClick={(event, { index }) => this.handleClick(index)}
          >
            <Icon name="clock" /> Lista de Orçamentos
          </Menu.Item>
          <Menu.Item
            as="a"
            name="marked"
            index={2}
            active={this.state.activeIndex === 2 ? true : false}
            onClick={(event, { index }) => this.handleClick(index)}
          >
            <Icon name="calendar" /> Informações 
          </Menu.Item>
        </Menu>
      </div>
    );

    let tela;

    switch (option) {
      case 1:
        tela =
          this.state.liststatus ? (
            <ListOrc
              patientsOrcView={this.state.patientsOrcView}
              patientsOrcTotal={this.state.patientsOrcTotal}
              listMarked={this.state.listMarked}
              listOrcamento={() => this.listOrcamento()}
              height={this.state.height}
              textObs={this.state.textObs}
              setTextObs={(value) => this.setTextObs(value)}
              setTextObsBlur={() => this.setTextObsBlur()}
              indexItem={this.state.indexItem}
              handleMark={(id) => this.handleMark(id)}
              handleDiscard={(id) => this.handleDiscard(id)}
              handleClickItemList={(value, orc) =>
                this.handleClickItemList(value, orc)
              }
              handleClickViewed={(value) => this.handleClickViewed(value)}
              handleObs={(orc) => this.handleObs(orc)}
              viewed={this.state.viewed}
            />
          ) : (
            <Loader style={{ padding: "10%" }} active inline="centered">
              {" "}
              Sem orçamentos{" "}
            </Loader>
          );
        break;
      case 2:
        tela =  this.state.liststatus    ? (
          <Marked
            patientsOrcView={ this.state.patientsOrcView}
            patientsOrcTotal={this.state.patientsOrcTotal} 
            thelistmarked={this.state.listMarked}
          /> ): (
            <Loader style={{ padding: "10%" }} active inline="centered">
              {" "}
              Sem Informações{" "}
            </Loader>
        ) ;
        break;

      default:
        break;
    }

    return (
      <Grid>
        <Grid.Column>
          <> {menu}</>
          <Segment style={{ height: "auto" }}>{tela}</Segment>
        </Grid.Column>
      </Grid>
    );
  }
}
