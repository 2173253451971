import * as API from './API';


export const getZapiToken = () => new Promise(resolve => {

    if (sessionStorage.getItem("zapitoken") === null) {
        generateZapiToken().then(result => {
            sessionStorage.setItem('zapitoken', result.token);
            resolve(sessionStorage.getItem("zapitoken"));
        });
    } else {
        let result = {};
        result.token = sessionStorage.getItem("zapitoken");
        resolve(result);
    }
});

export const saveZapiToken = (token) => {
    return sessionStorage.setItem("zapitoken", token);
};

export const generateZapiToken = () => new Promise(resolve => {
    let id = 10000;
    let generate = 1;
    let json = JSON.stringify({ id: id, generate: generate });

    API.receive("/zapi/generateZapiToken", json).then(result => resolve(result));
});

export const getQrCode = (token, instanceId) => new Promise(resolve => {

    let json = JSON.stringify({ token: token, instanceId: instanceId });

    API.receive("/zapi/getQrCode", json).then(result => resolve(result));
});

export const sendMassMessages = (token, data) => new Promise(resolve => {
    data.token = token;
    let json = JSON.stringify(data);


    API.send("zapi/sendMassMessages", "POST", json).then(result => resolve(result));
});

export const updateMassMessages = (token, data) => new Promise(resolve => {
    data.token = token;
    let json = JSON.stringify(data);

    API.send("zapi/updateMassMessages", "POST", json).then(result => resolve(result));
});

export const getCampaign = (data) => new Promise(resolve => {

    let json = JSON.stringify(data);



    API.receiveGet("zapi/getCampaign", json).then(result => resolve(result));
});

export const delCampaign = (id) => new Promise(resolve => {

    let json = JSON.stringify({ id });

    API.receive("zapi/delCampaign", json).then(result => resolve(result));
});
export const setActive = (id, isActive) => new Promise(resolve => {

    let json = JSON.stringify({ id, isActive });

    API.receive("zapi/setActive", json).then(result => resolve(result));
});

export const setTurnState = (turn) => new Promise(resolve => {
    let instanceId = 2828;
    let json = JSON.stringify({ turn, instanceId });

    API.receive("zapi/setTurnState", json).then(result => resolve(result));
});

export const getStatus = (id_conta, de, ate) => new Promise(resolve => {


    let json = JSON.stringify({ id_conta,de,ate });
    console.log(json)

    API.receive("zapi/getStatus", json).then(result => resolve(result));
});

