import React,{Component} from 'react'; 
import { Menu,Icon } from 'semantic-ui-react';

import * as Auth from '../../../resources/Auth';



//Menu Itens
import MenuPainelCromatico from './MenuPainelCromatico';
 
class Coordenacao extends Component {
   
    constructor(props) {

        Auth.getUser()
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            handleItemClick:props.handleItemClick,
            visibleBar:true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "MenuPainelCromatico": content = <MenuPainelCromatico />; break; 

            default:
                break;
        }

        this.state.handleItemClick(e,name,content);

    }
    componentDidMount(){
    }

    componentWillUnmount(){
    }

    render() {

        const { activeItem } = this.props;

        return (
            <Menu.Item>
            <Icon name='sitemap' />
            Coordenação
            <Menu.Menu>
                <Menu.Item
                    name='MenuPainelCromatico'
                    active={activeItem === 'MenuPainelCromatico'}
                    onClick={this.handleItemClick}
                >
                    Painel Cromático
                </Menu.Item>
            </Menu.Menu>
        </Menu.Item>);
    }
}

export default Coordenacao;