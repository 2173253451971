import React from 'react';
import { Grid, Header, Icon, Tab, Popup } from 'semantic-ui-react';
import * as Zapi  from "./../../../resources/Zapi";

import Setting from './WhatsappMarkting/Setting';
import Campanhas from './WhatsappMarkting/Campanhas';
//import Relatorio from './WhatsappMarkting/Relatorio';
import Status from './WhatsappMarkting/Status';
import NewCampanha from './WhatsappMarkting/NovaCampanha';

export default class WhatsappMarketing extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            isConnected:false,
        }
    }
    UpdateStatus() {
        Zapi.getZapiToken().then(result => {
            Zapi.getQrCode(result.token, 2828).then(result => {
    
         
                if (result.success) {
                    this.setState({
                        loading: false,
                        isConnected: new Date().getTime() - result.runing < 180000 ? result.isConnected >0?true:false : false,
                        qrcode: result.qrcode,
                        turn: result.turn,
                        isActive: (new Date().getTime() - result.runing < 180000 ? 1 : 0)
                    });
                } else {
                    this.setState({
                        loading: false,
                        isConnected: 0,
                        isActive: 0,
                        turn: 0,
                    });
                }
            });
        })

    }

    refresh = null;
    componentDidMount() {
        this.UpdateStatus();
        this.refresh = setInterval(() => {
            this.UpdateStatus();
        }, 3000);
    }

    componentWillUnmount() {
        clearInterval(this.refresh);
    }
    render() {
       

        const NewPopup =
            <Popup header={"Configurações"} trigger={<Icon name='setting' color='green' flipped='vertically' />} />
        const panes = [
            {
                menuItem: { key: 12, content: 'Nova Campanha' },
                render: () => <Tab.Pane attached={true}> <NewCampanha /></Tab.Pane>
            },
            {
                menuItem: { key: 13, content: 'Campanhas' },
                render: () => <Tab.Pane attached={true}> <Campanhas /></Tab.Pane>
            },
            /* {
                 menuItem: { key: 14, content: 'Relatório'  },
                 render: () => <Tab.Pane    attached={true}> <Relatorio   /></Tab.Pane>
             },*/
            {
                menuItem: { key: 15, content: 'Status' },
                render: () => <Tab.Pane attached={true}> <Status /></Tab.Pane>
            },
            {
                menuItem: { key: 16, content: NewPopup },
                render: () => <Tab.Pane attached={true}> <Setting /></Tab.Pane>
            },
        ]


        const isConnected = this.state.isConnected;
        const loading = this.state.loading;
        const qrcode = this.state.qrcode;
        const turn  = parseInt(this.state.turn);
        const isActive = this.state.isActive;
        let status = "Desconectado";

        if(isConnected && !loading && qrcode === "" && turn === 1 && isActive === 1 )
        status = "Conectado";

        if(!isConnected && !loading && qrcode !== "" && turn === 1 && isActive === 1 )
        status = "Aguardando QRcode ...";

        if(isConnected && !loading && qrcode === "" && turn === 0 && isActive === 1 )
        status = "Desligamento solicitado";

        if(!isConnected && !loading && qrcode === "" && turn === 1 && isActive === 0 )
        status = "Iniciando Instância";

        if(!isConnected && !loading && qrcode === "" && turn === 1 && isActive === 1 )
        status = "Carregando...";


        return (
            <div>
                <Grid stackable columns={1} >
                    <Grid.Column mobile={16} tablet={16} computer={16} >
                        <Header as='h2'>
                            <Icon name='whatsapp' color='green' />
                            <Header.Content>Whatsapp Marketing
                                <Header.Subheader>{status}</Header.Subheader>
                            </Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column >
                        <Tab menu={{ secondary: true, pointing: false, stackable: true, color: 'green' }} panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}