import React from 'react';
import { Segment, Icon, Accordion, Button, List, Grid, Label, Form, Header } from 'semantic-ui-react';

export default class ListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={

        }
    }

    render() {

        const SexoOptions = [{ key: 'm', text: 'Masculino', value: 0 }, { key: 'f', text: 'Feminino', value: 1 }, { key: 'o', text: 'Outros', value: 2 },]
        const TurnoOtions = [{ key: 0, text: 'Matutino ( Manhã )', value: 0 }, { key: 1, text: 'Vespertino ( Tarde )', value: 1 },]

        return (
            <Segment secondary raised>

                <List.Item>
                    <List.Item>
                        <Grid stackable columns={3} width='very'>
                            <Grid.Column width='6' textAlign='left' >
                                <Header as='a' color='blue' style={{ widths: "10px", wordWrap: "break-word" }}><b>{this.props.element.nameCompleto} </b></Header>
                            </Grid.Column >
                            <Grid.Column width='6' textAlign='left'  >
                                <label><h4><b>Exame: </b> {this.props.element.exame}</h4></label>
                            </Grid.Column>
                            <Grid.Column width='4'>
                                <Button floated='left' basic icon='edit' compact color='Standard' circular onClick={() => this.props.handleDisable(this.props.element, this.props.index)} />
                                <Button floated='right' size='medium' compact color='red' onClick={() => this.props.handlercancelar(this.props.element)}>Cancelar</Button>
                                <Button floated='right' size='medium' compact color='blue' type='onSubmit' >Confirmar</Button>
                            </Grid.Column>
                        </Grid>
                    </List.Item>
                    <List.Item>
                        <Accordion exclusive={false} >
                            <Accordion.Title
                                active={this.props.index === this.props.activeIndex}
                                onClick={() => this.props.handleClick(this.props.index)}
                                style={{ width: 100, height: 40 }}
                            ><Label ribbon as='a' color='grey' >  Ver Mais <Icon name='dropdown' /></Label>
                            </Accordion.Title>
                            <Accordion.Content
                                active={this.props.index === this.props.activeIndex}
                            >
                            <Segment secondary fluid>

{/*passar funcao na props*/}
                                    <Form onSubmit={this.props.handleConfirmarDados} stackable>
                                        <Form.Group compact>
                                            <Form.Input width={8} label='Nome Completo:' id="nameCompleto" value={this.props.element.nameCompleto} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                            <Form.Input width={8} label='Exame:' id='exame' value={this.props.element.exame} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                        </Form.Group>
                                        <Form.Group compact widths='16'>
                                            <Form.Input width={3} type='date' label='Data Nascimento:' id='dataNascimento' value={new Date(this.props.element.dataNascimento)} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                            <Form.Select width={3} label='Sexo:' id='sexo' options={SexoOptions} value={this.props.element.sexo} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                            <Form.Input width={3} type='number' id='telefone' label='Telefone:' value={this.props.element.telefone} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                            <Form.Input width={3} type='number' label='Celular:' id='celular' value={this.props.element.celular} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                            <Form.Input width={4} label='Whatsapp:' id='whatsapp' value={this.props.element.whatsapp > 0 ? this.props.element.celular : "Sem WhatsApp"} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                        </Form.Group>
                                        <Form.Group compact>
                                            <Form.Input compact width={4} label='Prestador:' id='clinicaDestino' value={this.props.element.clinicaDestino} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} required={this.props.element.clinicaDestino !== "" ? false : true} />
                                            <Form.Select compact width={4} label='Turno:' id='turno' options={TurnoOtions} value={this.props.element.turno} readOnly={this.props.readOnlyForm} />
                                            <Form.Input compact width={4} label='Data Pretendida:' size="mini"  type="date" id='dataPretendida' value={new Date(this.props.element.dataPretendida)} readOnly={this.props.readOnlyForm} />
                                            <Form.Input compact width={4} label='Médico Solicitante:' id='medicoSolicitante' value={this.props.element.medicoSolicitante} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />
                                            {/*new Date(this.props.element.dataPretendida).toLocaleDateString()*/}
                                        </Form.Group>
                                        <Form.TextArea fluid label='Observações:' id='observacao' value={this.props.element.observacao} index={this.props.index} onChange={(event, { id, index, value }) => this.props.handleChange(id, index, value)} readOnly={this.props.readOnlyForm} />


                                    </Form>
                                </Segment>
                            </Accordion.Content>
                        </Accordion>
                    </List.Item>
                </List.Item>
            </Segment>
   
        );
    }
}