import React, { Component } from 'react';
import { Segment, Menu, Grid, Header, Icon, Input } from 'semantic-ui-react';

export default class MenuPainelCromatico extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: "Manhã",
        }
    }
    handleItemClick = (e, { name }) => this.setState({ activeItem: name })

    render() {

        let component = null;
      
        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='line graph' />
                        <Header.Content>Painel Cromático</Header.Content>
                    </Header>
                    <Menu pointing size={'tiny'}>
                        <Menu.Menu >
                            <Menu.Item >
                                <Input 
                                icon='search' placeholder='Pesquisa por médico' />
                            </Menu.Item>
                        </Menu.Menu>

                        <Menu.Item position='right'>
                            <Input  
                            type='date' className='icon' icon='calendar' />
                           
                        </Menu.Item>
                    </Menu>
                    <Segment style={{ height: (window.innerHeight - 200), overflow: 'auto' }}>

                        {component}

                    </Segment>

                </Grid.Column>
            </Grid>

        );
    }
}
