import React from 'react';
import { Button, Icon, Segment, List, Form, Grid, Accordion, Label, Header, Checkbox, Confirm } from "semantic-ui-react";

export default class FormProgramacao extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.props.element;
        this.state.edit = true;
        this.state.open = false;

    }

    open = () =>  this.setState({ open: true })
    close = () => this.setState({ open: false })

    handleChange(value, name) {

        switch (name) {
            case 'name': this.setState({ name: value });
                break;
            case 'startDate':
                if (value > this.state.endDate) {
                    this.setState({ startDate: value, endDate: value });
                }
                this.setState({ startDate: value });
                break;
            case 'endDate': this.setState({ endDate: value });
                break;
            case 'startTime': this.setState({ startTime: value });
                break;
            case 'endTime': this.setState({ endTime: value });
                break;
            case 'number': if (this.state.isSql > 0) this.setState({ codSql: value });
                break;
            case 'message': this.setState({ message: value });
                break;
            case 'dom': this.setState({ dom: value });
                break;
            case 'seg': this.setState({ seg: value });
                break;
            case 'ter': this.setState({ ter: value });
                break;
            case 'qua': this.setState({ qua: value });
                break;
            case 'qui': this.setState({ qui: value });
                break;
            case 'sex': this.setState({ sex: value });
                break;
            case 'sab': this.setState({ sab: value });
                break;
            default:
                break;
        }


    }
    update = () => {
        if (this.state.isActive !== this.props.element.isActive)
            this.setState({ isActive: this.props.element.isActive })
    }
    componentDidUpdate() {
        this.update();
    }
    componentWillUnmount() {
        this.update = null;
    }

    handlerEdit() {
        if (!this.state.edit)
            this.props.handleClick(this.props.index);
        this.setState({ edit: !this.state.edit });
    }


    render() {
        //for (let index = 0; index <= nome.length; index++) {
        //  nameresult = index;  }   

        return (
            <Segment key={this.props.index} secondary >
                <List.Item>
                    <Label attached='top'>
                        <Grid columns={2} width={16}>
                            <Grid.Column mobile={11} textAlign='left' >
                                <Header color='black' style={{ widths: "10px", wordWrap: "break-word" }}><b>{this.state.name} </b></Header>

                            </Grid.Column>
                            <Grid.Column mobile={5} >
                                <Button floated='right' circular icon='cancel' size='medium' color='red' compact onClick={() => this.props.handlerCancelar(this.state.id)} />
                                <Button floated='right' circular icon='edit outline' size='medium' color='blue' compact onClick={() => this.handlerEdit()} />
                                <Checkbox style={{ float: 'right', marginTop: '1vh' }} toggle checked={parseInt(this.state.isActive) > 0 ? true : false} onChange={(e, { value }) => this.props.handleSetActive(this.state.id, !parseInt(this.state.isActive) > 0)} />
                            </Grid.Column>
                        </Grid>
                    </Label>
                    <Accordion exclusive={false} >
                        <Accordion.Title
                            active={this.props.index === this.props.activeIndex}
                            onClick={() => this.props.handleClick(this.props.index)}
                            style={{ width: 100, height: 35}} >
                            <Label ribbon as='a' color='grey' >  Ver Mais <Icon name='dropdown' /></Label>
                        </Accordion.Title>
                        <Accordion.Content active={this.props.index === this.props.activeIndex} >

                            <Grid stackable columns={1}>
                                <Grid.Column>
                                    <Segment vertical  >
                                        <Grid stackable columns={2} >
                                            <Grid.Column mobile={16} tablet={16} computer={16} >
                                                <Form>
                                                    <Form.Field>
                                                        <Form.Input name='name' label='Nome da Campanha:' type='text' value={this.state.name} onChange={(event, { value, name }) => this.handleChange(value, name)} readOnly={this.state.edit} />
                                                    </Form.Field>
                                                </Form>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={16} computer={16} verticalAlign='middle' >
                                                <Segment textAlign='center'>
                                                    <Label attached='top'>Dias na semana</Label>
                                                    <Grid stackable columns={7} computer={5} >
                                                        <Form.Checkbox disabled={this.state.edit}  name='dom' label='Dom' checked={this.state.dom?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} />
                                                        <Form.Checkbox disabled={this.state.edit}  name='seg' label='Seg' checked={this.state.seg?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} />
                                                        <Form.Checkbox disabled={this.state.edit}  name='ter' label='Ter' checked={this.state.ter?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} />
                                                        <Form.Checkbox disabled={this.state.edit}  name='qua' label='Qua' checked={this.state.qua?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} />
                                                        <Form.Checkbox disabled={this.state.edit}  name='qui' label='Qui' checked={this.state.qui?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} />
                                                        <Form.Checkbox disabled={this.state.edit}  name='sex' label='Sex' checked={this.state.sex?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} />
                                                        <Form.Checkbox disabled={this.state.edit}  name='sab' label='Sab' checked={this.state.sab?true:false} onChange={(event, { checked, name }) => this.handleChange(checked, name)} /><br /><br />
                                                    </Grid>
                                                </Segment>
                                            </Grid.Column>

                                            <Grid.Column mobile={16} tablet={16} computer={4}   >
                                                <Form >
                                                    <Form.Field width={16}>
                                                        <Form.Input name='startDate' labelPosition='left' label='Data de Início:' type='date' value={this.state.startDate === null ? null : this.state.startDate.substring(0, 10).split("/").reverse("/").join("")} onChange={(event, { value, name }) =>
                                                            (new Date(value).getTime() >= new Date().getTime()) ? this.handleChange(value, name) : value === new Date().toLocaleDateString().split("/").reverse("/").join("-") ? this.handleChange(value, name) : alert("Não é possível utilizar data retroativa !")} disabled={this.state.edit} />
                                                    </Form.Field>
                                                </Form>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={16} computer={4}   >
                                                <Form >
                                                    <Form.Field width={16}>
                                                        <Form.Input name='endDate' labelPosition='left' label='Data de Término:' type='date' value={this.state.endDate === null ? null : this.state.endDate.substring(0, 10).split("/").reverse("/").join("")} onChange={(event, { value, name }) =>

                                                            (new Date(value).getTime() >= new Date().getTime() && new Date(value).getTime() >= new Date(this.state.startDate).getTime()) ? this.handleChange(value, name) : value === new Date().toLocaleDateString().split("/").reverse("/").join("-") ? this.handleChange(value, name) : alert("Não é possível utilizar data retroativa !")} disabled={this.state.edit} />
                                                    </Form.Field>
                                                </Form>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={16} computer={4}   >
                                                <Form >
                                                    <Form.Field width={16} >
                                                        <Form.Input name='startTime' labelPosition='left' label='Horário de Início:' type='time' value={new Date(parseInt(this.state.startTime)).toLocaleTimeString()} onChange={(event, { value, name }) => this.handleChange(value, name)} disabled={this.state.edit} />
                                                    </Form.Field>
                                                </Form>
                                            </Grid.Column>
                                            <Grid.Column mobile={16} tablet={16} computer={4}   >
                                                <Form >
                                                    <Form.Field width={16} >
                                                        <Form.Input name='endTime' labelPosition='left' label='Horário de Término:' type='time' value={new Date(parseInt(this.state.endTime)).toLocaleTimeString()} onChange={(event, { value, name }) => this.handleChange(value, name)} disabled={this.state.edit} />
                                                    </Form.Field>
                                                </Form>
                                            </Grid.Column>

                                        </Grid>

                                    </Segment><br />
                                    <Grid stackable columns={2}>
                                        <Grid.Column >
                                            <Form width={8} >
                                                <Segment textAlign='center' secondary >
                                                    <Form.TextArea width='16' name='number' label={this.state.isSql === "1" ? "SQL" : "Números:"} value={this.state.codSql} onChange={(event, { value, name }) => this.handleChange(value, name)} disabled={this.state.edit} />
                                                </Segment>
                                            </Form>
                                        </Grid.Column>
                                        <Grid.Column >
                                            <Segment textAlign='center' secondary >
                                                <Form>
                                                    <Form.TextArea width={16} name='message' label={"Mensagem:  "} value={this.state.message.split('`').join('')} onChange={(event, { value, name }) => this.handleChange(value, name)} disabled={this.state.edit} />
                                                </Form>
                                            </Segment>
                                        </Grid.Column>
                                    </Grid><br />
                                    <Grid>
                                        <Form >
                                            {!this.state.edit ? <Form.Button
                                                hidden={this.state.edit}
                                                floated='right'
                                                color='green'
                                                content='Salvar'
                                                onClick={this.open}
                                            /> : false}

                                        </Form>
                                        <Confirm 
                                            open={this.state.open}
                                            onCancel={this.close}
                                            onConfirm={() => { this.props.handlerSalvar(this.state); this.close(); this.handlerEdit(); }}
                                        />
                                    </Grid>

                                </Grid.Column>

                            </Grid>
                        </Accordion.Content >
                    </Accordion >
                </List.Item >
            </Segment >
        )
    }
}