import React from 'react';
import { Segment, Form, Grid, Button, Label, Checkbox, Divider, Input } from "semantic-ui-react";

export default class FromNewCampanha extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "",
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
            number: "",
            numbers: [],
            message: "",
            today: false,
            todayValueDate: new Date().toLocaleDateString(),
            todayValueTime: new Date().toLocaleTimeString(),
            disabledValueDate: false,
            disabledValueTime: false,
            dom: true,
            seg: true,
            ter: true,
            qua: true,
            qui: true,
            sex: true,
            sab: true,
            enableWeekDays: false,
            file: { nome: "", obj: null },
            isSql: false,
            isAnexo: false,
        }
    }

    componentWillMount() {
        this.setState({
            startDate: new Date().toLocaleDateString().split("/").reverse("/").join("-"),
            endDate: new Date().toLocaleDateString().split("/").reverse("/").join("-"),
            startTime: "00:00",
            endTime: "23:59",

        })
    }

    handleChange(value, id) {

        switch (id) {
            case 'name': this.setState({ name: value });
                break;
            case 'startDate':
                if (value > this.state.endDate) {
                    this.setState({ startDate: value, endDate: value });
                }

                this.setState({ startDate: value });
                break;
            case 'endDate': this.setState({ endDate: value });
                break;
            case 'startTime': this.setState({ startTime: value });
                break;
            case 'endTime': this.setState({ endTime: value });
                break;
            case 'number': this.setState({ number: value });
                this.setState({ numbers: value.split("\n") });
                break;
            case 'message': this.setState({ message: value });
                break;
            case 'today': this.setState({ today: value });
                let horarioInicial = new Date();
                let horarioFinal = new Date();
                horarioInicial.setHours(0);
                horarioInicial.setMinutes(0);
                if (value === true) {
                    this.setState({
                        startDate: this.state.todayValueDate.substring(0, 10).split("/").reverse("/").join("-"),
                        startTime: new Date(horarioInicial).toLocaleTimeString().substring(0, 5),
                        endDate: "",
                        endTime: "",
                        disabledValueDate: true,
                        disabledValueTime: true
                    });
                } else if (value === false) {
                    this.setState({
                        startDate: this.state.todayValueDate.substring(0, 10).split("/").reverse("/").join("-"),
                        startTime: new Date(horarioInicial).toLocaleTimeString().substring(0, 5),
                        endDate: this.state.todayValueDate.substring(0, 10).split("/").reverse("/").join("-"),
                        endTime: new Date(horarioFinal).toLocaleTimeString().substring(0, 5),
                        disabledValueDate: false,
                        disabledValueTime: false
                    });
                }

                break;
            case 'dom': this.setState({ dom: value });
                break;
            case 'seg': this.setState({ seg: value });
                break;
            case 'ter': this.setState({ ter: value });
                break;
            case 'qua': this.setState({ qua: value });
                break;
            case 'qui': this.setState({ qui: value });
                break;
            case 'sex': this.setState({ sex: value });
                break;
            case 'sab': this.setState({ sab: value });
                break;
            case 'isMidia': this.setState({ isMidia: value });
                break;


            default:
                break;
        }

    }
    clearForm() {

        this.setState({
            name: "",
            startDate: "",
            endDate: "",
            startTime: "",
            endTime: "",
            number: "",
            numbers: [],
            message: "",
            today: false,
            enableWeekDays: false,
            disabledValueDate: false,
            disabledValueTime: false,
            dom: true,
            seg: true,
            ter: true,
            qua: true,
            qui: true,
            sex: true,
            sab: true,
            file: { nome: "", obj: null },

            isSql: false,

        });
    }
    render() {
        //for (let index = 0; index <= nome.length; index++) {
        //  nameresult = index;  }   
        return (
            <div> {/* Nome da campanha , data de inicio e termino, hora de inicio e termino. */}
                <Grid stackable columns={1}>
                    <Grid.Column>
                        <Segment vertical  >
                            <Grid stackable columns={2} >
                                <Grid.Column mobile={16} tablet={16} computer={16} >
                                    <Form>
                                        <Form.Input id='name' label='Nome da Campanha:' type='text' required value={this.state.name} onChange={(event, { value, id }) => this.handleChange(value.substring(0.5), id)} />
                                    </Form >
                                </Grid.Column>
                                <Grid.Column mobile={16} tablet={16} computer={16} >
                                    <Segment textAlign='center'>

                                        <Label attached='top'>
                                            <Checkbox
                                                label='Especificar dias na semana'
                                                onChange={() => {
                                                    this.setState({
                                                        enableWeekDays: !this.state.enableWeekDays,
                                                        dom: true,
                                                        seg: true,
                                                        ter: true,
                                                        qua: true,
                                                        qui: true,
                                                        sex: true,
                                                        sab: true,
                                                    })
                                                }} />
                                        </Label>
                                        <Grid stackable columns={7} computer={5} textAlign='center'>
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='dom' label='Dom' checked={this.state.dom} onChange={(event, { checked, id }) => this.handleChange(checked, id)} />
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='seg' label='Seg' checked={this.state.seg} onChange={(event, { checked, id }) => this.handleChange(checked, id)} />
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='ter' label='Ter' checked={this.state.ter} onChange={(event, { checked, id }) => this.handleChange(checked, id)} />
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='qua' label='Qua' checked={this.state.qua} onChange={(event, { checked, id }) => this.handleChange(checked, id)} />
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='qui' label='Qui' checked={this.state.qui} onChange={(event, { checked, id }) => this.handleChange(checked, id)} />
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='sex' label='Sex' checked={this.state.sex} onChange={(event, { checked, id }) => this.handleChange(checked, id)} />
                                            <Form.Checkbox disabled={!this.state.enableWeekDays} id='sab' label='Sab' checked={this.state.sab} onChange={(event, { checked, id }) => this.handleChange(checked, id)} /> <br /> <br />
                                        </Grid>
                                    </Segment>
                                </Grid.Column>
                                <Grid.Column mobile={1} tablet={16} computer={16} textAlign="justified">

                                    <Form>
                                        <Segment>
                                            <Checkbox id='today' checked={this.state.today} onChange={(event, { checked, id }) => this.handleChange(checked, id)} /> {"Iniciar envio a partir de hoje: " + this.state.todayValueDate + ", sem programação de término."}
                                        </Segment>
                                    </Form>

                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={16} computer={4}   >
                                    <Form >
                                        <Form.Field width={16}>
                                            <Form.Input id='startDate' labelPosition='left' label='Data de Início:' type='date' value={this.state.startDate} onChange={(event, { value, id }) =>
                                                (new Date(value).getTime() >= new Date().getTime()) ? this.handleChange(value, id) : value === new Date().toLocaleDateString().split("/").reverse("/").join("-") ? this.handleChange(value, id) : alert("Não é possível utilizar data retroativa !")} disabled={this.state.disabledValueDate} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={16} computer={4}   >
                                    <Form >
                                        <Form.Field width={16}>
                                            <Form.Input id='endDate' labelPosition='left' label='Data de Término:' type='date' value={this.state.endDate} onChange={(event, { value, id }) =>
                                                (new Date(value).getTime() >= new Date().getTime() && new Date(value).getTime() >= new Date(this.state.startDate).getTime()) ? this.handleChange(value, id) : value === new Date().toLocaleDateString().split("/").reverse("/").join("-") ? this.handleChange(value, id) : alert("Não é possível utilizar data retroativa !")} disabled={this.state.disabledValueDate} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>

                                <Grid.Column mobile={16} tablet={16} computer={4}   >
                                    <Form >
                                        <Form.Field width={16}>
                                            <Form.Input id='startTime' labelPosition='left' label='Horário de Início (diário):' type='time' value={this.state.startTime} onChange={(event, { value, id }) => this.handleChange(value, id)} disabled={this.state.disabledValueTime} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>


                                <Grid.Column mobile={16} tablet={16} computer={4}   >
                                    <Form >
                                        <Form.Field width={16}>
                                            <Form.Input id='endTime' labelPosition='left' label='Horário de Término (diário):' type='time' value={this.state.endTime} onChange={(event, { value, id }) => this.handleChange(value, id)} disabled={this.state.disabledValueTime} />
                                        </Form.Field>
                                    </Form>
                                </Grid.Column>


                            </Grid>
                        </Segment><br />
                        <Grid stackable columns={2}>
                            <Grid.Column >

                                <Segment textAlign='center' secondary >

                                    <Form width={8} >
                                        <Form.Group inline>

                                            <Checkbox
                                                id='checkboxNumeros'
                                                radio
                                                label="Números:"
                                                floated='left'
                                                checked={this.state.isSql === false}
                                                onChange={(event, { value }) => this.setState({ isSql: false })}
                                            />
                                            <Checkbox
                                                id='checkboxSQL'
                                                radio
                                                label="SQL:"
                                                floated='right'
                                                style={{ marginLeft: "5vh" }}
                                                checked={this.state.isSql === true}
                                                onChange={(event, { value }) => this.setState({ isSql: true })}
                                            />
                                        </Form.Group>
                                        <Divider />
                                        <Form.TextArea required width='16' id='number' label={this.state.isSql ? "Comando em SQL:" : "Números dos Clientes:"} rows={this.state.isSql === true ? 9 : 3} type="number" value={this.state.number} onChange={(event, { value, id }) => this.handleChange(value, id)} />
                                    </Form>
                                    {!this.state.isSql ? <Label>Total de números:{this.state.numbers.length}</Label> : <Label>Total de caracteres:{this.state.number.length}</Label>}
                                </Segment>
                            </Grid.Column>
                            <Grid.Column >

                                <Segment textAlign='center' secondary >
                                    <Form width={8} >
                                        <Form.Group>
                                            <Checkbox
                                                width='8'
                                                floated='left'
                                                id='checkboxisAnexo'
                                                label='Anexo'
                                                // value={this.state.isAnexo}
                                                onChange={(event, { value }) => this.setState({ isAnexo: !this.state.isAnexo })}
                                                style={{ marginRight: "20px", marginLeft:"10px" }}
                                       
                                            />


                                            <Input        style={{ marginLeft: "5vh",marginBottom:"0" }} width='4' disabled={!this.state.isAnexo} id='isMidia' transparent={true} type='file' value={this.state.file.nome} onChange={(event, { value, id }) => { this.setState({ file: { nome: value, obj: event.target.files[0] } }) }} />

                                        </Form.Group>
                                        <Divider />
                                        <Form.TextArea required width={16} id='message' label={"Mensagem:"} value={this.state.message} onChange={(event, { value, id }) => this.handleChange(value, id)} />
                                    </Form>
                                    <Label >Total de caracteres: {this.state.message.length}</Label>
                                </Segment>

                                <Button
                                    floated='right'
                                    color='blue'
                                    onClick={() => this.props.sendHandle(this.state, () => this.clearForm())}
                                >Enviar </Button>
                            </Grid.Column>
                        </Grid><br />



                    </Grid.Column>
                </Grid>
                <br />
            </div >
        );

    }
}