import React from 'react';
//import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dimmer, Header, Icon, Segment, Form, Button, Message, Container, Label, Grid, Select } from "semantic-ui-react";
import * as Manger from './../../../../resources/Manger';


function NewUser(props) {

    const [name, setName] = React.useState("");
    const [nick, setNick] = React.useState("");
    const [accessLevel,setAccessLevel] = React.useState(null);
    const [e1, setE1] = React.useState(false);
    const [status, setForStatus] = React.useState("form");
    const [statusMessage, setStatusMessage] = React.useState("");
    const [novaSenha, setNovaSenha] = React.useState("");
    const [repitirSenha, setRepetirSenha] = React.useState("");

    const accessLevelsList = [];
    props.accesslevels.forEach(element => {
        const ob = { key: element.user_accesslevel_id, value: element.user_accesslevel_id, text: element.description };
        accessLevelsList.push(ob);
    });



    function reset() {
        setName("");
        setNick("");
        setNovaSenha("");
        setRepetirSenha("");
        setAccessLevel(null);
        setE1(false);
        setStatusMessage("");
        setForStatus("form");
        props.update();
    }

    let aguarde = (
        <Message icon>
            <Icon name='circle notched' loading />
            <Message.Content>
                <Message.Header>Espere um momento</Message.Header>
                Estamos criando o usuário.
        </Message.Content>
        </Message>
    );

    let sucesso = (
        <Message positive>
            <Message.Header>Sucesso!</Message.Header>
            <p>
                {statusMessage}
        </p>
        </Message>
    );

    let falha = (
        <Message negative>
            <Message.Header>Falha!</Message.Header>
            <p>
            {statusMessage}
        </p>
        </Message>
    );

    function WrongMessage(text) {
        return (<Label basic color='red' pointing>
            {text}
        </Label>)
    }
    let form = (
        <Container>
            <Header as='h2' >
                <Icon name='area chart' />
                <Header.Content>
                    Novo Usuário!
        </Header.Content>
                {/*<Icon name='close' style={{float:'right',marginTop:'-10%'}}/>*/}
            </Header>
            <Form as={Segment} size={'tiny'} style={{ textAlign: 'left' }}>
                <Form.Field >
                    <Form.Field>
                        <label>Nome</label>
                        <input placeholder='Nome de usuário' value={name} onChange={ev => setName(ev.target.value)} />
                    </Form.Field>
                    <Form.Field>
                        <label>Usuário</label>
                        <input placeholder='Login de usuário' value={nick} onChange={ev => setNick(ev.target.value)} />
                    </Form.Field>
                    <Grid columns={'equal'}>
                    <Grid.Column>
                    <Form.Field>
                        <label>Nova Senha</label>
                        <input type={'password'} placeholder='Nova senha' name={'novaSenha'} value={novaSenha} onChange={ev => setNovaSenha(ev.target.value.toString())} />
                        {novaSenha.length < 6 && novaSenha.length > 1 ? WrongMessage("A senha precisa ser de no mínimo 6 caracteres!") : null}
                    </Form.Field>
                    <Form.Field>
                        <label>Repetir a Senha</label>
                        <input type={'password'} placeholder='Repita a senha' name={'repitirSenha'} value={repitirSenha} onChange={ev => setRepetirSenha(ev.target.value.toString())} />
                        {novaSenha !== repitirSenha && repitirSenha.length > 1 ? WrongMessage("As senhas não conferem!") : null}/
                    </Form.Field>
                    </Grid.Column>
                    <Grid.Column>
                        <Form.Field style={{marginRight:"5px"}}>
                        <label>Nível de Acesso</label>
                         <Select placeholder='Selecionar' value={accessLevel} options={accessLevelsList}  onChange={(e, { value }) => setAccessLevel(value)}/>
                         </Form.Field>
                    </Grid.Column>
                    </Grid>
                </Form.Field>
                {e1 ?
                    <Message negative>
                        <Message.Header>Atenção!</Message.Header>
                        <p>Todos os campos devem ser preenchidos devidademente, verifique se você digitou corretamente todos os campos!</p>
                    </Message> : null}
                <Button primary content={'Criar'} size={'tiny'} onClick={ev => {

                    let erro = false;
                    setE1(false);
                    if (name.length < 3) {
                        setE1(true);
                        erro = true;
                    }
                    if (nick.length < 3) {
                        setE1(true);
                        erro = true;
                    }
                    if (novaSenha.length < 6 || novaSenha !== repitirSenha) {
                        setE1(true);
                        erro = true;
                    }
                    if (accessLevel === null) {
                        setE1(true);
                        erro = true;
                    }

                    if (!erro) {
                        setForStatus("aguarde");
                        let user = {name:name,nick:nick,password:novaSenha,accessLevel:accessLevel};

                        Manger.createUser(user)
                            .then(result => {
                                props.update();
                                setStatusMessage(result.message)
                                if (result.status === "sucesso") {
                                    setForStatus('sucesso');
                                    console.log(result);
                                    setTimeout(() => { reset(); }, 4000);
                                    
                                }
                                else {
                                    console.log(result);
                                    setForStatus('falha');
                                    setTimeout(() => { setForStatus("form"); }, 4000);
                                }
                               
                            });
                    }
                }} />
                <Button secondary type={'Submit'} content={'Cancelar'} size={'tiny'} onClick={ev => { reset(); props.handleNew(false); }} />


            </Form>
        </Container>
    );

    return (
        <Dimmer active={props.active} onClickOutside={ev => { props.handleNew(false); reset(); }}>


            <Segment style={{ width: '400px' }}>
                {status === "form" ? form : status === "aguarde" ? aguarde : status === "sucesso" ? sucesso : falha}
            </Segment>
        </Dimmer>
    );

}
export default NewUser;