import React, { useState } from 'react';
import { Header, Grid, Breadcrumb, Segment, Radio, Form, Confirm, Button, Image } from 'semantic-ui-react';



function ImagemDetails(props) {

    const [state, setState] = useState(false);

    const open = () => setState(true);
    const close = () => setState(false);

    const active = parseInt(props.Imagem.isActive) > 0 ? true : false;
    const url = props.Imagem.url;
    const id_image = props.Imagem.id;

    return (
        <Grid >
            <Grid.Column>
                <Breadcrumb>
                    <Breadcrumb.Section link onClick={() => props.setActive(1)}>Imagem</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section active>Detalhes</Breadcrumb.Section>
                </Breadcrumb>
                <Segment>
                    <Form>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <Header size={'large'}>
                                    Imagem
                                     <Header.Subheader>{props.Imagem.título}</Header.Subheader>
                                </Header>
                            </Form.Field>
                            <Form.Field>
                                <Header size={'medium'}>
                                    Descrição
                                            <Header.Subheader>{props.Imagem.descrição}</Header.Subheader>
                                </Header>
                            </Form.Field>
                            <Form.Field width={'4'} inline>
                                <label>Ativo</label>
                                <Radio toggle checked={active} onClick={() => props.sendImageStatus(props.Imagem.id, props.Imagem.isActive > 0 ? 0 : 1)} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <Header size={'medium'}>
                                    Texto do Botão
                                            <Header.Subheader>{props.Imagem.botão}</Header.Subheader>
                                </Header>
                            </Form.Field>
                            <Form.Field>
                                <Header size={'medium'}>
                                    Link do Botão
                                            <Header.Subheader>{props.Imagem.href}</Header.Subheader>
                                </Header>
                            </Form.Field>
                            <Form.Field width={'2'}>
                            <Button color={'red'} content={'Apagar'} onClick={open}/>
                            <Confirm
                                content={'Você tem certeza que deseja apagar esta imagem?'}
                                open={state}
                                onCancel={close}
                                onConfirm={() => { close(); props.deleteImage(id_image, url); props.setActive(1); }}
                            />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <Header size={'small'} textAlign={'left'}>
                                    Data de criação
                                    <Header.Subheader>{new Date(props.Imagem.created_at).toLocaleDateString()}</Header.Subheader>
                                </Header>
                            </Form.Field>
                        </Form.Group>
                       
                    </Form>
                    <Segment compact>
                        <Image src={props.url} size='medium' style={{width:"100%"}}/>
                    </Segment>
                </Segment>


            </Grid.Column>
        </Grid>
    );
}

export default ImagemDetails;