import React, { Component } from 'react';
import { Segment, Grid, Header, List, Icon, Label, Menu } from "semantic-ui-react";

//import * as Manger from '../../../resources/Manger';



class NovoRegi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedules: [],
            agenda: [{ "id": "1", "id_médico": "1", "médico": "Médico de Teste", "inicio": "2020-01-23 08:00:00", "fim": "2020-01-23 12:00:00", "abertura": null, "fechamento": null, "pacientes": [{ "id": "1", "nome": "João Ferreira Oliveira", "celular": "73988958985", "especialidade": "Clinico Geral", "id_médico": "1", "agenda": "2020-01-23 09:00:00", "atendido": "0", "situação": "Nenhuma" }, { "id": "1", "nome": "Maxson Jordan Matos Araujo", "celular": "73988958985", "especialidade": "Clinico Geral", "id_médico": "1", "agenda": "2020-01-23 09:00:00", "atendido": "0", "situação": "Nenhuma" }] }],
            width: window.innerWidth,
            filter: "Todas as Situações",
            filterTipo: "Todos",
            encerrados: 0,
            loading: true,
        }
    }

    render() {

        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='calendar alternate outline' />
                        <Header.Content>Novo Regi de Controle</Header.Content>
                    </Header>
                    <Menu compact size={'tiny'}>
                        <Menu.Item as='a'  > <Icon name='clock' /> Tempo Real   </Menu.Item>
                        <Menu.Item as='a'  >  <Icon name='calendar' /> Encerrado    </Menu.Item>
                    </Menu>
                    <Segment style={{ height: (window.innerHeight - 220), overflow: 'auto' }}>

                        <List animated selection divided verticalAlign='middle'>
                            <List.Item>
                                
                                    <Icon name='user md' size='large' ></Icon>
                                    <List.Content>
                                        <List.Header size='large' >Dr Fulano</List.Header>
                                        <List.Description><Label color='green' size='mini'>Atendendo </Label></List.Description>
                                    </List.Content>  
                            </List.Item>
                            <List.Item>
                                <Icon name='user md' size='large' ></Icon>
                                <List.Content>
                                    <List.Header size='large' >Dr Fulano</List.Header>
                                    <List.Description><Label color='green' size='mini'>Atendendo </Label></List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <Icon name='user md' size='large' ></Icon>
                                <List.Content>
                                    <List.Header size='large' >Dr Fulano</List.Header>
                                    <List.Description><Label color='green' size='mini'>Atendendo </Label></List.Description>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <Icon name='user md' size='large' ></Icon>
                                <List.Content>
                                    <List.Header size='large' >Dr Fulano</List.Header>
                                    <List.Description><Label color='green' size='mini'>Atendendo </Label> </List.Description>
                                </List.Content>
                            </List.Item>

                        </List>

                    </Segment>
                    <Label style={{ float: 'right' }}> Total </Label>
                </Grid.Column>
            </Grid >
        );

    }
}

export default NovoRegi;