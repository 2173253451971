import React, { Component } from 'react';
import {Dimmer } from "semantic-ui-react";
import NewImage from './carrossel/NewImagem';
import Main from './carrossel/Main';
import ImageDetails from './carrossel/ImageDetails';

import * as Manger from './../../../resources/Manger';



class Carrossel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            carrossel:[],
            newIsActive:false,
            imagem:{id:0,url:""},
            active:1,
            Imagens:[],

        }
    }

    componentDidMount(){
        this.UpdateImagens();
        this.Refresh = setInterval(()=>{this.UpdateImagens();},5000);

    }

    componentWillUnmount(){
        clearInterval(this.Refresh);
    }

    Refresh = null;

    UpdateImagens(){
        Manger.getCarrossel().then(result=>{
            let imagem = this.state.imagem;
            if(typeof(result.carrossel) !== 'undefined'){
            result.carrossel.forEach(element => {
                if(imagem)
                if(imagem.id === element.id){
                    imagem = element;
                }
            });
            this.setState({Imagens:result.carrossel,imagem:imagem})
        }
        });
    }

    handleNew=value=>{
        this.setState({newIsActive:value});
    }
    setActive=(active,element)=>{
        if(active === 1){
            this.UpdateImagens();
            this.setState({active:active,});
        }else{
            this.setState({active:active,imagem:element});
        }
        
    }
    sendImageStatus=(id,active)=>{
        
        Manger.sendStatusImage(id,active).then(this.UpdateImagens());
    }
    deleteImage=(id,url)=>{
        console.log("id = "+id,"url "+url);
        Manger.deleteImage(id,url)
    }

    render() {

        //Processar antes de exebir
        const newImage = (<NewImage active={this.state.newIsActive} handleNew={this.handleNew} setActive={this.setNewIsActive} update={()=>{this.UpdateImagens();}}/>);

        const main = (<Main handleNew={this.handleNew} setActive={this.setActive} Imagens={this.state.Imagens} sendImageStatus={this.sendImageStatus} url={Manger.url+"/"}/>)
        const imageDetails = (<ImageDetails setActive={this.setActive} Imagem={this.state.imagem} sendImageStatus={this.sendImageStatus} deleteImage={this.deleteImage} url={Manger.url+"/"+this.state.imagem.url}/>);
        let content = null;
        switch (this.state.active) {

            case 1: content = main;break;
            case 2: content = imageDetails;break;
            default: content = main;break;
        }

        return (
            <Dimmer.Dimmable dimmed={true}>
            {content}
            {newImage}
            </Dimmer.Dimmable>
        );
    }
}

export default Carrossel;