import React,{ useState } from 'react';
import { Header,Grid,Breadcrumb, Segment, Radio, Form,Confirm ,Button,Image } from 'semantic-ui-react';



function ImagemDetails(props) {
    
    const [state, setState] = useState(false);

    const open = () => setState(true);
    const close = () => setState(false);
   
    const active = parseInt(props.Imagem.isActive) > 0?true:false;
    const url = props.Imagem.source;
    const id_image = props.Imagem.id;

    let video = (url)=>{
        return (<video width={360} controls><source src={url}/></video>);
    }
    console.log(props);
    return (
        <Grid >
                <Grid.Column>
                <Breadcrumb>
                    <Breadcrumb.Section link onClick={()=>props.setActive(1)}>Mídia</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section active>Detalhes</Breadcrumb.Section>
                </Breadcrumb>
                   <Segment>
                   <Form>
                        <Form.Group>
                            <Form.Field width={'14'}>
                                <Header size={'large'}>
                                    Mídia
                                     <Header.Subheader>{props.Imagem.nome}</Header.Subheader>
                                </Header>
                                <Header size={'medium'}>
                                    Descrição
                                     <Header.Subheader>{props.Imagem.descrição}</Header.Subheader>
                                </Header>
                         </Form.Field>
                         <Form.Field width={'2'}inline>
                             <label>Ativo</label>
                            <Radio toggle checked={active} onClick={()=>props.sendImageStatus(props.Imagem.id,props.Imagem.isActive>0?0:1)}/>
                        </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'equal'}>
                            <Form.Field>
                                <Header size={'small'} textAlign={'left'}>
                                Data de criação
                                    <Header.Subheader>{new Date(props.Imagem.created_at).toLocaleDateString()}</Header.Subheader>
                                </Header>
                            </Form.Field>
                          
                        </Form.Group>
                        <Button color={'red'} content={'Excluir'} onClick={open}/>
                        <Confirm
                        content={'Você tem certeza que deseja excluir esta imagem?'}
                        open={state}
                        onCancel={close}
                        onConfirm={()=>{close();props.deleteImage(id_image,url);props.setActive(1);}}
                        />
                   </Form>
                   <Segment compact>
                       {props.Imagem.tipo ==="vid"?video(props.url):<Image src={props.url} size='medium' />}
                   </Segment>
                        
                   </Segment>
                  
                   
                </Grid.Column>
        </Grid>
    );
}

export default ImagemDetails;