import React from 'react';
import {Grid,List,Segment,Header } from 'semantic-ui-react';
import {Useful} from '../../../../resources/Manger';

function Pacientes (props){

let vacina = props.homeState.vacinas[props.homeState.vacinaIndex];

let listPacientes = [];
let doseN = 1;

vacina.dose.forEach(dose=>{
    dose.pacientes.forEach(paciente=>{
        const vencimento = new Date(paciente.DataNascimento);
        const dias = vencimento.getDate()+ parseInt(parseInt(vacina.age) + parseInt(vacina.daysInterval*(doseN-1)));
        vencimento.setDate(dias);
        const item = Paciente(paciente,doseN,()=>props.selectPaciente(paciente),vencimento);
         listPacientes.push(item);
    });
    doseN++;
})
    return (
        <Grid>
            <Grid.Column>
                    <Header>
                       {vacina.name}
                        <Header.Subheader>
                            <b>Período: </b>de {props.homeState.dataDe.toLocaleDateString()} até {props.homeState.dataAte.toLocaleDateString()}<br/>
                            <b>Pacientes encontrados: </b>{listPacientes.length}<br/>
                        </Header.Subheader>
                    </Header>
                    <Header>Pacientes:</Header>
                    <Segment style={{ height: "47vh", overflow: 'auto' }}>
                        <List divided relaxed>
                           {listPacientes}
                        </List>
                </Segment>
            </Grid.Column>
        </Grid>

    );
}


function Paciente (paciente,doseN,action,vencimento){

    let nascimento = new Date(paciente.DataNascimento);
    //let idade = new Date("2000-01-01 00:00:00");
   // idade = new Date(idade.getTime() + (new Date()-nascimento));
    return (<List.Item key={paciente.id} onClick={action}>
        <List.Icon name='user' size='large' verticalAlign='middle' />
        <List.Content>
            <List.Header as='a'>{paciente.Nome}</List.Header>
            <List.Description as='a'>
               {/*Idade: {idade.getYear()>100?idade.getYear()-100+" anos":""} {idade.getMonth()>0?","+idade.getMonth()+" meses e ":idade.getYear()>100?" e ":''}{idade.getDate()+" dias"}<br/>*/}
                <b>Nascimento:</b> {nascimento.toLocaleDateString()}<br/>
                <b>Idade Atual:</b> {Useful.calculateAge(nascimento).toString()}<br/>
                <b>Idade no Vencimento:</b> {Useful.calculateAge(nascimento,vencimento).toString()}<br/>
                <b>Vencimento:</b> {vencimento.toLocaleDateString()}<br/>
                <b>Dose:</b> {doseN}º<br/>
            </List.Description>
        </List.Content>
    </List.Item>);
}

export default Pacientes;
