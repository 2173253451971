 const link = encodeURI('http://api.clinica28dejulho.com.br/');
 //const link = encodeURI('http://localhost:8080');

  export {link};
  export const receive = (path,jsonString) => new Promise(resolve=>{
    const url = encodeURI(link+path+"?json="+jsonString);
    //get na api usando método fetch
    fetch(url)
    .then((response) => response.json().then(json=>resolve(json)))
    .catch((error) => {
      console.error(error);
    });


  });
  export const receiveGet = (path) => new Promise(resolve=>{
    const url = encodeURI(link+path);
    //get na api usando método fetch
    fetch(url)
    .then((response) => response.json().then(json=>resolve(json)))
    .catch((error) => {
      console.error(error);
    });


  });

  export const send = (path,method,jsonString)=> new Promise(resolve=>{

    const url = encodeURI(link+path);

    var request = new XMLHttpRequest();
    request.open(method, url, true);
    request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
    request.send(jsonString);
    request.onreadystatechange = ()=>{
      if(request.readyState === XMLHttpRequest.DONE && request.status === 200) {
        resolve(JSON.parse(request.responseText));
      }
    }

  });

  export const sendFile = (path,file,token,id)=> new Promise((resolve,reject)=>{

    const url = encodeURI(link+path);

    let formData = new FormData();
    formData.append('arquivo', file, file.name);
    formData.append('token', token);
    formData.append('id', id);
    let xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.onload = function (e) {
        if (xhr.status === 200) {
          resolve(JSON.parse(xhr.responseText));
        } else {
          reject('An error occurred!');
        }
    };
    xhr.send(formData);

  });


 


 