import React, { Component } from 'react';
import {Dimmer } from "semantic-ui-react";

import NewUser from './users/NewUser';
import UsersList from './users/UsersList';
import UserDetails from './users/UserDetails';
import ChangePassword from './users/ChangePassword';

import * as Manger from './../../../resources/Manger';



class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            publicidades:[],
            newIsActive:false,
            user:{id:0,nick:"",name:"",description:""},
            userReset:{id:0,nick:"",description:""},
            active:1,
            Users:[],
            accesslevels:[],

        }
    }

    componentDidMount(){
        this.UpdateImagens();
        this.Refresh = setInterval(()=>{this.UpdateImagens();},5000);

    }

    componentWillUnmount(){
        clearInterval(this.Refresh);
    }

    Refresh = null;
    UpdateImagens(){
        Manger.getUsers().then(result=>{
            let user = this.state.user;
            if(typeof(result.users) !== 'undefined'){
            result.users.forEach(element => {
                if(user)
                if(user.user_id === element.user_id){
                    user = element;
                }
            });
            this.setState({Users:result.users,user:user,accesslevels:result.accesslevels});
        }
        });
    }

    handleNew=value=>{
        this.setState({newIsActive:value});
    }
    setActive=(active,element)=>{
        if(active === 1){
            this.UpdateImagens();
            this.setState({active:active,});
        }else{
            this.setState({active:active,user:element});
        }
        
    }
    sendStatusUser=(id,active)=>{
        Manger.sendStatusUser(id,active).then(this.UpdateImagens());
    }
    deleteUser=(id)=>{
        Manger.deleteUser(id)
    }

    updateUser=(user,callback)=>{
        Manger.updateUser(user).then(result => {alert(result.message);if(result.success)callback();})
    }

    render() {

        //Processar antes de exebir
        const newC = (<NewUser active={this.state.newIsActive} accesslevels={this.state.accesslevels} handleNew={this.handleNew} setActive={this.setNewIsActive} update={()=>{this.UpdateImagens();}}/>);

        const usersList = (<UsersList handleNew={this.handleNew} setActive={this.setActive} Users={this.state.Users} sendStatusUser={this.sendStatusUser} />)
        const userDetails = (<UserDetails accesslevels={this.state.accesslevels} setActive={this.setActive} user={this.state.user} deleteUser={this.deleteUser}  updateUser={this.updateUser}/>);
        const changePassord = (<ChangePassword setActive={this.setActive} user={this.state.user} sendStatusUser={this.sendStatusUser}/>);
       
        let content = null;
        switch (this.state.active) {

            case 1: content = usersList;break;
            case 2: content = userDetails;break;
            case 3: content = changePassord;break;
            default: content = usersList;break;
        }

        return (
            <Dimmer.Dimmable dimmed={true}>
            {content}
            {newC}
            </Dimmer.Dimmable>
        );
    }
}

export default Users;