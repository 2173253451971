import React, { Component } from 'react';
import {Dimmer } from "semantic-ui-react";
import NewImagem from './chamadas/NewImagem';
import Imagem from './chamadas/Imagem';
import ImagemDetails from './chamadas/ImagemDetails';

import * as Manger from './../../../resources/Manger';



class Chamadas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            publicidades:[],
            newIsActive:false,
            imagem:{id:0,source:""},
            active:1,
            Imagens:[],

        }
    }

    componentWillMount(){
        this.UpdateImagens();
    }
    componentDidMount(){
        this.UpdateImagens();
        this.Refresh = setInterval(()=>{this.UpdateImagens();},5000);

    }

    componentWillUnmount(){
        clearInterval(this.Refresh);
    }

    Refresh = null;
    UpdateImagens(){
        Manger.getPubs().then(result=>{
            let imagem = this.state.imagem;
            if(typeof(result.publicidades) !== 'undefined'){
            result.publicidades.forEach(element => {
                if(imagem)
                if(imagem.id === element.id){
                    imagem = element;
                }
            });
            this.setState({Imagens:result.publicidades,imagem:imagem})
        }
        });
    }

    handleNew=value=>{
        this.setState({newIsActive:value});
    }
    setActive=(active,element)=>{
        if(active === 1){
            this.UpdateImagens();
            this.setState({active:active,});
        }else{
            this.setState({active:active,imagem:element});
        }
        
    }
    sendImageStatus=(id,active)=>{
        
        Manger.sendStatusPub(id,active).then(this.UpdateImagens());
    }
    deleteImage=(id,url)=>{
        Manger.deletePub(id,url)
    }

    render() {

        //Processar antes de exebir
        const newC = (<NewImagem active={this.state.newIsActive} handleNew={this.handleNew} setActive={this.setNewIsActive} update={()=>{this.UpdateImagens();}}/>);

        const ImagemC = (<Imagem handleNew={this.handleNew} setActive={this.setActive} Imagens={this.state.Imagens} sendImageStatus={this.sendImageStatus} url={Manger.url+"/"}/>)
        const ImagemCDetails = (<ImagemDetails setActive={this.setActive} Imagem={this.state.imagem} sendImageStatus={this.sendImageStatus} deleteImage={this.deleteImage} url={Manger.url+"/"+this.state.imagem.source}/>);
        let content = null;
        switch (this.state.active) {

            case 1: content = ImagemC;break;
            case 2: content = ImagemCDetails;break;
            default: content = ImagemC;break;
        }

        return (
            <Dimmer.Dimmable dimmed={true}>
            {content}
            {newC}
            </Dimmer.Dimmable>
        );
    }
}

export default Chamadas;