import React from "react";
import { Grid, List, Segment, Header, Checkbox, Button, Modal, Icon, Label, Form, Input } from "semantic-ui-react";

import * as Manger from './../../../../resources/Manger';

class PacienteDetails extends React.Component {
  constructor(props) {
    super(props);
    let outrasVacinas = [];

    let selectedVacina = props.homeState.vacinas[props.homeState.vacinaIndex];
    selectedVacina.checked = true;

    const vencimento = new Date(this.props.homeState.paciente.DataNascimento);
    const dias = vencimento.getDate()+ parseInt(parseInt(selectedVacina.age) + parseInt(selectedVacina.daysInterval*(0)));
    vencimento.setDate(dias);

    outrasVacinas.push(selectedVacina);

    props.homeState.vacinas.forEach(vacina => {
      vacina.dose.forEach(dose => {
        dose.pacientes.forEach(paciente => {
          if (
            paciente.id === props.homeState.paciente.id &&
            vacina.id !== selectedVacina.id
          ) {
            let outra = vacina;
            outra.checked = false;
            outrasVacinas.push(outra);
          }
        });
      });
    });

    this.GetVacinasAgendadas();

    this.state = {
      vacinasAgendadas: [],
      vacinasPendentes: outrasVacinas.slice(),
      agendar: false,
      data: new Date(),
      dataValue: vencimento.toISOString().substr(0, 10),
      loading: false,
      agendado: false,
      
    };
  }

  GetVacinasAgendadas() {
    Manger.vacinas.getVacinasAgendadas(this.props.homeState.paciente.id).then(result => {
      if (result.success) {
        this.setState({ vacinasAgendadas: result.vacinas });
      }
    });
  }
  AgendarSelecionadas() {
    let agendar = false;
    this.state.vacinasPendentes.forEach(vacina => {
      if (vacina.checked)
        agendar = true;
    });

    this.setState({ agendar: agendar });
  }
  confirmar = () => {
    this.setState({ loading: true });
    let agendas = [];
    this.state.vacinasPendentes.forEach(vacina => {
      let agenda = {
        vacinaId: vacina.id,
        agendamento: this.state.data.getTime(),
        pacienteId: this.props.homeState.paciente.id,
        dose: vacina.doses,
      };
      agendas.push(agenda);
    });

    Manger.vacinas.agendarVacinas(agendas).then((result) => {
      this.GetVacinasAgendadas();
      if (result.success) {
        //Colocar aqui o codigo de todas as vacinas que foram agendadas para atualizar o props das vacinas
      } else {
        alert("Falha na tentativa de reservar essas vacinas!");
      }
      this.setState({ loading: false, agendado: result.success });
    }).catch(falha => alert("Falha ao tentar agendar estas vacinas!"));

  };
  Formulario = () => {
    const isValidDate = (date = String) => {
      return !(date.toString() === "Invalid Date");
    };

    let vacinasParaAgendar = [];
    this.state.vacinasPendentes.forEach(vacina => {
      if (vacina.checked) {
        vacinasParaAgendar.push(
          <span key={vacina.id}>
            <b>*</b>
            {vacina.name}
          </span>
        );
        vacinasParaAgendar.push(<br key={vacina.id + "b"} />);
      }
    });

    const invalidLabel = (
      <Label basic color="red" pointing="above" size="mini">
        Inválida
      </Label>
    );

    const loading = (
      <Grid.Column textAlign='center'>
        <Header>Agendando...</Header>
      </Grid.Column>
    );

    const success = (
      <Grid>
        <Grid.Row>
          <Grid.Column>
            <Header>Agendado com sucesso!</Header>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );

    const actionsDone = (
      <Modal.Actions>
        <Button
          color="green"
          onClick={() => {
            this.setState({ agendar: false, agendado: false, success: false });
          }}
        >
          <Icon name="checkmark" />
          Concluir
        </Button>
      </Modal.Actions>
    );

    return (
      <Modal open={true} size="mini">
        <Header icon="archive">Agendar Vacinas<Header.Subheader>** No caso de vacinas já agendadas, serão reagendadas para a data selecionada.</Header.Subheader></Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              {this.state.loading ? (loading) : this.state.agendado ? (success) : (
                <Grid.Column>
                  <b>{this.props.homeState.paciente.Nome}</b>
                  <br /><br />
                  <b>Vacinas:</b><br />
                  {vacinasParaAgendar}<br />
                  <Form>
                    <Form.Field width={"8"}>
                      <label>Data da Aplicação</label>
                      <Input placeholder="00/00/0000" size="mini" type="date" name={"data"} value={this.state.dataValue} onChange={(ev, { value }) => {
                        this.setState({ dataValue: value, data: new Date(value) });
                      }} />
                      {isValidDate(this.state.data) ? null : invalidLabel}
                    </Form.Field>
                  </Form>
                </Grid.Column>
              )}
            </Grid.Row>
          </Grid>
        </Modal.Content>
        {this.state.loading ? null : this.state.agendado ? (actionsDone) : (
          <Modal.Actions>
            <Button
              color="red"
              onClick={() => this.setState({ agendar: false })}
            >
              <Icon name="close" />
              Cancelar
            </Button>
            <Button
              color="green"
              onClick={this.confirmar}
            >
              <Icon name="checkmark" />
              Confirmar
            </Button>
          </Modal.Actions>
        )}
      </Modal>
    );
  };

  render() {
    let listVacinas = [];
    let v = this.state.vacinasPendentes.slice();
    for (let i = 0; i < v.length; i++) {

      let add = true;
      if (i !== 0) add = !(v[i].id === v[i - 1].id);

      if (add)
        listVacinas.push(
          <List.Item key={i + "" + v[i].id}>
            <Checkbox
              checked={v[i].checked}
              readOnly={i === 0 ? true : false}
              onChange={(ev, { checked }) => {
                v[i].checked = checked;
                this.setState({ vacinasPendentes: v });
              }}
              label={<label>{v[i].name}</label>}
            />
          </List.Item>
        );
    }
    this.state.vacinasPendentes.forEach(vacina => { });
    const paciente = this.props.homeState.paciente;

    let vacinasAgendadas = [];
    let listVA = this.state.vacinasAgendadas;
    listVA.forEach((element, index) => {
      vacinasAgendadas.push(
        <List.Item key={index + "" + element.id}>
          <label>{element.name} - <b>{element.cancelada > 0 ? "Cancelada " + new Date(element.agendamento).toLocaleDateString() : element.dataAplicada === null ? "Agendada " + new Date(element.agendamento).toLocaleDateString() : "Aplicada no dia " + new Date(element.dataAplicada).toLocaleDateString()}</b></label>
        </List.Item>
      );
    });

    return (
      <Grid>
        {this.state.agendar ? this.Formulario() : null}
        <Grid.Column>
          <Header>
            {paciente.Nome}
            <Header.Subheader>
              <b>Período:</b> {this.props.homeState.dataDe.toLocaleDateString()} - {this.props.homeState.dataAte.toLocaleDateString()}<br/><br/>
              <Grid stackable>
                <Grid.Row columns={2}>
                  <Grid.Column>
                    <b>Mãe: </b> {paciente.Mãe}<br />
                    <b>Responsável: </b> {paciente.Responsável}<br />
                    <b>Fone: </b> {paciente.Fone}<br />
                    <b>Celular: </b> {paciente.Celular}<br />
                    <b>Código: </b> {paciente.SISMEDCódigo}<br />
                  </Grid.Column>
                  <Grid.Column>
                    <b>Município: </b> {paciente.Município}<br />
                    <b>Nascimento: </b>{new Date(paciente.DataNascimento).toLocaleDateString()}<br />
                    <b>Idade Atual: </b>{Manger.Useful.calculateAge(new Date(paciente.DataNascimento)).toString()}<br />
                    <b>Sexo: </b> {paciente.Sexo}<br />
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Header.Subheader>
          </Header>

          <Grid columns={'2'}>
            <Grid.Column>
              <Header>Vacinas no mesmo período:</Header>
              <Segment style={{ height: "30vh", overflow: "auto" }}>
                <List divided relaxed>
                  {listVacinas}
                </List>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Header>Vacinas agendadas:</Header>

              <Segment style={{ height: "30vh", overflow: "auto" }}>
                <List divided relaxed>
                  {vacinasAgendadas}
                </List>
              </Segment>
            </Grid.Column>
          </Grid>
          <br/>
          <Button primary onClick={() => this.AgendarSelecionadas()}>
            Agendar Selecionadas
          </Button>
        </Grid.Column>
      </Grid>
    );
  }
}

export default PacienteDetails;
