import React, { Component } from 'react';
import { Menu, Icon} from "semantic-ui-react";
import * as Auth from '../../../resources/Auth';

//Menu Itens
import Users from './Users';


class UsersMananger extends Component {

    
    constructor(props) {
   
        Auth.getUser()
            .then(result => {this.setState({ user: result});})
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            handleItemClick:props.handleItemClick,
            visibleBar:true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "users": content = <Users />; break;

            default:
                break;
        }

        this.state.handleItemClick(e,name,content);

    }
    componentDidMount(){
    }

    componentWillUnmount(){
    }

    render() {

        const { activeItem } = this.props

        return (<Menu.Item
            name='users'
            active={activeItem === 'users'}
            onClick={this.handleItemClick}
        >
            <Icon name='users' />
            Usuários
         </Menu.Item>);
    }
}

export default UsersMananger;