import React, { Component } from 'react';
import { Grid, Header, Icon, Breadcrumb, Segment} from "semantic-ui-react";
import Home from './previsoes/Home';
import Busca from './previsoes/Busca';
import Resultado from './previsoes/Resultado';
import Pacientes from './previsoes/Pacientes';
import PacienteDetails from './previsoes/PacienteDetails';
import * as Manger from './../../../resources/Manger';



class Mananger extends Component {
    constructor(props) {
        super(props);

        let dataDe = new Date();
        dataDe.setDate(dataDe.getDate()+30);
        let dataAte = new Date();
        dataAte.setDate(dataDe.getDate()+60);
        Manger.vacinas.list().then(result => {
            let homeState = this.state.homeState;
            homeState.vacinas =  result.vacinas;
            for (let i = 0; i< homeState.vacinas.length; i++) {
                homeState.vacinas[i].selected = true;
            }
            this.setState({
                homeState:homeState,
            });
            
        });

        this.state = {
            breadCrumb : (<div></div>),
            homeState : {
                vacinas:[],
                de:dataDe.toISOString().substring(0,10),
                ate:dataAte.toISOString().substring(0,10),
                dataDe:dataDe,
                dataAte:dataAte,
                maxAge:"10",
                vacinaIndex:0,
                paciente:{},
            },
            content:'home',
        }
    }

    componentDidMount(){
       
    }

    //#################################
    //####    HANDLE FUNCTIONS      ###
     //#################################
    setContent=(content = String(),callback = function(){})=>{
        if(content === "home"){
            Manger.vacinas.list().then(result => {
                let homeState = this.state.homeState;
                homeState.vacinas =  result.vacinas;
                for (let i = 0; i< homeState.vacinas.length; i++) {
                    homeState.vacinas[i].selected = true;
                }
                this.setState({
                    homeState:homeState,
                });
                
            });
        }
        this.setState({content:content});
    }
    setBreadCrumb=(breadCrumb)=>{
        this.setState({breadCrumb:breadCrumb});
    }
    setHomeState=(homeState)=>{
        this.setState({homeState});
    }

    selectVacina=(index)=>{
        let h = this.state.homeState;
        h.vacinaIndex = index;
        this.setState({content:'pacientes',homeState:h});
    }
    selectPaciente(paciente){
        let h = this.state.homeState;
        h.paciente = paciente;
        this.setState({content:"pacienteDetails",homeState:h});
    }


    //#################################
    //####    VIEW COMPONENTS      ###
    //#################################

    render() {
        let content = null;
        let breadCrumb = null;
        switch(this.state.content)
        {
            case 'pacienteDetails':content = <PacienteDetails setContent={this.setContent} homeState={this.state.homeState} setHomeState={this.setHomeState}/>;
            breadCrumb = <Breadcrumb>
            <Breadcrumb.Section link onClick={() => this.setContent("home")}>Início</Breadcrumb.Section>
            <Breadcrumb.Divider icon='right angle' />
            <Breadcrumb.Section link onClick={() => this.setContent("resultado")}>Resultado</Breadcrumb.Section>
            <Breadcrumb.Divider icon='right angle' />
            <Breadcrumb.Section link onClick={() => this.setContent("pacientes")}>{this.state.homeState.vacinas[this.state.homeState.vacinaIndex].name}</Breadcrumb.Section>
            <Breadcrumb.Divider icon='right angle' />
            <Breadcrumb.Section active>{this.state.homeState.paciente.Nome}</Breadcrumb.Section>
            </Breadcrumb>;
            break;
            case 'pacientes':content = <Pacientes selectPaciente={(paciente)=>this.selectPaciente(paciente)} setContent={this.setContent} homeState={this.state.homeState} setHomeState={this.setHomeState}/>;
            breadCrumb = <Breadcrumb>
            <Breadcrumb.Section link onClick={() => this.setContent("home")}>Início</Breadcrumb.Section>
            <Breadcrumb.Divider icon='right angle' />
            <Breadcrumb.Section link onClick={() => this.setContent("resultado")}>Resultado</Breadcrumb.Section>
            <Breadcrumb.Divider icon='right angle' />
            <Breadcrumb.Section active>{this.state.homeState.vacinas[this.state.homeState.vacinaIndex].name}</Breadcrumb.Section>
            </Breadcrumb>;
            break;
            case 'resultado':content =<Resultado selectVacina={this.selectVacina} setContent={this.setContent} homeState={this.state.homeState} setHomeState={this.setHomeState}/>;
            breadCrumb = <Breadcrumb>
            <Breadcrumb.Section link onClick={() => this.setContent("home")}>Início</Breadcrumb.Section>
           <Breadcrumb.Divider icon='right angle' />
            <Breadcrumb.Section active>Resultado</Breadcrumb.Section>
            </Breadcrumb>;
            break;
            case 'home':content = <Home setContent={this.setContent} homeState={this.state.homeState} setHomeState={this.setHomeState}/>;
                       breadCrumb = <Breadcrumb>
                       <Breadcrumb.Section link onClick={() => this.setContent("home")}>Início</Breadcrumb.Section>
                       {/*<Breadcrumb.Divider icon='right angle' />*/}
                       {/*<Breadcrumb.Section active>Editar Perfil</Breadcrumb.Section>*/}
                       </Breadcrumb>;
                       break;
            case 'busca':content = <Busca setContent={this.setContent} />;break;
            default:break;
        }

        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='calendar' />
                        <Header.Content>Previsões de Vacinas</Header.Content>
                    </Header>
                    {breadCrumb}
                    
                    <Segment>
                           {content}
                    </Segment>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Mananger;