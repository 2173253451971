import React from "react";
import {
  Segment,
  Grid,
  Header,
  Icon,
  Label,
  List,
  Form,
  Button,
  Message,
  Popup
} from "semantic-ui-react";
import * as Manger from "./../../../../resources/Manger";

function Home(props) {
  const [message, setMessageVisible] = React.useState(false);
  //#################################
  //####    HANDLE FUNCTIONS      ###
  //#################################
  let setContent = props.setContent;
  const updateStates = () => {
    props.setHomeState(props.homeState);
  };
  const selectVacina = index => {
    let vacinas = props.homeState.vacinas.slice();
    vacinas[index].selected = !vacinas[index].selected;
    props.homeState.vacinas = vacinas;
    updateStates();
  };
  const selectAll = () => {
    let v = props.homeState.vacinas.slice();
    for (let i = 0; i < v.length; i++) {
      v[i].selected = true;
    }
    props.homeState.vacinas = v;
    updateStates();
  };
  const unSelectAll = () => {
    let v = props.homeState.vacinas.slice();
    for (let i = 0; i < v.length; i++) {
      v[i].selected = false;
    }
    props.homeState.vacinas = v;
    updateStates();
  };
  const isDigit = value => {
    for (let i = 0; i < value.length; i++) {
      if (value.charAt(i) !== "0") {
        if (!parseInt(value.charAt(i))) return false;
      } else {
        if (value.length <= 1) return false;
      }
    }
    return true;
  };
  const maskDate = ev => {
    let value = ev.target.value;
    let key = ev.key;

    if (key !== "Backspace" && key !== "Tab") {
      if (value.length < 2) {
        if (key !== "0") if (!isDigit(key)) ev.preventDefault();
      }
      if (value.length === 2 || value.length === 5) {
        switch (ev.target.name) {
          case "de":
            props.homeState.de = value + "/";
            props.setHomeState(props.homeState);
            break;
          case "ate":
            props.homeState.ate = value + "/";
            props.setHomeState(props.homeState);
            break;
          default:
            break;
        }
      }
      if (value.length > 2 && value.length < 5) {
        if (key !== "0") if (!isDigit(key)) ev.preventDefault();
      }
      if (value.length > 9) ev.preventDefault();
    }
  };
  
  const handleBuscar = () => {
    if (
      !(
        isValidDate(props.homeState.dataDe) &&
        isValidDate(props.homeState.dataAte) &&
        props.homeState.maxAge.length > 0 &&
        countSelecteds() > 0
      )
    ) {
      setMessageVisible(true);
      setTimeout(() => {
        setMessageVisible(false);
      }, 4000);
      return;
    }

    let daysToMs = days => {
      return Math.round(days * 24 * 60 * 60 * 1000);
    };

    let vacinas = props.homeState.vacinas;
    let inicio = props.homeState.dataDe;
    let fim = props.homeState.dataAte;

    let vacinasToSend = [];
    for (let i = 0; i < vacinas.length; i++) {
      if (vacinas[i].selected) {
        let doses = vacinas[i].doses;
        vacinas[i].dose = [];
        for (let j = 0; j < doses; j++) {
          vacinas[i].dose[j] = {
            de: new Date(inicio.getTime() - (daysToMs(vacinas[i].age) + daysToMs(vacinas[i].daysInterval * j))).getTime(),
            ate: new Date(fim.getTime() - (daysToMs(vacinas[i].age) + daysToMs(vacinas[i].daysInterval * j))).getTime(),
            deString: new Date(inicio.getTime() - (daysToMs(vacinas[i].age) + daysToMs(vacinas[i].daysInterval * j))).toLocaleDateString(),
            ateString: new Date(fim.getTime() - (daysToMs(vacinas[i].age) + daysToMs(vacinas[i].daysInterval * j))).toLocaleDateString(),
          };
        }
        vacinasToSend.push(vacinas[i]);
      }
    }
    //console.log(vacinasToSend);
    setContent("busca", null,
      Manger.vacinas.pacientesByVacinas(vacinasToSend).then(result => {
        props.homeState.vacinas = result.vacinas;
        updateStates();
        setContent("resultado");
      }));
  };
  const countSelecteds = () => {
    let v = props.homeState.vacinas.slice();
    let selecteds = 0;
    v.forEach(element => {
      if (element.selected) selecteds++;
    });
    return selecteds;
  };

  const isValidDate = (date = String) => {
    return !(date.toString() === "Invalid Date");
  };

  //#################################
  //####    VIEW COMPONENTS      ###
  //################################


  const idadeLabel = (
    <Label basic color="red" pointing="left" size="mini">
      Você precisa selecionar uma idade máxima!
    </Label>
  );
  const messageCampos = (
    <Message negative>
      <Message.Header>
        Você precisa preencher todos os campos corretamente!
      </Message.Header>
      <p>Verifique os campos e tente novamente.</p>
    </Message>
  );
  const listVacinas = () => {
    let v = props.homeState.vacinas.slice();
    let vacinas = [];
    for (let i = 0; i < v.length; i++) {
      const vacina = v[i];
      let item = (
        <List.Item
          as="a"
          key={"lsv" + i}
          onClick={(ev, { value }) => selectVacina(value)}
          value={i.toString()}
        >
          <Segment color={vacina.selected ? "green" : null} size="mini" compact>
            <Icon name="syringe" size="small" />
            {vacina.name}
          </Segment>
        </List.Item>
      );
      vacinas.push(item);
    }
    return vacinas;
  };

  return (
    <Grid>
      <Grid.Column>
        <Header>Parametros de Busca<Header.Subheader>Nesta tela você cofigura os parametros para a busca dos pacientes que tem vacinas para ser tomada no período escolhido.</Header.Subheader></Header>
        <div>
          {message ? messageCampos : null}

          <Form>
              <Form.Group>
              <Popup content='Inicio do período onde as vacinas precisarão ser tomadas' trigger={
                <Form.Input
                  label="Data Inicial"
                  placeholder="00/00/0000"
                  size="mini"
                  style={{width:"140px"}}
                  type={'date'}
                  name={"de"}
                  value={props.homeState.de}
                  onChange={(ev, { value }) => {
                    props.homeState.de = value;
                    props.homeState.dataDe = new Date(value+" 00:00:00");
                    updateStates();
                  }}
                  onKeyDown={ev => maskDate(ev)}
                />
              } />

              <Popup content='Fim do período onde as vacinas precisarão ser tomadas' trigger={
                <Form.Input
                  label='Data Final'
                  placeholder="00/00/0000"
                  style={{width:"140px"}}
                  type={'date'}
                  size="mini"
                  name={"ate"}
                  value={props.homeState.ate}
                  onChange={(ev, { value }) => {
                    props.homeState.ate = value;
                    props.homeState.dataAte =new Date(value+" 00:00:00");
                    updateStates();
                  }}
                  onKeyDown={ev => maskDate(ev)}
                />
              } />

              <Popup content='Idade máxima em anos para busca de pacientes.' trigger={
                <Form.Input
                  label='Idade'
                  size='mini'
                  style={{width:"75px"}}
                  type={'number'}
                  placeholder="10"
                  value={props.homeState.maxAge}
                  onChange={(ev, { value }) => {
                    if (isDigit(value)) {
                      props.homeState.maxAge = value;
                      updateStates();
                    }
                  }}
                />
              } />
    
               <Form.Input
                  size='mini'
                  width={'3'}
                  type={'hidden'}
                />
              {props.homeState.maxAge.length > 0 ? null : idadeLabel}
              </Form.Group>
          </Form>
          <Form style={{marginTop:"50px"}}>
            <Form.Group>
              <Form.Input>
                <h3>
                  Vacinas{" "}
                </h3>
              </Form.Input>
              <Form.Input>
                <Button
                  icon="checkmark"
                  content="Selecionar Tudo"
                  size="mini"
                  onClick={() => selectAll()}
                /></Form.Input>
              <Form.Input>
                <Button icon="close" content={'Deselecionar Tudo'} size="mini" onClick={() => unSelectAll()} />
              </Form.Input>



            </Form.Group>
          </Form>

          <Segment
            style={{ height: "28vh", overflow: "auto" }}
          >
            <List horizontal>{listVacinas()}</List>
          </Segment>
          <Label style={{ float: "left" }}>
            Selecionadas
            <Label.Detail>{countSelecteds()}</Label.Detail>
          </Label>
        </div>

        <Button floated={"right"} color="blue" onClick={() => handleBuscar()}>
          Buscar
        </Button>
      </Grid.Column>
    </Grid>
  );
}

export default Home;
