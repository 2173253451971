import React, { Component } from 'react';
import {Switch,Route} from 'react-router-dom';
import {getToken} from './resources/Auth';



//JANELAS
import Login from "./login/Login";
import PasswordRecovery from './password_recovery/PasswordRecovery';
import Dashboard from './dashboard/Dashboard';

class Main extends Component {

    constructor(props) {
        super(props);
    
        this.state = {
          hasError: false,
          content:null,
          }
      }


    render() {
      return(
        <Rotas setIsLogged={this.setIsLogged} user={this.state.user} login={this.login}/>
      );
    };
}


function Rotas(props) {
  
  const token = getToken();
  if (!(token !== null && token.length > 1))
    return <Login />

  return(
          <Switch>
            <Route exact path={"/"}>
              <Login/>
            </Route>
            <Route exact path={"/recovery"}>
              <PasswordRecovery/>
            </Route>
            <Route exact path={"/contact"}>
              <PasswordRecovery/>
            </Route>
            <Route exact path={"/dashboard"}>
               <Dashboard/>
            </Route>
          </Switch>
  );
}

export default Main;
