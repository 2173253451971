import React, { Component } from 'react'

import { Menu, Segment, Grid, Header, Icon, Message } from 'semantic-ui-react'
import Agendar from './Exames Terceirizados/Agendar'
import Pendentes from './Exames Terceirizados/Pendente'
import Agendados from './Exames Terceirizados/Agendados'


export default class ExamesTerceirizados extends Component {

    constructor(props) {

        super(props);
        this.state = {
            activeItem: 'agendar',
            visibleMessageSucess: true,
            visibleMessageWarning: true,
            pendente: {
                nameCompleto: undefined,
                dataNascimento: undefined,
                sexo: undefined,
                telefone: undefined,
                celular: undefined,
                whatsapp: false,
                clinicaDestino: undefined,
                exame: undefined,
                turno: undefined,
                dataPretendida: undefined,
                medicoSolicitante: undefined,
                observacao: undefined,
            },

        }
        this.handleDismiss = this.handleDismiss.bind(this);


    }
    handleDismiss(params) {

        if (params === "success") {

            this.setState({ visibleMessageSucess: false })
            setTimeout(() => {
                this.setState({ visibleMessageSucess: true })
            }, 2000)
 

        }if (params === "warning") {
            this.setState({ visibleMessageWarning: false })
            setTimeout(() => {
                this.setState({ visibleMessageWarning: true })
            }, 2000)
 
        } 
 


    }

    render() {
        let Dados = this.state.Dados;

        let component = null;
        switch (this.state.activeItem) {
            case "agendar": component = <Agendar ref='this.props.Agendar'  handleDismiss={(params)=>{this.handleDismiss(params)}}  />;
                break;
            case "pendentes": component = <Pendentes ref='this.props.Pendentes' formulario={Dados} />
                break;
            case "agendados": component = <Agendados formulario={Dados} agendadosProps={this.state.agendados} />
                break;

            default:
                break;
        }

        console.log(<ExamesTerceirizados />)

        return (

            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='calendar alternate outline' />
                        <Header.Content>Exames Terceirizados</Header.Content>
                    </Header>
                    <Menu compact size={'tiny'}>
                        <Menu.Item active={this.state.activeItem === 'agendar'} onClick={() => this.setState({ activeItem: 'agendar' })}>
                            <Icon name='edit' />Pré-agendar

                        </Menu.Item>
                        <Menu.Item active={this.state.activeItem === 'pendentes'} onClick={() => this.setState({ activeItem: 'pendentes' })}>
                            <Icon name='clock' />Pendentes
                        </Menu.Item>

                        <Menu.Item active={this.state.activeItem === 'agendados'} onClick={() => this.setState({ activeItem: 'agendados' })}>
                            <Icon name='calendar check' />Agendados
                        </Menu.Item>

                    </Menu>
                    <Message size='mini' success floating
                        hidden={this.state.visibleMessageSucess}
                        onDismiss={() => this.handleDismiss("success")}
                        icon='check'
                        header='Cadastro realizado com sucesso!'
                    />
                    <Message size='mini'  warning floating
                        hidden={this.state.visibleMessageWarning}
                        onDismiss={() => this.handleDismiss("warning")}
                        icon='exclamation circle'
                        header='Falhou :('
                    />
                    <Segment style={{ height: (window.innerHeight - 200), overflow: 'auto' }}>

                        {component}
                    </Segment>

                </Grid.Column>
            </Grid>

        )
    }
}