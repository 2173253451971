import React from 'react';
import { Table, Segment, Label, Divider, Header, Input, Grid, Button, Form, Select, GridRow, Icon, Menu, GridColumn } from 'semantic-ui-react';

export default class ActionSite extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actions: [],
            rankOrigens: [],
            orcsViewed: "0",
            marcacoesSite: "0",

            valor1: "",
            valor2: "",

            buscaItem1: "",
            buscaItem2: "",

            selectDate: "1",   // recebe a selecao das options do periodo
            selectDe: "",    ////usando no header posição
            selectAte: "",     ////usando no header posição

            de: new Date(),
            ate: new Date(),

            disabledPeriodo: true,
            optionsDefaultValue: "1",

        }

    }
    acessar() {

        let de = new Date();
        let ate = new Date();


        switch (this.state.selectDate) {
            case '0':
                de = new Date(this.state.de);
                ate = new Date(this.state.ate);


                // if (new Date(this.state.de) <= new Date()) {

                // } else {

                //     console.log("aqui DE")

                // }

                // if (new Date(this.state.de) <= new Date() && new Date(this.state.ate) <= new Date()) {

                // } else {

                //     console.log("aqui ATE")

                // }


             /**  console.log("###########################");
                console.log("1 DE :");
                console.log(de);
                console.log("1 ATE :");
                console.log(ate);
                console.log("---------------------------");

                let deLocal = de;
                let ateLocal = ate;
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                console.log("1 DE :");
                console.log(de);
                console.log("1 ATE :");
                console.log(ate);
                console.log("###########################");
           */       ; break;

            case '1': //Hoje
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '2'://Ontem
                de.setDate(de.getDate() - 1);
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setDate(ate.getDate() - 1);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '3'://Esta semana (de dom até hoje)
                de.setDate(de.getDate() - de.getDay());
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '4'://Últimos dias (7)
                de.setDate(de.getDate() - 6);
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '5'://Semana passada (de dom a sáb)
                if (de.getDay() === 0) {
                    de.setDate(de.getDate() - 7);
                } else {
                    de.setDate((de.getDate() - de.getDay()) - 7);
                }
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setDate((ate.getDate() - ate.getDay()) - 1);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '6'://Últimos dias (14)'
                de.setDate(de.getDate() - 13);
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '7'://Este mês
                de.setDate(1)
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '8'://Últimos dias (30)
                de.setDate(de.getDate() - 30);
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;
            case '9'://Último mês
                de.setDate(1);
                de.setMonth(de.getMonth() - 1);
                de.setHours(0);
                de.setMinutes(0);
                de.setSeconds(0);
                ate.setDate(0);
                ate.setHours(23);
                ate.setMinutes(59);
                ate.setSeconds(59);
                break;

            default:
                break;

        }

        let deLocal = de;
        let ateLocal = ate;

        if (this.state.selectDate > 0) {

            let data = deLocal;
            let data2 = new Date(data.valueOf() - data.getTimezoneOffset() * 60000);
            let deData = data2.toISOString().replace(/\.\d{3}Z$/, '');
            //----
            let data3 = ateLocal;
            let data4 = new Date(data3.valueOf() - data3.getTimezoneOffset() * 60000);
            let ateData = data4.toISOString().replace(/\.\d{3}Z$/, '');

            this.setState({ selectDe: deData.substring(0, 16), selectAte: ateData.substring(0, 16) })
        }
        
        const url1 = encodeURI("http://api.clinica28dejulho.com.br/site/actions?de=" + de.getTime() + "&ate=" + ate.getTime());
        const url2 = encodeURI("https://clinica28dejulho.com.br/painelapi/api/orcsViewed?de=" + de.getTime() + "&ate=" + ate.getTime());
        const url3 = encodeURI("http://api.clinica28dejulho.com.br/site/rankOrigens?de=" + de.getTime() + "&ate=" + ate.getTime());
        const url4 = encodeURI("http://api.clinica28dejulho.com.br/site/marcacoesSite?de=" + de.getTime() + "&ate=" + ate.getTime());

        fetch(url1).then(result => result.json().then(json => {
            this.setState({ actions: json.actions })
        }));
        fetch(url2).then(result => result.json().then(json => {
            this.setState({ orcsViewed: json.views.views })
        }));

        fetch(url3).then(result => result.json().then(json => {
            this.setState({ rankOrigens: json.data })
        }));
        fetch(url4).then(result => result.json().then(json => {
            this.setState({ marcacoesSite: json.total })
        }));

    }

    /*acessarDeAte(){ 
       
        let de =   new Date(this.state.selectDe)

        let ate = new Date(this.state.selectAte)

        console.log("2 DE :");
        console.log(de );

        console.log("2 ATE :");
        console.log(ate );

        

        const url1 = encodeURI("http://api.clinica28dejulho.com.br/site/actions?de=" + de.getTime() + "&ate=" + ate.getTime());
        const url2 = encodeURI("https://clinica28dejulho.com.br/painelapi/api/orcsViewed?de=" + de.getTime() + "&ate=" + ate.getTime());
        const url3 = encodeURI("http://api.clinica28dejulho.com.br/site/rankOrigens?de=" + de.getTime() + "&ate=" + ate.getTime());
        const url4 = encodeURI("http://api.clinica28dejulho.com.br/site/marcacoesSite?de=" + de.getTime() + "&ate=" + ate.getTime());

        this.setState({ selectDe: de.toISOString().substring(0,16), selectAte: ate.toISOString().substring(0,16)})

        fetch(url1).then(result => result.json().then(json => {
            this.setState({ actions: json.actions })
        }));
        fetch(url2).then(result => result.json().then(json => {
            this.setState({ orcsViewed: json.views.views })
        }));

        fetch(url3).then(result => result.json().then(json => {
            this.setState({ rankOrigens: json.data }) 
        }));
        fetch(url4).then(result => result.json().then(json => {
            this.setState({ marcacoesSite: json.total })
        }));
    } */

    Buscar(value, className) {

        switch (className) {
            case "cliquesNoSite": this.setState({ buscaItem1: value });

                break;
            case "ranckDeOrigem": this.setState({ buscaItem2: value });

                break;


            default:
                break;
        }

    }

    componentDidMount() {
        this.acessar();
    }


    render() {

        const options = [
            { text: 'Personalizados', value: '0' },
            { text: 'Hoje', value: '1' },
            { text: 'Ontem', value: '2' },
            { text: 'Esta semana (de dom até hoje)', value: '3' },
            { text: 'Últimos dias (7)', value: '4' },
            { text: 'Semana passada (de dom a sáb)', value: '5' },
            { text: 'Últimos dias (14)', value: '6' },
            { text: 'Este mês', value: '7' },
            { text: 'Últimos dias (30)', value: '8' },
            { text: 'Último mês', value: '9' },
        ]

        let Rows1 = [];
        let cont1 = 0;
        let resultado = this.state.actions.filter(elemento => {
            return elemento.action.toLowerCase().includes(this.state.buscaItem1.toLowerCase())
        });

        resultado.forEach((element, index) => {
            cont1 += parseInt(element.clicks);
            if (this.state.valor1.length < 2) {
                Rows1.push(
                    <Table.Row key={index}>
                        <Table.Cell> {element.action} </Table.Cell>
                        <Table.Cell> {element.clicks} </Table.Cell>
                    </Table.Row>
                )
            } else {
                if (element.action.toUpperCase().includes(this.state.valor.toUpperCase())) {
                    Rows1.push(
                        <Table.Row key={index}>
                            <Table.Cell> {element.action} </Table.Cell>
                            <Table.Cell> {element.clicks} </Table.Cell>
                        </Table.Row>
                    )
                }
            }
        });
        let Rows2 = [];
        let cont2 = 0;
        this.state.rankOrigens.forEach((element, index) => {
            cont2 += parseInt(element.total);
            if (this.state.valor2.length < 2) {
                Rows2.push(
                    <Table.Row key={index}>
                        <Table.Cell>
                            <p style={{ width: '20vw', whitepace: "normal", display: "block", wordWrap: "break-word" }}> {element.origem} </p>
                        </Table.Cell>
                        <Table.Cell> {element.total} </Table.Cell>
                    </Table.Row>
                )
            } else {
                if (element.rankOrigens.toUpperCase().includes(this.state.valor2.toUpperCase())) {
                    Rows2.push(
                        <Table.Row key={index}>
                            <Table.Cell > {element.origem} </Table.Cell>
                            <Table.Cell> {element.total} </Table.Cell>
                        </Table.Row>
                    )
                }
            }
        });
 
 
        return (
            <Grid stackable columns={1}>
                <Grid.Row>
                    <Grid.Column>
                        <Grid.Row>
                            <Header as='h2'>
                                <Icon name='mouse pointer' />
                                <Header.Content > Ações do Site</Header.Content>
                            </Header>
                            <GridRow>
                                <Grid stackable columns={1}> {/*  Menu periodo */}
                                    <Grid.Column>
                                        <Grid.Row>
                                            <Form >
                                                <Form.Group widths='16'>


                                                    <Form.Field width='4' >

                                                        <Input disabled={this.state.disabledPeriodo} label='De:' labelPosition='left' type='datetime-local' value={this.state.selectDe}  focus={this.state.focoDe}
                                                            onChange={(event, { value }) => (new Date(value).getTime() <= new Date().getTime()) ? (new Date(this.state.selectAte) >= new Date(value)) ? this.setState({ selectDe: value, de: value ,focoDe:false }) :( alert("A data DE não pode ser maior do que a data ATÉ")): this.setState({ focoDe: true })} /> <br />
                                                         </Form.Field>
                                                    <Form.Field width='4' >

                                                        <Input disabled={this.state.disabledPeriodo} label='Até:' labelPosition='left' type='datetime-local' value={this.state.selectAte}  focus={this.state.focoAte}
                                                            onChange={(event, { value }) => (new Date(value).getTime() <= new Date().getTime()) ? (new Date(this.state.selectDe) <= new Date(value)) ? this.setState({ selectAte: value, ate: value,focoAte:false }) :( alert("A data ATÉ não pode ser maior do que a data DE")): this.setState({ focoAte: true })} />
                                                    </Form.Field>

                                                    <Form.Field
                                                        width='4'
                                                        control={Select}
                                                        options={options}
                                                        defaultValue={this.state.optionsDefaultValue}
                                                        placeholder='Calendário'
                                                        onChange={(event, { value }) => {
                                                            if (value === "0") {
                                                                let deLocal = new Date();
                                                                let ateLocal = new Date();
                                                                deLocal.setHours(0);
                                                                deLocal.setMinutes(0);
                                                                ateLocal.setHours(23);
                                                                ateLocal.setMinutes(59);

                                                                let data = deLocal;
                                                                let data2 = new Date(data.valueOf() - data.getTimezoneOffset() * 60000);
                                                                let deData = data2.toISOString().replace(/\.\d{3}Z$/, '');
                                                                //----
                                                                let data3 = ateLocal;
                                                                let data4 = new Date(data3.valueOf() - data3.getTimezoneOffset() * 60000);
                                                                let ateData = data4.toISOString().replace(/\.\d{3}Z$/, '');


                                                                this.setState({
                                                                    optionsDefaultValue: "0",
                                                                    disabledPeriodo: false,
                                                                    selectDate: value,

                                                                    selectDe: deData.substring(0, 16),
                                                                    selectAte: ateData.substring(0, 16),
                                                                })
                                                            } else {

                                                                this.setState({
                                                                    disabledPeriodo: true,
                                                                    selectDate: value,
                                                                })
                                                            }
                                                            setTimeout(() => {
                                                                this.acessar();
                                                            }, 500);

                                                        }} />
                                                    <Form.Field width='1' >
                                                        <Button icon='search' onClick={() => this.acessar()} attached='right' />
                                                    </Form.Field >
                                                </Form.Group>
                                            </Form>
                                        </Grid.Row>
                                    </Grid.Column>
                                </Grid>
                            </GridRow>

                            <Grid stackable columns={3} >
                                <GridColumn mobile={16} tablet={16} computer={5}>
                                    {/*  Cliques no Site */}
                                    <Segment color='blue' raised>
                                        <Menu pointing secondary >
                                            <Menu.Header>
                                                <Header>Cliques no Site</Header>
                                            </Menu.Header>
                                        </Menu>
                                        <Form.Field >
                                            <Input fluid value={this.state.buscaItem1} placeholder='Procurar...' className='cliquesNoSite' onChange={(event, { value, className }) => this.Buscar(value, className)} />
                                        </Form.Field>
                                        <Table celled >
                                            <Table.Header >
                                                <Table.Row>
                                                    <Table.HeaderCell  > Categorias  </Table.HeaderCell>
                                                    <Table.HeaderCell collapsing > Cliques  </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body >
                                                {Rows1}
                                            </Table.Body>
                                        </Table>
                                        <Divider >
                                            <Label style={{ float: 'right' }}>Total<Label.Detail>{cont1}</Label.Detail></Label>
                                        </Divider>
                                    </Segment >
                                </GridColumn>

                                <GridColumn mobile={16} tablet={16} computer={7}>
                                    {/*  Rank De Origem */}
                                    <Segment color='blue' raised>
                                        <Menu pointing secondary >
                                            <Menu.Header>
                                                <Header> Rank de Origem </Header>
                                            </Menu.Header>
                                        </Menu>
                                        <Form.Field>
                                            <Input fluid value={this.state.buscaItem2} placeholder='Procurar...' className='ranckDeOrigem' onChange={(event, { value, className }) => this.Buscar(value, className)} />
                                        </Form.Field>
                                        <Table celled >
                                            <Table.Header >
                                                <Table.Row>
                                                    <Table.HeaderCell> Endereços de Origens  </Table.HeaderCell>
                                                    <Table.HeaderCell collapsing> Quantidade  </Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body >
                                                {Rows2}
                                            </Table.Body>
                                        </Table>
                                        <Divider >
                                            <Label style={{ float: 'right' }}>Total<Label.Detail>{cont2}</Label.Detail></Label>
                                        </Divider>
                                    </Segment>
                                </GridColumn>

                                <GridColumn mobile={16} tablet={16} computer={4}>

                                    {/*   Marcações Pelo Site */}
                                    <Segment color='blue' raised>
                                        <Menu pointing secondary >
                                            <Menu.Header>
                                                <Header> Marcações Pelo Site</Header>
                                            </Menu.Header>
                                        </Menu>
                                        <Table celled >
                                            <Table.Header >
                                                <Table.Row>
                                                    <Table.HeaderCell > Quantidade </Table.HeaderCell>
                                                    <Table.Cell collapsing> {this.state.marcacoesSite} </Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Segment>

                                    <Divider clearing />
                                    <Segment color='blue' raised>
                                        <Menu pointing secondary>
                                            <Menu.Header>
                                                <Header >Orçamentos Visualizados</Header>
                                            </Menu.Header>
                                        </Menu>
                                        <Table celled >
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Quantidade</Table.HeaderCell>
                                                    <Table.Cell collapsing>{this.state.orcsViewed}</Table.Cell>
                                                </Table.Row>
                                            </Table.Header>
                                        </Table>
                                    </Segment>
                                </GridColumn>
                            </Grid>
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid >
        )
    }
}


// <Form.Field width='4' >
// <Input disabled={this.state.disabledPeriodo} label='De:' labelPosition='left' type='datetime-local'   value={this.state.selectDe}  focus={this.state.focoDe}
// onChange={(event, { value }) => value <= this.state.dataAtual.toISOString()  ? this.setState({ selectDe: value, de: value }):this.setState({focoDe:true})  } /> <br />


// </Form.Field>
// <Form.Field width='4' >
// <Input disabled={this.state.disabledPeriodo} label='Até:' labelPosition='left' type='datetime-local' value={this.state.selectAte}   focus={this.state.focoAte}
// onChange={(event, { value  }) => value <= this.state.dataAtual.toISOString() ?this.setState({ selectAte: value, ate: value }):this.setState({focoAte:true})  } />

// </Form.Field>