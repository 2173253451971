import React from 'react';
import { List, Segment, Grid, Header } from 'semantic-ui-react';


function Resultado(props) {

    //console.log(props);
    let listVacinas = [];
    let vendasPossíveis = 0;
    let index = 0;
    props.homeState.vacinas.forEach(vacina => {
        const val = index;
        let howMuch = 0;
        vacina.dose.forEach(dose=>{
            howMuch+=dose.pacientes.length;
        });
        if(howMuch > 0){
            vendasPossíveis+=howMuch;
            let item = Vacina(vacina,()=>props.selectVacina(val));
            listVacinas.push(item);
            
        }
        index++; 
    });


    return (
        <Grid>
            <Grid.Column>
                <Header>
                    PREVISÃO NO PERÍODO DE:<br/>
                    "<i>{props.homeState.dataDe.toLocaleDateString()}</i>" até "<b>{props.homeState.dataAte.toLocaleDateString()}</b>"<i></i>
                        <Header.Subheader>
                            <b>Vacinas encontradas: </b> {listVacinas.length}<br />
                            <b>Vendas possíveis: </b>{vendasPossíveis}<br/>
                            <b> Faixa de Idada:</b> 0 a {props.homeState.maxAge} anos<br />
                        </Header.Subheader>
                </Header>
                <Segment style={{ height: "55vh", overflow: 'auto' }} basic>
                    <List divided relaxed>

                        {listVacinas}

                    </List>
                </Segment>
            </Grid.Column>
        </Grid>

    );
}

function Vacina(vacina,action) {
 
    let totalPacientes = 0;
    vacina.dose.forEach(dose=>{
        totalPacientes+=dose.pacientes.length;
    })
    return (<List.Item key={vacina.id} onClick={action}>
        <List.Icon name='syringe' size='large' verticalAlign='middle' />
        <List.Content>
            <List.Header as='a'>{vacina.name}</List.Header>
            <List.Description as='a'>Clientes possíveis: {totalPacientes}</List.Description>
        </List.Content>
    </List.Item>);
}

export default Resultado;
