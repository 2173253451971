import React from 'react';
import {List } from 'semantic-ui-react';

import ListComponent from './Pendentes/ListComponent';

import { send } from '../../../../resources/API';

export default class Pendente extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            IconEdit: "edit",
            IconSave: "save",
            requiredForm: true,
            readOnlyForm: [],
            activeIndex: [],
            pendentes: [],
        }

        this.handleClick = this.handleClick.bind(this);
        this.handleConfirmarDados = this.handleConfirmarDados.bind(this);
        this.acessar();
    }

    acessar() {
        const url = "http://api.clinica28dejulho.com.br/examesterceirizados/getPendentes";
        fetch(url).then(result => result.json().then(json => {
            this.setState({ pendentes: json.pendentes })
        }))
    }

    componentWillMount() {
        this.acessar();
    }

    handleClick = (index) => {
        if (this.state.activeIndex === index) {
            this.setState({ activeIndex: -1 });
        } else {
            this.setState({ activeIndex: index })
        }
    }

    handleChange = (id, index, value) => {
        let pen = this.state.pendentes;
        //this.setState({ pendentes: pen }) 
        switch (id) {

            case "nameCompleto":
                pen[index].nameCompleto = value
                break;
            case "exame":
                pen[index].exame = value
                break;
            case "dataNascimento":
                pen[index].dataNascimento = value
                break;
            case "sexo":
                pen[index].sexo = value
                break;
            case "telefone":
                pen[index].telefone = value
                break;
            case "celular":
                pen[index].celular = value
                break;
            case "whatsapp":
                pen[index].whatsapp = value
                break;
            case "clinicaDestino":
                pen[index].clinicaDestino = value
                break;
            case "turno":
                pen[index].turno = value
                break;
            case "dataPretendida":
                pen[index].dataPretendida = value
                break;
            case "medicoSolicitante":
                pen[index].medicoSolicitante = value
                break;
            case "observacao":
                pen[index].observacao = value
                break;
            default:
                break;



        }
        this.setState({ pendentes: pen });
    }



    handleConfirmarDados(element, index) {
        //element.preventDefault(); 

        //<ExamesTerceirizados dadosAgendar={this.state} />
        console.log(JSON.stringify(element))
        const url = "http://api.clinica28dejulho.com.br/examesterceirizados/agendar";
        fetch(url, {
            method: 'POST', //enviando os dados 
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(this.state)

        }).then(result =>
            console.log(result)

        ).catch(
            console.log("ainda não deu certo")
        );
    }

    handlercancelar(element) {

        // let newpendentes = this.state.pendentes;
        // newpendentes.splice(index, 1);
        // this.setState({ pendentes: newpendentes })
        console.log(JSON.stringify(element))
        send("/examesterceirizados/delete", "DELETE", JSON.stringify(element)).then(json => {
            console.log(json)
        });

    }

    handleDisable(element, index) { 

    }

    render() {
        // const { activeIndex } = this.state;
   
        let itens = [];

        this.state.pendentes.forEach((element, index) => { 
            let item = <ListComponent element={element} index={index} activeIndex={this.state.activeIndex}  eadOnlyForm={this.state.readOnlyForm} handlercancelar={(element)=>this.handlercancelar(element)}   handleClick={(index)=>(this.handleClick(index))} />
        itens.push(item); 
        });
        return ( 
            <div >
                {this.props.children}
                <List divided verticalAlign='middle'>
                    {itens}
                </List>
            </div>
        )

    }
}