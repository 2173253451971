import React from 'react';
import { Button,Grid,Header,Form,Segment,Message, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom';

import { authenticate,setToken,getToken } from '../resources/Auth';

function Login(props) {

  let login = (<div></div>);
  const token = getToken();
  if (token !== null) {
    window.location.href = "/dashboard";
  }else{
    login = <LoginUI />;
  }

  return login;
}

function enter(user, password, fun) {
  authenticate(user, password).then(result => {

    if (result.match) {
      setToken(result.token);
      window.location.href = "/dashboard";
    } else {
      fun(true);
      setTimeout(() => fun(false), 6000);
    }

  });

}


function LoginUI(){


  const [user,setUser]  = React.useState('');
  const [password,setPassword] = React.useState('');
  const [falha,setFalha] = React.useState(false);


  return(
  
  <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='middle'>
  <Grid.Column style={{ maxWidth: 350 }}>
    <Header as='h2' color='blue' textAlign='center'>
      <Icon name={"key"} /> ACESSO RESTRITO
    </Header>
   {falha?
     <Message
      error
      header='Falha na Autenticação'
      content='Seu usuário ou senha podem estar incorretos, por favor verifique os dados e tente novamente.'
      />:null
    }
    <Form size='large'>
      <Segment stacked>
      <Form.Input fluid icon='user' iconPosition='left' placeholder='E-mail ou usuário' value={user} onInput={e=>setUser(e.target.value)}/>
        <Form.Input
          fluid
          icon='lock'
          iconPosition='left'
          placeholder='Senha'
          type='password'
          value={password}
          onInput={e=>setPassword(e.target.value)}
        />

        <Button color='blue' fluid size='large' onClick={()=>enter(user,password,setFalha)}>
          Entrar
        </Button>
      </Segment>
    </Form>
    <Message>
      Esqueceu a senha? <Link to={"recovery"}>Recuperar</Link>
    </Message>
  </Grid.Column>
</Grid>);
}

export default Login;