import React from 'react';
import { Grid,Button,Segment,List,Breadcrumb,Radio,Icon,Image } from 'semantic-ui-react';


function Campaign(props) {
  
    let Imagens = [];
    props.Imagens.forEach(element => {
        const image = element;
        let img = element.thumbnail;
        Imagens.push( <List.Item key={element.id}>
            <Image src={img.substr(0,img.length-1)} size='small'/>
            <List.Content onClick={()=>props.setActive(2,image)}>
                <List.Header as='a'>{element.título} <Icon name='edit' size={'small'}/> </List.Header>
                <List.Description as='a'>{element.descrição}</List.Description>
            </List.Content>
            <Radio toggle style={{float:'right',}} checked={parseInt(image.isActive)===1?true:false} onClick={()=>props.sendImageStatus(image.id,image.isActive > 0?0:1)}/>
            </List.Item>);
        
    });

    return (
        <Grid >
                <Grid.Column>
                <Breadcrumb>
                    <Breadcrumb.Section link>Carrossel</Breadcrumb.Section>
                </Breadcrumb>
                <Segment>
                    <Button icon={'plus'} content={'Nova Imagem'} color={'blue'} size={'small'} onClick={ev=>props.handleNew(true)} />
                    <Segment style={{height:(window.innerHeight - 180), overflow:'auto'}}>
                        <List divided relaxed>

                           {Imagens}
                           
                        </List>
                    </Segment>
                    </Segment>
                </Grid.Column>
            </Grid>
    );
}

export default Campaign;