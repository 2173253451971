import React from 'react';
import { Form, Input, Grid, GridColumn, Checkbox, GridRow, Segment } from 'semantic-ui-react';
import ExamesTerceirizados from '../ExamesTerceirizados';

import { send } from '../../../../resources/API';


export default class Agendar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            nameCompleto: undefined,
            dataNascimento: undefined,
            sexo: undefined,
            telefone: undefined,
            celular: undefined,
            whatsapp: false,
            clinicaDestino: undefined,
            exame: undefined,
            turno: undefined,
            dataPretendida: undefined,
            medicoSolicitante: undefined,
            observacao: undefined,
        }
        //this.handleChange = this.handleChange.bind(this);
        this.handleConfirmarDados = this.handleConfirmarDados.bind(this);

    }
    handleChange(value, id) {


        switch (id) {
            case "nomeCompleto": this.setState({ nameCompleto: value });
                break;
            case "dataNascimento": this.setState({ dataNascimento: value });
                break;
            case "sexo": this.setState({ sexo: value });
                break;
            case "telefone": this.setState({ telefone: value });
                break;
            case "celular": this.setState({ celular: value });
                break;
            case "whatsapp": value ? this.setState({ whatsapp: false }) : this.setState({ whatsapp: true });
                break;
            case "clinicaDestino": this.setState({ clinicaDestino: value });
                break;
            case "exame": this.setState({ exame: value });
                break;
            case "turno": this.setState({ turno: value });
                break;
            case "dataPretendida": this.setState({ dataPretendida: value });
                break;
            case "medicoSolicitante": this.setState({ medicoSolicitante: value });
                break;
            case "observacao": this.setState({ observacao: value });
                break;

            default:
                break;
        }
    }



    handleConfirmarDados(e) {
        e.preventDefault();

        send("/examesterceirizados/agendar", "POST", JSON.stringify(this.state)).then(json => {
            if (json.success) {

                this.props.handleDismiss("success");
                this.setState({
                    nameCompleto: "",
                    dataNascimento: "",
                    sexo: "",
                    telefone: "",
                    celular: "",
                    whatsapp: false,
                    clinicaDestino: "",
                    exame: "",
                    turno: "",
                    dataPretendida: "",
                    medicoSolicitante: "",
                    observacao: "",
                });
               


            } else {
                this.props.handleDismiss("warning");
            }
        })


        // sessionStorage.setItem(dados,JSON.stringify(this.state));  
    }
 

    render() {
        const SexoOptions = [{ key: 'm', text: 'Masculino', value: 0 }, { key: 'f', text: 'Feminino', value: 1 }, { key: 'o', text: 'Outros', value: 2 },]
        const TurnoOtions = [{ key: 1, text: 'Matutino ( Manhã )', value: 0 }, { key: 2, text: 'Vespertino ( Tarde )', value: 1 },]


        console.log(<ExamesTerceirizados />)

        return (
            //  <Grid >
            //   <Grid.Column> 
            <Form onSubmit={this.handleConfirmarDados}   >
                <Grid>
                    <GridColumn>
                        <GridRow>

                            <Grid>
                                <GridColumn>
                                    <Form.Field required><label>Nome Completo:</label> <Input className='nomeCompleto' placeholder='Nome e Sobrenome' value={this.state.nameCompleto} onChange={(event, { value, className }) => this.handleChange(value, className)} /></Form.Field>
                                </GridColumn>
                            </Grid>

                            <Grid>
                                <Form.Field > <label>Data de Nascimento:</label> <Input id="dataNascimento" icon='calendar' iconPosition='left' type='date' value={this.state.dataNascimento} placeholder='__/__/____.' onChange={(event, { value, id }) => this.handleChange(value, id)} /> </Form.Field>
                                <Form.Field > <Form.Select id='sexo' label='Sexo:' options={SexoOptions} placeholder='Sexo' value={this.state.sexo} onChange={(event, { value, id }) => this.handleChange(value, id)} />   </Form.Field>
                                <Form.Field required >  <label>Telefone:</label> <Input id='telefone' icon='phone' iconPosition='left' type='number' placeholder='(XX) XXXX-XXXX' value={this.state.telefone} onChange={(event, { value, id }) => this.handleChange(value, id)} /> </Form.Field>
                                <Form.Field required ><label>Celular:</label> <Input id='celular' icon='mobile alternate' iconPosition='left' type='number' placeholder='(XX) X XXXX-XXXX' value={this.state.celular} onChange={(event, { value, id }) => this.handleChange(value, id)} /> </Form.Field>
                                <Form.Field > <div>  <br />  <Checkbox id='whatsapp' label='Whatsapp?' value={this.state.whatsapp} onChange={(event, { value, id }) => this.handleChange(value, id)} />  </div> </Form.Field>
                            </Grid>

                            <Grid stackable>
                                <GridRow >
                                    <GridColumn width='8'>
                                        <Form.Field required>
                                            {/*}   <label>Prestador:</label>  <Input id='clinicaDestino' placeholder='Clinica' value={this.state.clinicaDestino} onChange={(event, { value, id }) => this.handleChange(value, id)} />*/}
                                            <Form.Field >    <label>Exame:</label>  <Input id='exame' placeholder='Exame' value={this.state.exame} onChange={(event, { value, id }) => this.handleChange(value, id)} />
                                            </Form.Field>

                                        </Form.Field>
                                    </GridColumn>
                                    <GridColumn width='8'>
                                        <Form.Field ><label>Médico Solicitante:</label><Input id='medicoSolicitante' placeholder='Digite aqui o nome completo do médico solicitante' value={this.state.medicoSolicitante} onChange={(event, { value, id }) => this.handleChange(value, id)} ></Input> </Form.Field>

                                    </GridColumn>
                                </GridRow>
                            </Grid>
                            <Grid stackable columns={3}>
                                <GridColumn width='4'>
                                    <Form.Field ><label>Data Pretendida:</label><Input id='dataPretendida' type='date' value={this.state.dataPretendida} onChange={(event, { value, id }) => this.handleChange(value, id)} /> </Form.Field>
                                </GridColumn>
                                <GridColumn width='4' >
                                    <Form.Field  ><label>Turno:</label> <Form.Select compact id='turno' options={TurnoOtions} placeholder='Selecione o turno' value={this.state.turno} onChange={(event, { value, id }) => this.handleChange(value, id)} /> <br /></Form.Field>
                                </GridColumn>

                                <GridColumn width='8'>
                                    <Segment fluid>
                                        <Form.TextArea id='observacao' label='Obsercações:' placeholder='Digite aqui mais informações...' value={this.state.observacao} onChange={(event, { id, value }) => this.handleChange(value, id)} />

                                    </Segment>
                                </GridColumn>
                            </Grid>
                            <Grid>
                                <GridColumn>
                                    <Form.Button floated='right' primary type='onSubmit' > Confirmar  </Form.Button>
                                </GridColumn>
                            </Grid>

                        </GridRow>
                    </GridColumn>
                </Grid>
            </Form >


            //       </Grid.Column>
            //     </Grid>

        )
    }
}