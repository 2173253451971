import React from "react";
import { Button, Icon } from "semantic-ui-react";
//import Birthday from '../birthday'

export default function Imprimir(props) {
  function hendleNiv(params) {
    let tela = window.open();
    let elemento = window.document.getElementById("telaPrint").outerHTML;

    tela.document.write(document.children[0].innerHTML);

    tela.document.body.innerHTML = elemento;

    tela.window.print();

    tela.document.write(document.children[0].innerHTML);

    tela.document.body.innerHTML = elemento;

    setTimeout(() => {
      tela.close();
    }, 1000);
  }
  return (
    <Button
      floated="right"
      size="mini"
      style={{ marginTop: "0", textAlign: "center" }}
      onClick={() => hendleNiv(props.elem)}
      icon="print"
    >
      {" "}
      imprimir <Icon name="print"></Icon>{" "}
    </Button>
  );
}
