import * as API from './API';
import { getToken } from './Auth';


export const getUrlParameter = (parameter) => {
    let url_string = window.location.toLocaleString(); //window.location.href
    let url = new URL(url_string);
    let result = url.searchParams.get(parameter);
    return result;
}

export const getVideoImage = (path, secs) => new Promise(callback => {
    var me = this, video = document.createElement('video');
    video.crossOrigin = "Anonymous";
    video.onloadedmetadata = function () {
        if ('function' === typeof secs) {
            secs = secs(this.duration);
        }
        this.currentTime = Math.min(Math.max(0, (secs < 0 ? this.duration : 0) + secs), this.duration);
    };
    video.onseeked = function (e) {
        var canvas = document.createElement('canvas');
        canvas.height = video.videoHeight;
        canvas.width = video.videoWidth;
        var ctx = canvas.getContext('2d');
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        var img = new Image();
        img.src = canvas.toDataURL();
        callback.call(me, img, this.currentTime, e);
    };
    video.onerror = function (e) {
        callback.call(me, undefined, undefined, e);
    };
    video.src = path;
});


export const regiGet = (encerrados, inicialDate, finalDate) => new Promise(resolve => {

    const token = getToken();
    let Today = new Date();

    Today.setHours(20);
    Today.setMinutes(59);
    Today.setSeconds(59);
    let final = Today.toISOString().slice(0, 19).replace('T', ' ');
    if (typeof (finalDate) !== "undefined")
        final = finalDate.toISOString().slice(0, 19).replace('T', ' ');


    Today.setHours(-3);
    Today.setMinutes(0);
    Today.setSeconds(0);
    let inicial = Today.toISOString().slice(0, 19).replace('T', ' ');
    if (typeof (inicialDate) !== "undefined")
        inicial = inicialDate.toISOString().slice(0, 19).replace('T', ' ');


    let json = JSON.stringify({ token: token, encerrados: encerrados, inicial: inicial, final: final });

    API.receive("/regiGet", json).then(result => resolve(result));

});

export const changeRegiSituacao = (id, situação) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, id: id, situação: situação });

    API.receive("/changeRegiSituacao", json).then(result => resolve(result));

});


export const schedules = {

    set: (id_schedule, status) => new Promise(resolve => {

        const token = getToken();
        let json = JSON.stringify({ token: token, id_schedule: id_schedule, status: status });

        API.receive("/setSchedules", json).then(result => resolve(result));

    }),
    get: () => new Promise(resolve => {

        const token = getToken();
        let json = JSON.stringify({ token: token });

        API.receive("/getSchedules", json).then(result => resolve(result));

    }),
    updateOBS: (schedule) => new Promise(resolve => {

        const token = getToken();
        let json = JSON.stringify({ token: token, id: schedule.id, obs: schedule.obs });
        console.log("json");

        API.send("schedule/updateOBS", "POST", json).then(result => resolve(result));
    }),

}

export const getCarrossel = () => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token });

    API.receive("/getCarrossel", json).then(result => resolve(result));

});

export const getPubs = () => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token });

    API.receive("/getPubs", json).then(result => resolve(result));

});

export const sendImage = (image, id) => new Promise(resolve => {

    const token = getToken();
    API.sendFile("/sendImage", image, token, id).then(result => resolve(result));

});

export const sendPubImg = (image, id) => new Promise(resolve => {

    const token = getToken();
    API.sendFile("/sendPubImg", image, token, id).then(result => resolve(result));

});

export const sendStatusImage = (id, active) => new Promise(resolve => {
    const token = getToken();

    let json = JSON.stringify({ token: token, id, active });

    API.receive("/sendStatusImage", json).then(result => resolve(result));

});

export const sendStatusPub = (id, active) => new Promise(resolve => {
    const token = getToken();

    let json = JSON.stringify({ token: token, id, active });

    API.receive("/sendStatusPub", json).then(result => resolve(result));

});

export const deleteImage = (id, url) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, id: id, url: url });

    API.receive("/deleteImage", json).then(result => resolve(result));

});

export const deletePub = (id, url) => new Promise(resolve => {
    const token = getToken();
    let json = JSON.stringify({ token: token, id: id, url: url });

    API.receive("/deletePub", json).then(result => resolve(result));

});

export const addCarrossel = (data) => new Promise(resolve => {
    const token = getToken();
    data.token = token;
    let json = JSON.stringify(data);

    API.receive("/addCarrossel", json).then(result => resolve(result));

});

export const addPub = (título, descrição) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, título: título, descrição: descrição });

    API.receive("/addPub", json).then(result => resolve(result));

});

export const changePassword = (oldPassword, newPassword) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, oldPassword: oldPassword, newPassword: newPassword });

    API.receive("/changePassword", json).then(result => resolve(result));

});

export const updateMe = (user) => new Promise(resolve => {

    const token = getToken();
    user.token = token;
    let json = JSON.stringify({ user });

    API.receive("/updateMe", json).then(result => resolve(result));

});

export const getImagensChamadas = () => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token });

    API.receive("/getCarrossel", json).then(result => resolve(result));

});



export const getUsers = () => new Promise(resolve => {

    const token = getToken();
    API.receiveGet("/getUsers?token=" + token).then(result => resolve(result));
});

export const changeUserPassword = (user_id, newPassword) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, user_id: user_id, newPassword: newPassword });

    API.receive("/changeUserPassword", json).then(result => resolve(result));

});

export const sendStatusUser = (user_id, active) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, user_id, active });

    API.receive("/sendStatusUser", json).then(result => resolve(result));

});

export const deleteUser = (user_id) => new Promise(resolve => {

    const token = getToken();
    let json = JSON.stringify({ token: token, user_id });

    API.receive("/deleteUser", json).then(result => resolve(result));

});

export const updateUser = (user) => new Promise(resolve => {

    const token = getToken();
    user.token = token;
    let json = JSON.stringify({ user });

    API.receive("/updateUser", json).then(result => resolve(result));

});

export const createUser = (user) => new Promise(resolve => {

    const token = getToken();
    user.token = token;
    let json = JSON.stringify({ user });

    API.receive("/createUser", json).then(result => resolve(result));

});

export const notifications = {

    spawnNotification(theBody, theTitle) {

        const options = {
            body: theBody,
            icon: "https://clinica28dejulho.com.br/favicon.ico"
        }
        if (!("Notification" in window)) {
            console.log("This browser does not support desktop notification");
        }

        // Let's check whether notification permissions have alredy been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            new Notification(theTitle, options);
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    new Notification(theTitle, options);
                }
            });
        }


    }
}

export const dashboard = {
    getNotifications: () => new Promise(resolve => {

        API.receiveGet("/dasboard/notifications").then(result => resolve(result));

    }),
}

export const vacinas =
{
    list: () => new Promise(resolve => {

        API.receive("/vacinas/list").then(result => resolve(result));

    }),
    pacientesByVacinas: (vacinas) => new Promise(resolve => {

        let json = JSON.stringify(vacinas);

        API.send("/vacinas/pacientesByVacinas", 'POST', json).then(result => resolve(result));

    }),
    agendarVacinas: (agendas) => new Promise(resolve => {


        let json = JSON.stringify(agendas);

        API.send("/vacinas/agendar", 'POST', json).then(result => resolve(result));

    }),
    getAgenda: (vacinaId) => new Promise(resolve => {
        API.receiveGet("/vacinas/getAgenda?id=" + vacinaId).then(result => resolve(result));
    }),

    getVacinasAgendadas: (pacienteId) => new Promise(resolve => {
        API.receiveGet("/vacinas/getVacinasAgendadas?pacienteId=" + pacienteId).then(result => resolve(result));
    }),

}

export const Useful = {
    calculateAge(beginDate,endDate){

        let Idade = {
            years: 0,
            months: 0,
            days: 0,
            toString(){

                let years =
                    this.years > 0 ?
                        this.years > 1 ?
                            this.years + " anos"
                            : this.years + " ano"
                        : "";
                let months =
                    this.months > 0 ?
                        this.months > 1 ?
                            this.months + " meses"
                            : this.months + " mês"
                        : "";
                let days =
                    this.days > 0 ?
                        this.days > 1 ?
                            this.days + " dias"
                            : this.days + " dia"
                        : "";


                let age = "";
                if (years.length > 0) {
                    age += years;
                    if (months.length > 0 && days.length < 1) {
                        age += " e " + months;
                    } else {
                        if (months.length > 0 && days.length > 0) {
                            age += ", " + months + " e " + days;
                        }
                    }
                } else {
                    if (months.length > 0 && days.length < 1) {
                        age += months;
                    } else {
                        if (months.length > 0 && days.length > 0) {
                            age += months + " e " + days;
                        }
                    }
                }
                return age;
            }
        };
        if(endDate === undefined)
            endDate = new Date();

        let idadeDates = new Date("2000-01-01");
        idadeDates.setTime(idadeDates.getTime() + (endDate.getTime() - beginDate.getTime()));
        Idade.years = parseInt(idadeDates.getFullYear().toString().substr(2, 2));
        Idade.months = idadeDates.getMonth();
        Idade.days = idadeDates.getDate();

        return Idade;

    },
    
   

}

export const ExamesTerceirizados = {

    agendar:(cliente) => new Promise(resolve =>{

        let json = JSON.stringify(cliente);

        API.send("/examesterceirizados/agendar", 'POST', json).then(result => resolve(result));
    }),
    deletar:(id) => new Promise(resolve =>{

        let json = JSON.stringify(id);

        API.send("/examesterceirizados/delete", 'POST', json).then(result => resolve(result));
    }),

}

export const Orcamentos = {

   
    listPatients: () => new Promise(resolve => { 
        API.receiveGet("/orc/list").then(result => resolve(result));   
 
    }),

    listPatientsMarked: () => new Promise(resolve => { 
        API.receiveGet("/orc/marked/list").then(result => resolve(result));   
 
    }),
    markPatients: (data) => new Promise(resolve => {  

        let json = JSON.stringify(data);
        API.send("/orc/marked",'POST', json).then(result => resolve(result));   
 
    }),
    markObs: (data) => new Promise(resolve => {  

        let json = JSON.stringify(data); 
        API.send("/orc/marked/obs",'POST', json).then(result => resolve(result));   
 
    }),
  

}


export const url = API.link;