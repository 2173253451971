import React, { Component } from 'react';
import { Segment, Grid, Header, List, Icon, Dropdown, Label, Menu, Modal, Loader, Dimmer } from "semantic-ui-react";

import * as Manger from '../../../resources/Manger';



class Regi extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedules: [],
            agenda: [{ "id": "1", "id_médico": "1", "médico": "Médico de Teste", "inicio": "2020-01-23 08:00:00", "fim": "2020-01-23 12:00:00", "abertura": null, "fechamento": null, "pacientes": [{ "id": "1", "nome": "João Ferreira Oliveira", "celular": "73988958985", "especialidade": "Clinico Geral", "id_médico": "1", "agenda": "2020-01-23 09:00:00", "atendido": "0", "situação": "Nenhuma" }, { "id": "1", "nome": "Maxson Jordan Matos Araujo", "celular": "73988958985", "especialidade": "Clinico Geral", "id_médico": "1", "agenda": "2020-01-23 09:00:00", "atendido": "0", "situação": "Nenhuma" }] }],
            width: window.innerWidth,
            filter: "Todas as Situações",
            filterTipo: "Todos",
            encerrados: 0,
            loading:true,
        }
    }

    UNSAFE_componentWillMount() {
        this.UpdateSchedules();
    }


    Resfresh = null;
    componentDidMount() {
        this.startUpdater();
    }

    componentWillUnmount() {
        this.stopUpdater();
    }



    UpdateSchedules() {
        Manger.regiGet(this.state.encerrados)
            .then(result => {
                this.setState({ agenda: result.agenda,loading:false });
            }
            );
    }
    setFilterSituação(active) {
        this.setState({ filter: active });
    }
    setFilter(active) {
        this.setState({ filterTipo: active });
    }
    showencerrados(val) {
        this.setState({ agenda: [],loading:true });
        this.setState({ encerrados: val });
        Manger.regiGet(val)
            .then(result => {
                this.setState({ agenda: result.agenda,loading:false });
            }
            );
    }
    startUpdater() {
        this.Resfresh = setInterval(() => { this.UpdateSchedules(); }, 15000);
    }
    stopUpdater() {
        clearInterval(this.Resfresh);
    }
     mysqlTimeStampToDate(timestamp) {
        //function parses mysql datetime string and returns javascript Date object
        //input has to be in this format: 2007-06-05 15:26:02
        if(timestamp !== null){
        var regex=/^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9]) (?:([0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;
        var parts=timestamp.replace(regex,"$1 $2 $3 $4 $5 $6").split(' ');
        return new Date(parts[0],parts[1]-1,parts[2],parts[3],parts[4],parts[5]);
        }else
        {
            let dataErro =  new Date(0);
            console.log(dataErro);
            return dataErro;
        }
      }
    render() {

        const filter = [{ key: 'tho', value: 'Todos', text: 'Todos' },
        { key: 'cst', value: 'Consultas', text: 'Consultas' },
        { key: 'exm', value: 'Exames', text: 'Exames' }];
        const situações = [{ key: 'NHM', value: 'Nenhuma', text: 'Nenhuma' },
        { key: 'NAT', value: 'Não atende', text: 'Não atende' },
        { key: 'RM', value: 'Remarcado', text: 'Remarcado' },
        { key: 'DM', value: 'Desmarcado', text: 'Desmarcado' }];
        let situaçõesFiltro = [{ key: 'todas', value: 'Todas as Situações', text: 'Todas as Situações' }];
        situações.map((e) => situaçõesFiltro.push(e));
        let changeSituação = (paciente) => { Manger.changeRegiSituacao( paciente.id, paciente.situação).then(this.UpdateSchedules()) };
        let inputSituação = (paciente) => (<Dropdown options={situações} value={paciente.situação} size={'mini'} onChange={(event, { value }) => { paciente.situação = value; changeSituação(paciente); }}></Dropdown>);


        let schedules = [];

        let pendentesTotais = 0;
        let total = 0;



        if (this.state.encerrados < 1 && typeof (this.state.agenda.pacientes) === "undefined") {
            this.state.agenda.forEach(item => {

                let categorizado = this.mysqlTimeStampToDate(item.inicio) <= new Date() && item.fechamento === "0000-00-00 00:00:00";
                let pacientes = [];

                //Totais

                if (categorizado)
                    total += item.pacientes.length;

                item.pacientes.forEach(paciente => {

                    //Pedentes
                    if (paciente.situação === "Nenhuma" && categorizado)
                        pendentesTotais++;

                    if ((this.state.filter === "Todas as Situações" || paciente.situação === this.state.filter) && categorizado)
                        pacientes.push(

                            <Modal key={paciente.id + "rd"} onClose={() => { this.UpdateSchedules(); this.startUpdater() }} size='mini' trigger={<List.Item  as='a' onClick={() => { this.stopUpdater() }}>
                                <List.Icon name='user' />
                                <List.Content>
                                    <List.Header>{paciente.nome}</List.Header>
                                    <List.Description>
                                        <b>Tel:</b> {paciente.celular}    <br></br><b>Situação:</b> {inputSituação(paciente)}
                                    </List.Description>
                                </List.Content>
                            </List.Item>}>
                                <Modal.Header>{paciente.nome}</Modal.Header>
                                <Modal.Content>
                                    <Modal.Description>
                                        <b>Tel:</b> {paciente.celular}    <br></br><b>Situação:</b> {inputSituação(paciente)}
                                    </Modal.Description>
                                </Modal.Content>
                            </Modal>
                        );
                });


                if (pacientes.length > 0 && categorizado)
                    schedules.push(
                        <List.Item key={item.id + "rm"}>
                            <List.Icon name='doctor' size={'big'} />
                            <List.Content>
                                <List.Header>{item.médico}</List.Header>
                                <List.Description>
                                    <Grid columns={"equal"}>
                                        <Grid.Column>
                                          <Grid.Column><b>Atendimento: </b>{new Date(item.inicio).toLocaleString().substr(11, 2)}h as {new Date(item.fim).toLocaleString().substr(11, 2)}h </Grid.Column><Grid.Column><b>Pacientes: </b>{pacientes.length}</Grid.Column>
                                        </Grid.Column>
                                    </Grid>
                                    </List.Description>
                                <List.List>
                                    {pacientes}
                                </List.List>
                            </List.Content>
                        </List.Item>);

            });
        } else
            if (this.state.encerrados > 0 && typeof (this.state.agenda.pacientes) === "undefined") {
                //
              
                this.state.agenda.forEach(item => {

                    let data = new Date();
                    let categorizado = (item.fechamento !== "0000-00-00 00:00:00" && data.getHours() >= this.mysqlTimeStampToDate(item.inicio).getHours())?true:false;
                    let pacientes = [];

                    
                    
                    //Totais

                    if (categorizado)
                        total += item.pacientes.length;

                    item.pacientes.forEach(paciente => {

                        if ((this.state.filter === "Todas as Situações" || paciente.situação === this.state.filter) && categorizado)
                            pacientes.push(

                                <Modal key={paciente.id + "rd"} onClose={() => { this.UpdateSchedules(); this.startUpdater() }} size='mini' trigger={<List.Item as='a' onClick={() => { this.stopUpdater() }}>
                                    <List.Icon name='user' />
                                    <List.Content>
                                        <List.Header>{paciente.nome}</List.Header>
                                        <List.Description>
                                            <b>Tel:</b> {paciente.celular}    <br></br><b>Situação:</b> {inputSituação(paciente)}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>}>
                                    <Modal.Header>{paciente.nome}</Modal.Header>
                                    <Modal.Content>
                                        <Modal.Description>
                                            <b>Tel:</b> {paciente.celular}    <br></br><b>Situação:</b> {inputSituação(paciente)}
                                        </Modal.Description>
                                    </Modal.Content>
                                </Modal>
                            );
                    });


                    if (pacientes.length > 0 && categorizado)
                        schedules.push(
                            <List.Item key={item.id + "rc"}>
                                <List.Icon name='doctor' size={'big'} />
                                <List.Content>
                                    <List.Header>{item.médico}</List.Header>
                                    <List.Description>
                                    <Grid columns={"equal"}>
                                        <Grid.Column>
                                          <Grid.Column><b>Atendimento: </b>{new Date(item.inicio).toLocaleString().substr(11, 2)}h as {new Date(item.fim).toLocaleString().substr(11, 2)}h </Grid.Column><Grid.Column><b>Pacientes: </b>{pacientes.length}</Grid.Column>
                                        </Grid.Column>
                                    </Grid>
                                    </List.Description>
                                    <List.List>
                                        {pacientes}
                                    </List.List>
                                </List.Content>
                            </List.Item>);

                });
            }


            let loadingComp = (
                <Dimmer active inverted>
                  <Loader inverted>Carregando</Loader>
                </Dimmer>
              );
        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='calendar alternate outline' />
                        <Header.Content>Regi de Controle</Header.Content>
                    </Header>
                    <Menu compact size={'tiny'}>
                        <Menu.Item as='a' active={this.state.encerrados === 0} onClick={() => this.showencerrados(0)}>
                            <Icon name='clock' /> Tempo Real
                    <Label style={{ display: pendentesTotais > 0 ? '' : 'none' }} color='red' floating>
                                {pendentesTotais}
                            </Label>
                        </Menu.Item>
                        <Menu.Item as='a' active={this.state.encerrados === 1} onClick={() => this.showencerrados(1)}>
                            <Icon name='calendar' /> Encerrado
                    </Menu.Item>
                    </Menu>
                    <Menu compact size={'tiny'} floated={'right'}>
                        <Menu.Item as='a'>
                            <Icon name='checkmark box' />
                            <Dropdown
                                inline
                                header='Situação'
                                options={situaçõesFiltro}
                                defaultValue={situaçõesFiltro[0].value}
                                onChange={(ev, { value }) => this.setFilterSituação(value)}
                            />
                        </Menu.Item>
                        {this.state.encerrados > 0 ? <Menu.Item as='a'>
                            <Icon name='filter' />
                            <Dropdown
                                inline
                                header='Filtrar'
                                options={filter}
                                defaultValue={filter[0].value}
                                onChange={(ev, { value }) => this.setFilter(value)}
                            />
                        </Menu.Item> : null}

                    </Menu>
                    <Segment style={{ height: (window.innerHeight - 220), overflow: 'auto' }}>
                        <List divided relaxed>
                            {this.state.loading?loadingComp:schedules}
                        </List>
                    </Segment>
                    <Label style={{ float: 'right' }}>
                        Total
                    <Label.Detail>{total}</Label.Detail>
                    </Label>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Regi;