import React, { Component } from 'react';
import { Grid, Header, Icon, Segment, Form} from "semantic-ui-react";

//import * as Manger from './../../../resources/Manger';





class Agendadas extends Component {
    constructor(props) {
        super(props);

        this.state = {
            valor: "oi",
            valor2: "ola", 
        }
    }

    componentDidMount(){
       
    }

    handleValue(value){

        this.setState({valor:value});
 
    }

    deleteComponent(){
        this.setState({valor:""});
    }

    //#################################
    //####    VIEW COMPONENTS      ###
    //#################################

    render() {

        

        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='calendar' />
                        <Header.Content>Vacinas Agendadas</Header.Content>
                    </Header>
                    <Filter />
                    <Container />
                    
                    
                </Grid.Column>
            </Grid>
        );
    }
}

function Filter (props){

    return(
        <Segment>
        <Grid columns={1}>
            <Grid.Column>
                <Form>
                    <Form.Group>
                        <Form.Input type={'date'} label='Data inicial' width={4} size={'mini'} />
                        <Form.Input type={'date'} label='Data Final' placeholder='Middle Name' width={4} size={'mini'} />
                        <Form.Input label='Data Final' placeholder='Middle Name' width={4} size={'mini'}/>
                        <Form.Input type={'search'} value={props.valor} icon={'search'} name={"valor2"} label={'Pesquisa'} placeholder='Procurar...' width={8} size={'mini'} onChange={(ev,{value})=>props.funcao(value)} onClick={props.delete}/>
                    </Form.Group>
                </Form>
            </Grid.Column>
        </Grid>
        </Segment>
    );
}


function Container(props){
    return(<Segment style={{ height: "73vh", overflow: 'auto',backgroud:'black' }}>
        <span>Valor Digitado: {props.valor} </span>
    </Segment>);
}

export default Agendadas;