import React from "react";
import {
  Segment,
  Button,
  Form,
  Grid,
  Header,
  List,
  Icon,
  Divider,
  Checkbox, 
  Message, 
} from "semantic-ui-react";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";

const ListOrc = (props) => { 
   
  let listNamePatients = []; // a lista dos pacientes
  let descriptionPacient = []; // segment com dados do paciente
  let ViewOrc = [];  // iframe de orc
  let arrayPatient = []; 
 // let orc = []; 

  let item =  props.viewed ?  props.patientsOrcView[props.indexItem] : props.patientsOrcTotal[props.indexItem];  
  arrayPatient = props.viewed ? props.patientsOrcView : props.patientsOrcTotal; 
  arrayPatient.map((element, index, array) => {    
    let listLocal = [];  
    if (props.viewed) {    
    
    if (  element.viewed_at !== null && (element.confirmed_at === null  && element.deleted_at === null))  {
      listLocal = (
        <List.Item
          key={index}
          active={props.indexItem === index ? true : false}
        >
          <List.Item
            onClick={() => {
              props.handleClickItemList(index, element.orçamento);
              props.setTextObs(element.obs); 
            }}
          >
            <Segment
              raised
              style={{background:props.indexItem === index ? "#2a3f54" : "none"}}
              inverted={props.indexItem === index ? true : false}
            >
              <b>   
                {element.orçamento + "  -  "}
                {element.paciente === undefined ? "" : element.paciente}{" "}
                {element.viewed_at === null ? "" : <Icon name="eye"></Icon>}
              </b>
            </Segment>
          </List.Item>
        </List.Item>
      );
    }
    } else {
      
    if (  element.viewed_at === null  && (element.confirmed_at === null  && element.deleted_at === null))  {
      listLocal = (
        <List.Item
          key={index}
          active={props.indexItem === index ? true : false}
        >
          <List.Item
            onClick={() => {
              props.handleClickItemList(index, element.orçamento);
              props.setTextObs(element.obs); 
            }}
          >
            <Segment
              raised
              style={{background:props.indexItem === index ? "#2a3f54" : "none"}}
              inverted={props.indexItem === index ? true : false}
            >
              <b>  
                {element.orçamento + "  -  "}
                {element.paciente === undefined ? "" : element.paciente}{" "}
                {element.viewed_at === null ? "" : <Icon name="eye"></Icon>}
              </b>
            </Segment>
          </List.Item>
        </List.Item>
      );
    }
    }
    return listNamePatients.push(listLocal);
  });


  descriptionPacient = (
    <Segment secondary>   {
          item !== undefined ?
      <Grid stackable>
        <Grid.Column width="8">
     
          <Header>
          <br/>
            {item.paciente} <br />
            <HeaderSubHeader>
              Código : {item.orçamento} <br />
              Telefone:{item.celular} <br /> 
              {item.viewed_at !== null?  "Data de visualização:"+new Date(item.viewed_at).toLocaleString() : ""} <br /> 
            </HeaderSubHeader> 
          </Header> 
          
        </Grid.Column>
        <Grid.Column width="8">
          <Form>
            <Form.Field>
              <Form.TextArea
                label="Observações:"
                value={props.textObs}
                onChange={(event, { value }) => props.setTextObs(value)}
                onBlur={(event) =>  props.setTextObsBlur()}
              />{" "} 

            </Form.Field>
          </Form>
          <br />
          <Button
            floated="right"
            size="mini"
            color="blue"
            onClick={() => props.handleMark(props.indexItem)}
          >
            Marcar
          </Button>
          <Button
            floated="right"
            size="mini"
            color="red"
            onClick={() => props.handleDiscard(props.indexItem)}
          >
            Descartar
          </Button>
          
        </Grid.Column>
      </Grid>
      :  <Message info>Selecione um dos orçamentos da lista de {props.viewed?"visualizados":"não visualizados"} </Message> 
    }
    </Segment>
  );
  let tamanho=16;
  if (props.viewed === true  && item !== undefined )  {
    tamanho = 6;
    ViewOrc = (
      <Grid>
        <Grid.Column width="16">
          <iframe
            width="100%"
            height={window.innerHeight - 220}
            src={`https://clinica28dejulho.com.br/orc/?id=${item.orçamento}`}
            title="ORÇAMENTO"
          />
        </Grid.Column>
      </Grid>
    );
    
  }
 let height = props.height - 220;

  return ( 
    <>
      <Grid stackable>
        <Grid.Column width={tamanho}>
          <Grid.Column width="16">{descriptionPacient}</Grid.Column>
          <Divider />
          <Segment vertical> 
              <Checkbox   radio label="Visualizados" value={true} checked={props.viewed} onChange={(event,{value})=> props.handleClickViewed(value)  }/> 
              <Icon name="eye" style={{ marginRight: "15px", marginLeft: "15px" }}></Icon>
              <Checkbox   radio label="Não Visualizados" value={false} checked={!props.viewed} onChange={(event,{value})=> props.handleClickViewed(value)} /> 
              <Icon name="eye slash" style={{ marginLeft: "15px" }}></Icon>
          
          </Segment>
         
          <Grid.Column width="16" style={{ height: height, overflowY: "auto" }}>
            <List selection verticalAlign="middle">
              {listNamePatients}
            </List>
          </Grid.Column>
        </Grid.Column>

        <Grid.Column width="10" style={{ height: "auto" }}>
          {ViewOrc}
        </Grid.Column>
      </Grid>
    </>
  );
};

export default ListOrc;
