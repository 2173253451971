import React, { Component } from 'react';
import { Segment, Grid, Header, List, Icon, Button, Label, Menu, TextArea, Form } from "semantic-ui-react";

import * as Manger from '../../../resources/Manger';



class Schedules extends Component {
    constructor(props) {
        super(props);
        this.state = {
            schedules: [],
            width: window.innerWidth,
            filter: 2,
            marcados: 0,

        }
    }

    componentWillMount() {
        this.UpdateSchedules();
    }


    Resfresh = null;
    componentDidMount() {
        this.Resfresh = setInterval(() => { this.UpdateSchedules(); }, 4000);
    }

    componentWillUnmount() {
        clearInterval(this.Resfresh);
    }


    UpdateSchedules() {
        
        Manger.schedules.get()
            .then(result => {
                this.setState({ schedules: result.schedules });
            }
            );
    }
    setFilter(active) {
        this.setState({ filter: active });
    }
    showMarcados(val) {
        this.setState({ marcados: val });
    }

    updateOBS(index, value){
        let schedules = this.state.schedules;
        schedules[index].obs = value;
        this.setState({schedules:schedules});
    }

    applyOBS(index){
        Manger.schedules.updateOBS(this.state.schedules[index]).then(result=>{
            this.UpdateSchedules();
            this.Resfresh = setInterval(() => { this.UpdateSchedules(); }, 4000);
        }).catch(err=>{
            this.Resfresh = setInterval(() => { 

                Manger.schedules.updateOBS(this.state.schedules[index]).then(result=>{
                    clearInterval(this.Resfresh);
                    this.UpdateSchedules();
                    this.Resfresh = setInterval(() => { this.UpdateSchedules(); }, 4000);
                });

             }, 4000);
        });
        
    }

    render() {

        //Processar antes de exebir
        let schedules = [];
        let pendentesTotais = 0;
        this.state.schedules.forEach((schedule,index) => {
            let medico = (<div><b>Médico: </b>{schedule.médico}</div>);


            let st = schedule.user_id ? true : false;
            if (!st && schedule.obs.length<1)
                pendentesTotais++;

            //let status = (<div><b>Status: </b><Label size={'mini'} color={st?'green':'red'}>{st?'Marcado':'Pendente'}</Label></div>);
            let marcados = st ? 1 : 0;

            let agenda = (<div><b>Data e Hora: </b>{new Date(schedule.agenda).toLocaleString().substr(0, 16)}</div>);
            let origem = (<div style={{ width: "20em", wordWrap: "break-word" }}><b>Origem: </b>{schedule.origem === "" ? "Sem origem" : schedule.origem.substr(0, 33)}</div>);
            let exame = parseInt(schedule.exame);
            let filter = parseInt(this.state.filter);
            if ((exame === filter || this.state.filter === 2) && marcados === this.state.marcados)
                schedules.push(<List.Item key={schedule.id}>
                    <List.Icon name='calendar' size='large' verticalAlign='middle' />
                    <List.Content>
                <List.Header as='a'><h3>{schedule.paciente.toLocaleUpperCase()}<span style={{color:'red'}}>{schedule.obs.length<1?"*":""}</span></h3></List.Header>
                        <List.Description as='a'>
                            <Form>
                                <Form.Group>
                                <Form.Field>
                                    <b>CPF: </b>{schedule.cpf}<br />
                                    {schedule.interesse < 1 ? agenda : null}
                                    {schedule.exame > 0 || schedule.interesse > 0 ? null : medico}
                                    <b>{schedule.interesse > 0 ? "Interesse" : schedule.exame > 0 ? "Exame" : "Especialidade"}: </b>{schedule.especialidade}<br />
                                    {origem}
                                    <b>Tel: </b>{schedule.tel}
                                    <br/>
                                    <br/>
                                    <Button.Group size={"small"}>
                                        <Button color={st ? 'red' : 'blue'} onClick={() => { Manger.schedules.set(schedule.id, st ? 0 : 1).then(this.UpdateSchedules()); }}>{st ? 'Desmarcar' : 'Confirmar'}</Button>
                                        <Button secondary onClick={() => { Manger.schedules.set(schedule.id, 2).then(this.UpdateSchedules()); }}>Descartar</Button>
                                    </Button.Group>
                                </Form.Field>

                                <Form.Field width={"10"}>
                                    <b>Observações:</b><br /> 
                                        <TextArea placeholder='' onFocus={()=>{clearInterval(this.Resfresh)}} onBlur={ev=>this.applyOBS(index)}  value={schedule.obs} onChange={(ev,{value})=>{this.updateOBS(index,value);clearInterval(this.Resfresh)}}/>
                                    <br />
                                </Form.Field>

                                </Form.Group>
                            </Form>
                        </List.Description>
                    </List.Content>
                </List.Item>)
        });

        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='calendar alternate outline' />
                        <Header.Content>Agendamentos</Header.Content>
                    </Header>

                    <Menu compact size={'tiny'}>
                        <Menu.Item as='a' active={this.state.marcados === 0} onClick={() => this.showMarcados(0)}>
                            <Icon name='clock' /> Pendentes
                    <Label style={{ display: pendentesTotais > 0 ? '' : 'none' }} color='red' floating>
                                {pendentesTotais}
                            </Label>
                        </Menu.Item>
                        <Menu.Item as='a' active={this.state.marcados === 1} onClick={() => this.showMarcados(1)}>
                            <Icon name='calendar' /> Marcados
                    </Menu.Item>
                    </Menu>

                    <Menu compact size={'tiny'} floated={this.state.width < 520 ? null : 'right'}>
                        <Menu.Item as='a' active={this.state.filter === 2} onClick={() => this.setFilter(2)}>
                            <Icon name='archive' /> Todos
                    </Menu.Item>
                        <Menu.Item as='a' active={this.state.filter === 0} onClick={() => this.setFilter(0)}>
                            <Icon name='hospital' /> Consultas
                    </Menu.Item>
                        <Menu.Item as='a' active={this.state.filter === 1} onClick={() => this.setFilter(1)}>
                            <Icon name='lab' /> Exames
                    </Menu.Item>
                    </Menu>
                    <Segment style={{ height: "64vh", overflow: 'auto', }}>
                        <List divided relaxed>
                            {schedules}
                        </List>
                    </Segment>
                    <Label style={{ float: 'right' }}>
                        Total
                    <Label.Detail>{schedules.length}</Label.Detail>
                    </Label>
                </Grid.Column>
            </Grid>
        );
    }
}

export default Schedules;