import React from 'react';
import { Grid, Loader, Dimmer } from 'semantic-ui-react';

class Busca extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  render() {
    return (
      <Grid verticalAlign='middle' style={{ height: '70vh' }}>
        <Grid.Column textAlign='center'>
          <Dimmer active inverted>
            <Loader size='medium'>Buscando...</Loader>
          </Dimmer>
        </Grid.Column>
      </Grid>
    );
  }

  componentDidMount() {
    this.setState({

    });
  }
}

export default Busca;
