import React, { Component } from 'react';
import { Menu, Icon } from "semantic-ui-react";
import * as Auth from '../../../resources/Auth';

//Menu Itens
import Carrossel from './Carrossel';
import Chamadas from './Chamadas';
import ActionSite from './ActionSite';
import WhatsappMarketing from './WhatsappMarketing';


class Marketing extends Component {


    constructor(props) {
        Auth.getUser()
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            handleItemClick: props.handleItemClick,
            visibleBar: true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "Carrossel": content = <Carrossel />; break;
            case "chamadas": content = <Chamadas />; break;
            case "ActionSite": content = <ActionSite />; break;
            case "WhatsappMarketing": content = <WhatsappMarketing />;break;

            default:
                break;
        }

        this.state.handleItemClick(e, name, content);

    }
    componentDidMount() {
      
    }
    update = ()=>{
        const { activeItem } = this.props;
        if(activeItem === "marketingMenu")
             this.handleItemClick(null,{name:'ActionSite'});
    }
    componentDidUpdate(){
       this.update();
    }


    componentWillUnmount() {
        this.update = null;
    }

    render() {

        const { activeItem } = this.props;
        const active = (activeItem === "Carrossel" || activeItem === "chamadas" || activeItem === 'ActionSite' ||activeItem === 'WhatsappMarketing' || activeItem === 'marketingMenu');
       // if(activeItem === "marketingMenu")
            //this.handleItemClick(null,{name:'RankAction'});
        return (
            <Menu.Item
                name='marketingMenu'
                onClick={active ? null : this.handleItemClick}
            >
                <Icon name='bullhorn' />
             Marketing 28
                {active ?
                    <Menu.Menu>
                        <Menu.Item
                            name='ActionSite'
                            active={activeItem === 'ActionSite'}
                            onClick={this.handleItemClick}
                        >
                            Ações do Site
                        </Menu.Item>
                        <Menu.Item
                            name='Carrossel'
                            active={activeItem === 'Carrossel'}
                            onClick={this.handleItemClick}
                        >
                            Carrossel do Site
                        </Menu.Item>

                        <Menu.Item
                            name='chamadas'
                            active={activeItem === 'chamadas'}
                            onClick={this.handleItemClick}
                        >
                            Marketing Interno
                        </Menu.Item>
                        <Menu.Item
                        name='WhatsappMarketing'
                        active={activeItem === 'WhatsappMarketing'}
                        onClick={this.handleItemClick}
                    >
                            Whatsapp Marketing
                    </Menu.Item>





                    </Menu.Menu> : null}
            </Menu.Item>);
    }
}

export default Marketing;