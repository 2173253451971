import React, { Component } from 'react';
import { Segment, Grid, Header, Image, Icon, Dropdown, Menu } from "semantic-ui-react";

import * as Zapi from './../../../resources/Zapi';



class Mananger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content:"conectar",
            qrcode:"",
            loading:true,
            isConnected:0,
            isActive:0,
        }
    }

    componentWillMount() {
        this.UpdateStatus();
    }


    Resfresh = null;
    componentDidMount() {
        this.Resfresh = setInterval(() => { this.UpdateStatus(); }, 5000);
    }

    componentWillUnmount() {
        clearInterval(this.Resfresh);
    }



    UpdateStatus() {
        Zapi.getZapiToken().then(result=>{
            Zapi.getQrCode(result.token,10000).then(result=>{
                console.log(result);
                if(result.success){
                    this.setState({
                        loading:false,
                        isConnected:new Date().getTime() - result.runing < 180000?result.isConnected:0,
                        qrcode:result.qrcode,
                        isActive:(new Date().getTime() - result.runing < 180000?1:0)
                    });
                }else{
                    this.setState({
                        loading:false,
                        isConnected:0,
                        isActive:0
                    });
                }
            });
        });
        
    }
    setFilterSituação(active) {
        this.setState({ filter: active });
    }
    setFilter(active) {
        this.setState({ filterTipo: active });
    }
    showencerrados(val) {
       
    }


    conectar = ()=>{
        return(<Grid textAlign={'center'} verticalAlign={'middle'} style={{height:'60vh'}}>
            <Grid.Column>
            {this.state.isConnected>0 || this.state.qrcode.length < 1?<Icon name='whatsapp' size={'massive'}centered></Icon>:<Image src={this.state.qrcode} style={{width:'auto',height:'20vh'}} centered></Image>}
                <Header>{this.state.loading?'Carregando...':this.state.isConnected>0?'Conectado':this.state.isActive>0?'Capture o código acima com o whatsapp no seu celular!':'Conta inativa! Tente novamente mais tarde, caso o problema persista, entre em contato com o suporte!'}</Header>
            </Grid.Column>
        </Grid>);
    }

    render() {

        let content = null;
        switch(this.state.content)
        {
            case 'conectar':content = this.conectar();break;
            default:break;
        }

        return (
            <Grid >
                <Grid.Column>
                    <Header as='h2'>
                        <Icon name='whatsapp' />
                        <Header.Content>Configurar Polyzhat</Header.Content>
                    </Header>
                    <Menu compact size={'tiny'}>
                        <Menu.Item as='a' active={this.state.encerrados === 0} onClick={() => this.showencerrados(0)}>
                            <Icon name='qrcode' /> Conectar
                 
                        </Menu.Item>
                        <Menu.Item as='a' active={this.state.encerrados === 1} onClick={() => this.showencerrados(1)}>
                            <Icon name='stack exchange' /> Outros
                    </Menu.Item>
                    </Menu>
                    <Menu compact size={'tiny'} floated={'right'}>
                        <Menu.Item as='a'>
                            <Icon name='checkmark box' />
                            <Dropdown
                                inline
                                header='Situação'

                            />
                    </Menu.Item>
                    {this.state.encerrados>0?<Menu.Item as='a'>
                            <Icon name='filter' />
                            <Dropdown
                                inline
                                header='Filtrar'
                              
                            />
                    </Menu.Item>:null}
                
                    </Menu>
                    <Segment style={{ height: (window.innerHeight - 220), overflow: 'auto' }}>
                       {content}
                    </Segment>
                    
                </Grid.Column>
            </Grid>
        );
    }
}

export default Mananger;