import React, { Component } from 'react';
import { Input, Grid, Divider } from "semantic-ui-react";
import * as zapi from '../../../../resources/Zapi';
import { getToken } from '../../../../resources/Auth';

import FormProgramacao from './campanhas/FormCampanha';

export default class Programacao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            activeIndex: [],
            numberPage: 0,
            buscaItem: "",
            
                id: "",
                name: "",
                message: "",
                id_conta: "",
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                isSql: "",
                codSql: "",
                created_at: "",
                updated_at: "",
                
        }

        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.updateData();
    }

    updateData() {
        try {
            const url = "http://api.clinica28dejulho.com.br/zapi/getCampaign";
            fetch(url)
                .then((result) => result.json().then(json => { if (json.success === true) this.setState({ data: json.statusDados }) }
                ).catch((error) => {
                //   console.error(error);
                }));
        } catch (error) {
            
        }
        
    }
    handlerSalvar(newDataEdit){
        try {
            zapi.updateMassMessages(getToken(),newDataEdit).then(result=>{
                if(result.success > 0){
                    alert("Campanha atualizada com sucesso!");
                    this.updateData();
                }else{
                     alert("Falha ao atualizar campanha!");
                }
            }).catch(err=>{
             alert("Falha na tentativa de atualizar campanha!");
            });
        } catch (error) {
            
        }
      
    }

    handleSetActive(id,isActive){
        zapi.setActive(id,isActive).then(result=>this.updateData());
    }
    
    handlerCancelar(id){
        zapi.delCampaign(id).then(result=>this.updateData());
    }


    handleClick = (index) => {
        if (this.state.activeIndex === index) {
            this.setState({ activeIndex: -1 });
        } else {
            this.setState({ activeIndex: index })
        }
    }

    buscar(value) {
        this.setState({ buscaItem: value });   
    }
    

    render() {
        let resultado = this.state.data.filter((elemento) => {
            return elemento.name.toLowerCase().includes(this.state.buscaItem.toLowerCase());
        });

       
        let itens = [];

        resultado.forEach((element, index) => {
            index = element.id
            let item = <FormProgramacao key={index} element={element} index={index} activeIndex={this.state.activeIndex} 
                                         handleClick={(index) => (this.handleClick(index))}
                                         handlerSalvar={(newDataEdit) => this.handlerSalvar(newDataEdit)} 
                                         handleSetActive={(id,isActive)=>this.handleSetActive(id,isActive)}
                                         handlerCancelar={(id)=>this.handlerCancelar(id)}  />
            itens.push(item);
        });
        return (
            <div>
                <Grid stackable columns={1}>
                    <Grid.Column floated='right' >
                        <Grid stackable floated='right'>
                            <Grid.Column>
                                <Input label='Pesquisar Campanha' fluid value={this.state.buscaItem} onChange={(event, { value }) => this.buscar(value)} floated='left' />
                            </Grid.Column>
                            {/*<Grid.Column width={2} >
                                <Checkbox label='Ativa' /> <br />
                                <Checkbox label='Concluída' /> <br />
                            </Grid.Column>
                            <Grid.Column width={2}>
                                <Checkbox label='Parada' /><br />
                                <Checkbox label='Programada' /> <br />
        </Grid.Column>*/}
                        </Grid>
                        <Divider />
                    </Grid.Column>
                    <Grid.Column width={16}>
                        {itens}
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}