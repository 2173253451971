import React, { Component } from 'react';
import { Segment, Header, Image, Grid,   Input  } from "semantic-ui-react";

import imgBaloes from '../birthday/brithday-img/baloes.png'
//import imgPerfil from '../birthday/imagensProfile.jpeg' 
import imgPerfil2 from '../birthday/brithday-img/imagensProfile.jpeg'

//import imgFundo from '../birthday/aniversario-fundo.jpg'
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader';

import BrithdayPrint from './imprimir/BrithdayPrint';

export default class Birthday extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cor1: 'teal',
            cor2: 'teal',
            file: { nome: "", obj: imgPerfil2 },

        }
    }

    handleChange(value, id) {

        switch (id) {
            case 'isMidia': this.setState({ isMidia: value });
                break;
            default:
                break;
        }

    }

    render() {

        let elemento = <div id="telaPrint" className="telaPrint" style={{ backgroundImage: `url(${imgBaloes})`, ColorAdjust: 'exact', alignItems: 'center' }} >
            <Segment color='teal' inverted attached='top' style={{ margin: "0", ColorAdjust: 'exact' }}>
                <Header textAlign='center'> Aniversariantes do Mês </Header>
            </Segment>  
            <Grid stackable  >
                <Grid.Column floated='right' >
                    <Segment compact circular  color={this.state.cor1} inverted>
                        <Image src={this.state.file.obj} circular spaced /> <br /> <br />
                        <Header>Fulana <HeaderSubHeader style={{ color: 'white' }} >0/01/2020</HeaderSubHeader></Header>
                    </Segment>

                </Grid.Column>
            </Grid>


        </div>

        return (
            <div>   
              <Segment>
                <Input   style={{ marginLeft: "5vh", marginBottom: "0" }} width='4'   id='isMidia' transparent={true} type='file' value={this.state.file.nome} onChange={(event, { value, id }) => { this.setState({ file: { nome: value, obj: event.target.files[0] } }) ;console.log(event.target.files[0]) }} />
                <BrithdayPrint elem={elemento} />
                </Segment> 
                {elemento} 
                <br /> 
            </div>

        );
    }
}
