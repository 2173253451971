import React, { Component } from 'react'
import { Segment, Button, List, Grid, Search, Input,   GridColumn } from 'semantic-ui-react'

export default class Agendados extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeIndex: 1,
            agendados: [],
        }
        this.handleClick = this.handleClick.bind(this);
        this.acessar();
    }

    acessar() {
        const url = "http://api.clinica28dejulho.com.br/examesterceirizados/getPendentes";
        fetch(url).then(result => result.json().then(json => {
            this.setState({ agendados: json.pendentes })
        }))
    }

    componentWillMount() {
        this.acessar();
    }

    removeitem = (index) => {
        let newagendados = this.state.agendados;
        newagendados.splice(index, 1);
        this.setState({ agendados: newagendados })
    }
    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex });
    }
    render() {
        let itens = [];
        this.state.agendados.forEach((element, index) => {
            let item =
                <Segment secondary>
                    <List divided verticalAlign='middle'>

                        <List.Item>

                            <div >
                                <Button basic size='mini' floated='right' circular icon='remove' onClick={() => this.removeitem(index)} />
                            </div>
                            <List.Header >
                                <label size='big'>Clinica de Destino:{element.clinicaDestino} </label><br />
                            </List.Header>
                            <List.Description>

                                <Grid stackable compact columns={2}>
                                    <Grid.Column>
                                        <label ><b>Nome Completo:</b> {element.nameCompleto} </label><br />
                                        <label><b>Data Nasicmento:</b> {new Date(element.dataNascimento).toLocaleDateString()} </label><br />
                                        <label><b> Sexo: </b> {element.sexo}</label><br />
                                        <label><b> Telefone: </b> {element.telefone}</label><br />
                                        <label><b> Celular: </b> {element.celular}</label><br />
                                        <label><b> Whatsapp: </b> {element.whatsapp > 0 ? element.celular : "Sem WhatsApp"}</label><br />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label><b> Prestador:  </b>{element.clinicaDestino}</label><br />
                                        <label><b> Turno: </b> {element.turno}</label><br />
                                        <label><b> Data Pretendida: </b> {new Date(element.dataPretendida).toLocaleDateString()}</label><br />
                                        <label><b> Médico Solicitante: </b> {element.medicoSolicitante}</label><br />
                                        <label><b>Observações:</b> {element.observacao}</label>
                                    </Grid.Column>
                                </Grid>
                            </List.Description>
                        </List.Item>
                    </List>
                </Segment>
            itens.push(item);
        });
        return (
            <div >
                <Segment color='blue' padded >
                    <Grid stackable compact columns={2}>
                        <GridColumn textAlign='left'>
                            <Search /> 
                        </GridColumn>
                        <GridColumn textAlign='right'  >
                            <Input label='Data' type='Date'/> 
                        </GridColumn>
                    </Grid>  
                </Segment>
                {itens}
            </div>
        )
    }
}