import React, { Component } from 'react';
import { Form, Segment, Grid, Header,Icon,Label } from "semantic-ui-react";
import * as Manger from '../../../resources/Manger'



export default class AlterarSenha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            senhas: { senhaAntiga: '', novaSenha: '', repitirSenha: '' },
            user: { name: "teste", cor: "rosa" },
        }
    }

    
    HandleValue = (e) => {

        let senhas = this.state.senhas;

        switch (e.target.name) {
            case 'senhaAntiga': senhas.senhaAntiga = e.target.value; break;
            case 'novaSenha': senhas.novaSenha = e.target.value; break;
            case 'repitirSenha': senhas.repitirSenha = e.target.value; break;

            default:
                break;
        }
        this.setState({ senhas });
    }

    UF = [{ key: "B", text: "BA", value: 12 }]
    City = [{ key: "I", text: "Itabuna", value: 12 }]

    EnviarHandle = (e)=>{
        let senhas = this.state.senhas;
        if(senhas.novaSenha === senhas.repitirSenha && senhas.senhaAntiga.length > 0 && senhas.novaSenha !== senhas.senhaAntiga){
            Manger.changePassword(this.state.senhas.senhaAntiga,this.state.senhas.novaSenha)
            .then(result => 
                {
                    if(result.success){
                        senhas.novaSenha = "";
                        senhas.senhaAntiga = "";
                        senhas.repitirSenha = "";
                        this.setState({senhas:senhas});
                    }
                    alert(result.message)});
        }else{
            alert("Porfavor! verifique os campos e tente novamente!")
        }
    }
    render() {

        function WrongMessage(text){
            return(<Label basic color='red' pointing>
           {text}
          </Label>)
        }

        return (
            <Grid >
                <Grid.Column>
                <Header as='h2'>
                    <Icon name='lock' />
                    <Header.Content>Alterar Senha</Header.Content>
                    </Header>
                    <Form as={Segment} size={'tiny'}>
                        <Form.Field width={8}>
                            <label>Senha Antiga</label>
                            <input type={'password'} placeholder='Senha antiga' name={'senhaAntiga'} value={this.state.senhas.senhaAntiga} onChange={this.HandleValue} />
                            {this.state.senhas.senhaAntiga.length < 1?WrongMessage("Este campo não pode ficar vazio!"):null}
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Nova Senha</label>
                            <input type={'password'} placeholder='Nova senha' name={'novaSenha'} value={this.state.senhas.novaSenha} onChange={this.HandleValue} />
                            {this.state.senhas.novaSenha === this.state.senhas.senhaAntiga?WrongMessage("Você precisa definir uma senha diferente da antiga!"):null}
                        </Form.Field>
                        <Form.Field width={8}>
                            <label>Repetir a Senha</label>
                            <input type={'password'} placeholder='Repita a senha' name={'repitirSenha'} value={this.state.senhas.repitirSenha} onChange={this.HandleValue} />
                            {this.state.senhas.novaSenha === this.state.senhas.repitirSenha || this.state.senhas.repitirSenha.length < 1?null:WrongMessage("As senhas não conferem!")}
                        </Form.Field>
                        <Form.Button content={'Alterar'} color={'blue'} size={'small'} onClick={this.EnviarHandle}/>
                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}