import React from 'react';
//import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Dimmer, Header, Icon, Segment, Form, Button, Message, Container } from "semantic-ui-react";
import * as Manger from './../../../../resources/Manger';


function NewImage(props) {

    const [título, setTítulo] = React.useState("");
    const [descrição, setDescrição] = React.useState("");
    const [image, setImage] = React.useState({ nome: "", image: null });
    const [e1, setE1] = React.useState(false);
    const [status, setForStatus] = React.useState("form");

    function reset(){
        setImage({nome:"",image:null});setTítulo("");setDescrição("") ;setForStatus("form");
        props.update();
    }

    let aguarde = (
        <Message icon>
            <Icon name='circle notched' loading />
            <Message.Content>
                <Message.Header>Espere um momento</Message.Header>
                Estamos enviando o arquivo para o servidor.
        </Message.Content>
        </Message>
    );

    let sucesso = (
        <Message positive>
            <Message.Header>Sucesso!</Message.Header>
            <p>
                Tudo parece ter ocorrido perfeitamente como esperado.
        </p>
        </Message>
    );

    let falha = (
        <Message negative>
            <Message.Header>Falha!</Message.Header>
            <p>
                Algo deu errado, tente novamente.
        </p>
        </Message>
    );

    let form = (
        <Container>
            <Header as='h2' >
                <Icon name='area chart' />
                <Header.Content>
                    Nova Mídia!
        </Header.Content>
                {/*<Icon name='close' style={{float:'right',marginTop:'-10%'}}/>*/}
            </Header>
            <Form as={Segment} size={'tiny'} style={{ textAlign: 'left' }}>
                <Form.Field >
                    <Form.Field>
                        <label>Título</label>
                        <input placeholder='Título da mídia' value={título} onChange={ev => setTítulo(ev.target.value)} />
                    </Form.Field>
                    <Form.Field>
                        <label>Descrição</label>
                        <input placeholder='Descrição da mídia' value={descrição} onChange={ev => setDescrição(ev.target.value)} />
                    </Form.Field>
                    <Form.Field>
                        <label>Descrição</label>
                        <input type="file" name="file-select" id="file-select" accept="image/gif, image/jpeg, image/png, image/jpg, video/mp4, video/avi, video/flv, video/mkv," value={image.nome} onChange={ev => setImage({ image: ev.target.files[0], nome: ev.target.value })} />
                    </Form.Field>
                </Form.Field>
                {e1 ?
                    <Message negative>
                        <Message.Header>Atenção!</Message.Header>
                        <p>Todos os campos devem ser preenchidos devidademente, verifique se você selecionou uma imagem!</p>
                    </Message> : null}
                <Button primary content={'Enviar'} size={'tiny'} onClick={ev => {

                    let erro = false;
                    setE1(false);
                    if (título.length < 1) {
                        setE1(true);
                        erro = true;
                    }
                    if (descrição.length < 1) {
                        setE1(true);
                        erro = true;
                    }
                    if (image.nome.length < 1) {
                        setE1(true);
                        erro = true;
                    }

                    if (!erro) {
                        setForStatus("aguarde");
                        Manger.addPub( título, descrição)
                            .then(result => {
                                Manger.sendPubImg(image.image, result.publicidades.id)
                                    .then(result =>  {
                                        result = JSON.parse(result);
                                        props.update();
                                        if (result.status === "sucesso") {
                                            setForStatus('sucesso');
                                            console.log(result);
                                            setTimeout(()=>{reset();},4000);
                                        }
                                        else {
                                            console.log(result);
                                            setForStatus('falha');
                                            
                                        }
                                    }
                                    );
                            });
                    }
                }} />
                <Button secondary type={'Submit'} content={'Cancelar'} size={'tiny'} onClick={ev => { reset(); props.handleNew(false); }} />


            </Form>
        </Container>
    );

    return (
        <Dimmer active={props.active} onClickOutside={ev => {props.handleNew(false); reset();}}>


            <Segment style={{ width: '400px' }}>
                {status === "form" ? form : status === "aguarde" ? aguarde : status === "sucesso" ? sucesso : falha}
            </Segment>
        </Dimmer>
    );

}
export default NewImage;