import React, { Component } from 'react';
import { Message, Modal, Dimmer, Loader } from "semantic-ui-react";


import * as Zapi from '../../../../resources/Zapi';
import { getToken } from '../../../../resources/Auth';
import FormNovaCampanha from './novaCampanha/FormNovaCampanha';
import { sendFile, link } from '../../../../resources/API';

export default class NewCampanha extends Component {
    constructor(props) {

        super(props);
        this.state = {
            showPosive: false,
            showNegative: false,
            showMessage: "",
            dados: {},
            isSending:false,
        }
    }
    sendHandle = async (params, clearForm) => {
        //   let number = toString(this.state.number);
        //   let itens = number.split("\n")

        this.setState({isSending:true})
        let result = {success:false};

        params.anexo = "";

        if(params.isAnexo){
            result = await sendFile("/fileManger/sendFile",params.file.obj,getToken(),0).catch(error => 
                {this.setState({ showPosive: false, showNegative: true, showMessage: error.message,isSending:false });
                console.error(error); // falha
                 return result;
                });
        }

        if(result.success === false && params.isAnexo){
            this.setState({ showPosive: false, showNegative: true, showMessage: result.message }); // falha
            return;
        }else{
            let url = result.destino;
            params.anexo =  link+url;
        }

        let vldados = params;
        vldados.startTime = new Date("2000-01-01 " + vldados.startTime + ":00").getTime();
        vldados.endTime = new Date("2000-01-01 " + vldados.endTime + ":00").getTime();
        console.log(vldados);
        if (vldados.name.length < 1 || vldados.startDate === "" || vldados.startDate === undefined
            || vldados.startTime === "" || vldados.startTime === undefined ) {
            this.setState({ showPosive: false, showNegative: true, showMessage: "Falha! Motivo: nome de campanha vazio ou a configuração da programação inválida!",isSending:false }); // falha
        } else {
            //aqui é onde você vai enviar; 
            let token = getToken();
            let data = params;
            let numeros = vldados.numbers;
            console.log(numeros)
            for (let index = 0; index < numeros.length; index++) { 
                numeros[index] = numeros[index].split('').filter((element)=>{ 
                    return !(isNaN(parseInt(element)) || element === ",") ;  
                    }).join("");
            } 
            data.numbers = numeros;
            console.log(data)
            Zapi.sendMassMessages(token, data).then(result => {
                if (result.success === true) {
                    this.setState({ showPosive: true, showNegative: false, showMessage: "Envios programados com sucesso!", isSending:false }); // sucesso
                    clearForm();
                } else {
                    this.setState({ showPosive: false, showNegative: true, showMessage: result.message,isSending:false }); // falha
                }
            }).catch(error=>{
                this.setState({ showPosive: false, showNegative: true, showMessage: error.message,isSending:false }); // falha
            });
        }
    }
    render() {

        let itemForm = [];
        itemForm = <FormNovaCampanha sendHandle={(params, clearForm) => this.sendHandle(params, clearForm)} />

        return (
            <div> {/* Nome da campanha , data de inicio e termino, hora de inicio e termino. */}
            <Modal size={"tiny"} open={this.state.isSending} onClose={this.close} >
            <Modal.Header></Modal.Header>
            <Modal.Content>
            <div>
             <Dimmer active inverted>
                <Loader inverted>Enviando...</Loader>
              </Dimmer>
  
           </div>
            </Modal.Content>
          </Modal>
                {this.props.children}
                <Message compact visible positive hidden={!this.state.showPosive} onDismiss={() => { this.setState({ showPosive: false }) }}> {this.state.showMessage}<br></br>

                    {/* Variáveis apenas para evibir os valores no Message  durante essa fase de testes */}
                </Message>
                <Message compact visible negative hidden={!this.state.showNegative} onDismiss={() => { this.setState({ showNegative: false }) }}>{this.state.showMessage}</Message>

                {itemForm}

                <br />
            </div >
        );
    }
}