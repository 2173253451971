import React from 'react';
import { Grid, Button, Segment, List, Breadcrumb, Radio, Icon } from 'semantic-ui-react';

function UsersList(props) {

    let vid = () => {
        return (
            <Icon size={'huge'} name={'user'}>
            </Icon>
        );
    }

    let Users = [];
    props.Users.forEach(element => {
        const user = element;
        Users.push(<List.Item key={element.user_id}>
            <List.Content floated='right'>
                <List.Header>Ativo</List.Header>
                <Radio toggle checked={parseInt(user.isActive) === 1 ? true : false} onClick={() => props.sendStatusUser(user.user_id, user.isActive > 0 ? 0 : 1)} />
            </List.Content>
            {vid()}
            <List.Content>
                <div onClick={() => props.setActive(2, user)}>
                    <List.Header as='a'>{element.name} <Icon name='edit' size={'small'} /> </List.Header>
                    <List.Description as='a'><b>Usuário: </b>{element.nick}</List.Description>
                    <List.Description as='a'>{element.description}</List.Description>
                </div>
                <List.Description as={'a'} onClick={() => props.setActive(3, element)}><Icon name={'lock'}/>Alterar senha</List.Description>
            </List.Content>
        </List.Item>);

    });

    return (
        <Grid >
            <Grid.Column>
                <Breadcrumb>
                    <Breadcrumb.Section link>Gerenciar Usuários</Breadcrumb.Section>
                </Breadcrumb>
                <Segment>
                    <Button icon={'user plus'} content={'Novo'} color={'blue'} size={'small'} onClick={ev => props.handleNew(true)} />
                    <Segment style={{ height: (window.innerHeight - 180), overflow: 'auto' }}>
                        <List divided relaxed>

                            {Users}

                        </List>
                    </Segment>
                </Segment>
            </Grid.Column>
        </Grid>
    );
}

export default UsersList;