import React, { Component } from 'react';
import { Form, Segment, Grid, Header,Icon,Breadcrumb,Button,Confirm,Select } from "semantic-ui-react";



class UserDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            user: props.user,
            open:false,
        }
    }

    HandleValue = (e) => {
        let user = this.state.user;

        switch (e.target.name) {
            case 'name': user.name = e.target.value; break;
            case 'cpf': user.cpf = e.target.value; break;
            case 'address': user.address = e.target.value; break;
            case 'district': user.district = e.target.value; break;
            case 'city': user.city = e.target.value; break;
            case 'cep': user.cep = e.target.value; break;
            case 'email': user.email = e.target.value; break;
            case 'mobile': user.mobile = e.target.value; break;
            case 'phone': user.phone = e.target.value; break;

            default:
                break;
        }
        this.setState({ user:user });
    }

    UF = [{ key: "B", text: "BA", value: 12 }]
    City = [{ key: "I", text: "Itabuna", value: 12 }]

    render() {

        const accessLevelsList = [];
        this.props.accesslevels.forEach(element => {
            const ob = { key: element.user_accesslevel_id, value: element.user_accesslevel_id, text: element.description };
            accessLevelsList.push(ob);
        });

        const open = () => this.setState({ open: true })
        const close = () => this.setState({ open: false })
        const changeLevel = (level)=>{
            const user = this.state.user;
            user.user_accesslevel_id = level;
            this.setState({user:user});
        }

        let buttonDelete=()=>{
        let message = [<div>
            <Header>Você tem certeza que deseja excluir permanentemente este usuário?</Header>
            <Header.Subheader>Esta ação não poderá ser desfeita!</Header.Subheader>
        </div>];
            return (
                <div>
                  <Button  onClick={open} content={'Excluir'}  color={'red'} size={'small'} />
                  <Confirm
                    content={message}
                    open={this.state.open}
                    onCancel={close}
                    onConfirm={()=>{close();this.props.deleteUser(this.state.user.user_id);this.props.setActive(1);}}
                  />
                </div>
              );
        }

        return (
            <Grid >
                <Grid.Column>
                <Breadcrumb>
                    <Breadcrumb.Section link onClick={()=>this.props.setActive(1)}>Gerenciar Usuários</Breadcrumb.Section>
                    <Breadcrumb.Divider icon='right angle' />
                    <Breadcrumb.Section active>Editar Perfil</Breadcrumb.Section>
                </Breadcrumb>
                <Header as='h2'>
                    <Icon name='user' />
                    <Header.Content>Editar Perfil</Header.Content>
                </Header>

                    <Form size={'tiny'} as={Segment}>
                        <Form.Group widths={'3'} >
                            <Form.Field width={10}>
                                <label>Nome</label>
                                <input placeholder='Nome' name={'name'} value={this.state.user.name} onChange={this.HandleValue} />
                            </Form.Field>
                            <Form.Field width={6}>
                                <label>CPF</label>
                                <input placeholder='ex:000.000.000-00' name={'cpf'} value={this.state.user.cpf} onChange={this.HandleValue} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths={'3'}>
                            <Form.Field width={7}>
                                <label>Endereço</label>
                                <input placeholder='Endereço' name={'address'} value={this.state.user.address} onChange={this.HandleValue} />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>Bairro</label>
                                <input placeholder='Bairro' name={'district'} value={this.state.user.district} onChange={this.HandleValue} />
                            </Form.Field>
                            <Form.Select name={"city"} width={5} options={this.City} label={"Município"} defaultValue={parseInt(this.state.user.city)} onChange={this.HandleValue}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Select width={4} options={this.UF} label={"UF"} value={12} />
                            <Form.Field width={4}>
                                <label>CEP</label>
                                <input placeholder='Ex: 45600-000' name={'cep'} value={this.state.user.cep} onChange={this.HandleValue} />
                            </Form.Field>
                            <Form.Field width={8}>
                                <label>Email</label>
                                <input type={'email'} placeholder='email@email.com' name={'email'} value={this.state.user.email} onChange={this.HandleValue} />
                            </Form.Field>
                        </Form.Group>

                        <Form.Group>
                            <Form.Field width={4}>
                                <label>Celular</label>
                                <input type={'text'} placeholder='+00(00)00000-0000' name={'mobile'} value={this.state.user.mobile} onChange={this.HandleValue} />
                            </Form.Field>
                            <Form.Field width={4}>
                                <label>Fone</label>
                                <input type={'tel'} placeholder='+00(00)00000-0000' name={'phone'} value={this.state.user.phone} onChange={this.HandleValue} />
                            </Form.Field>
                        </Form.Group>
                        <Form.Field width={"4"}>
                        <label>Nível de Acesso</label>
                         <Select placeholder='Selecionar' value={this.state.user.user_accesslevel_id} options={accessLevelsList}  onChange={(e, { value }) => changeLevel(value)}/>
                         </Form.Field>
                     
                        <Form.Group inline>
                                <Button content={'Salvar'}  primary size={'small'} onClick={()=>{this.props.updateUser(this.state.user,()=>this.props.setActive(1));}} />
                                <Button content={'Cancelar'} onClick={()=>this.props.setActive(1)} secondary size={'small'} />
                                {buttonDelete()}
                        </Form.Group>

                    </Form>
                </Grid.Column>
            </Grid>
        );
    }
}

export default UserDetails;