import React, { Component } from 'react';
import { Menu, Icon} from "semantic-ui-react";
import * as Auth from '../../../resources/Auth';

//Menu Itens
//import Schedules from './Schedules';
import Mananger from './Mananger';


class Site extends Component {

    
    constructor(props) {
    
        Auth.getUser()
            .then(result => {this.setState({ user: result});})
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            user:props.user,
            handleItemClick:props.handleItemClick,
            visibleBar:true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "mananger": content = <Mananger />; break;
            case "regi": content = <Mananger />; break;

            default:
                break;
        }

        this.state.handleItemClick(e,name,content);

    }
    componentDidMount(){
    }

    componentWillUnmount(){
    }

    render() {

        const { activeItem } = this.props;

        if(typeof(this.state.user.zapi_chat) !== "undefined" || typeof(this.state.user.zapi_manger) !== 'undefined')
        return (
            (this.state.user.zapi_chat > 0 || this.state.user.zapi_manger>0)?
            <Menu.Item>
            <Icon name='whatsapp' />
            Polyzhat
            <Menu.Menu>
                {this.state.user.zapi_chat>0?<Menu.Item
                    name='chat'
                    active={activeItem === 'chat'}
                    onClick={this.handleItemClick}
                >
                    Chat
                </Menu.Item>:null}
                
                {this.state.user.zapi_manger>0?<Menu.Item
                    name='mananger'
                    active={activeItem === 'mananger'}
                    onClick={this.handleItemClick}
                >
                    Configurações
                </Menu.Item>:null}
            </Menu.Menu>
        </Menu.Item>:null);
    }
}

export default Site;