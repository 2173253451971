import React, { Component } from 'react';
import { Menu, Icon} from "semantic-ui-react";
import * as Auth from '../../../resources/Auth';

//Menu Itens
//import Schedules from './Schedules';
import Previsoes from './Previsoes';
import Agendadas from './Agendadas';


class Vacinas extends Component {

    
    constructor(props) {
       

        Auth.getUser()
            .then(result => {this.setState({ user: result});})
            .catch(err => {
                console.error(err);
                //window.location.href = "/";
            });

        super(props);
        this.state = {
            user:"",
            handleItemClick:props.handleItemClick,
            visibleBar:true,
        }

    }

    handleItemClick = (e, { name }) => {

        let content = null;
        switch (name) {
            case "previsoes": content = <Previsoes />; break;
            case "vacinasAgendadas": content = <Agendadas />; break;

            default:
                break;
        }
        
        this.state.handleItemClick(e,name,content);

    }
  componentDidUpdate(){
    const { activeItem } = this.props;
    if(activeItem === "vacinasMenu")
        this.handleItemClick(null,{name:"previsoes"});
  }

    componentWillUnmount(){
    }

    render() {

        const { activeItem } = this.props;
        const active = (activeItem === "vacinasMenu" || activeItem === "previsoes" || activeItem === 'vacinasAgendadas');
      
        return (
            <Menu.Item
            name='vacinasMenu'
            onClick={active?null:this.handleItemClick}
            >
            <Icon name='syringe' />
            Vacinas
           {active?<Menu.Menu>
                <Menu.Item
                    name='previsoes'
                    active={activeItem === 'previsoes'}
                    onClick={this.handleItemClick}
                >
                    Previsões
                </Menu.Item>
                <Menu.Item
                    name='vacinasAgendadas'
                    active={activeItem === 'vacinasAgendadas'}
                    onClick={this.handleItemClick}
                >
                    Agendadas
                </Menu.Item>
                
                {this.state.user.zapi_manger>0?<Menu.Item
                    name='mananger'
                    active={activeItem === 'mananger'}
                    onClick={this.handleItemClick}
                >
                    Configurações
                </Menu.Item>:null}
            </Menu.Menu>:null}
        </Menu.Item>);
    }
}

export default Vacinas;